import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import './App.css';
import WebDesigners from './Components/Web Designers/WebDesigners';
import WebDevelopers from './Components/Web developers/WebDevelopers';
import UiUx from './Components/Ui Ux/UiUx';
import MobileApps from './Components/Mobile Apps/MobileApps';
import SEOServices from './Components/SEO Services/SEOServices';
import PayPerClickAdvertising from './Components/Pay Per Click Advertising/PayPerClickAdvertising';
import SocialMediaMarketing from './Components/Social Media Marketing/SocialMediaMarketing';
import Aboutus from './Components/About us/AboutUs';
import Insights from './Components/Insights/Insights';
import Lifepixl from './Components/Life@pixl/Lifepixl';
import OurTeam from './Components/Our Team/OurTeam'
import DigitalMarketing from './Components/Digital Marketing/DigitalMarketing';
import EmailMarketing from './Components/Email Marketing/EmailMarketing';
import Influncerpartnership from './Components/Influncerpartnership/Influncerpartnership';
import Contentmarketing from './Components/Contentmarketing/Contentmarketing';
import Contactus from './Components/Contactus/Contactus';
import Getquote from './Components/Getquote/Getquote';
import ScrollToTopButton from './Components/Scroll To Top/ScrollToTopButton';
import LanidngPage from './Components/LandingPage/Landingpage';


const App = () => {
  return (
    <Router>
      <Navbar />
      <ScrollToTopButton />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/about-us' element={<Aboutus />} />
        <Route path='/insights' element={<Insights />} />
        <Route path='/life-pixl' element={<Lifepixl />} />
        <Route path='/our-team' element={<OurTeam />} />
        <Route path='/web-designers' element={<WebDesigners />} />
        <Route path='/web-developers' element={<WebDevelopers />} />
        <Route path='/ui-ux' element={<UiUx />} />
        <Route path='/mobile-apps' element={<MobileApps />} />
        <Route path='/digital-marketing' element={<DigitalMarketing />} />
        <Route path='/digital-marketing' element={<DigitalMarketing />} />
        <Route path='/email-marketing' element={<EmailMarketing />} />
        <Route path='/influencer-partnerships' element={<Influncerpartnership />} />
        <Route path='/content-marketing' element={<Contentmarketing />} />
        <Route path='/seo-services' element={<SEOServices />} />
        <Route path='/pay-per-click-advertising' element={<PayPerClickAdvertising />} />
        <Route path='/social-media-marketing' element={<SocialMediaMarketing />} />
        <Route path='/contact-us' element={<Contactus />} />
        <Route path='/get-quote' element={<Getquote />} />
        <Route path='/landing' element={<LanidngPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
