import React from 'react';
import aboutusimage from '../Our Team/assets/gkyhretgyhju.jpg';
import ceo from '../Our Team/assets/ceo.jpg';
import md from '../Our Team/assets/md.jpg';
import asif from '../Our Team/assets/asif.jpg';
import { FaLinkedinIn } from "react-icons/fa";
import manohar from '../Our Team/assets/manohar.png';
import sravan from '../Our Team/assets/sravan.jpg';
import viswa from '../Our Team/assets/viswa.jpg';
import rani from '../Our Team/assets/raninew.jpeg';
import navya from '../Our Team/assets/navya.jpg';
import dolly from '../Our Team/assets/dolly.jpg';
import varun from '../Our Team/assets/varun.jpeg';
import saicharan from '../Our Team/assets/sai charan.jpeg';
import mahesh from '../Our Team/assets/mahesh.png';
import hruthik from '../Our Team/assets/hruthik.jpg';
import pavan from '../Our Team/assets/pawan.jpg';
import gopi from '../Our Team/assets/gopinew.jpeg';
import kiran from '../Our Team/assets/kiran.png';
import balaji from '../Our Team/assets/balaji.png';
import harsha from '../Our Team/assets/harsha.png';
import bhanu from '../Our Team/assets/bhanu.png';
import divya from '../Our Team/assets/divya.png';
import rajendra from '../Our Team/assets/rajendrapython.jpeg';
import ayesha from '../Our Team/assets/ayesha.jpg';
import vijaya from '../Our Team/assets/vijaya.jpg';
import shiva from '../Our Team/assets/shiva.png';
import hari from '../Our Team/assets/hari.jpeg';
import koteswari from '../Our Team/assets/kotewsari.jpeg';
import swamy from '../Our Team/assets/yerriswanynew.jpeg';
import saranyapython from '../Our Team/assets/saranyapython.jpeg';
import shivakrishna from '../Our Team/assets/shivakrishna.jpeg';
import sharanyabde from '../Our Team/assets/sharanyabde.jpeg';
import geeshmitha from '../Our Team/assets/geeshmitha.jpeg';
import nimisha from '../Our Team/assets/nimisha.jpg';
import chandra from '../Our Team/assets/chndara.jpeg';
import pavanbde from '../Our Team/assets/pavanbde.jpeg';
import yohan from '../Our Team/assets/yohan.jpeg';
import karthikbde from '../Our Team/assets/karthikbde.jpeg';
import gouse from '../Our Team/assets/gouse.jpeg';
import prasanthbde from '../Our Team/assets/prasanth.jpeg';
import ReactHelmet from 'react-helmet'
import { Link } from 'react-router-dom';
import OrganizationSchema from '../OrganizationSchema';



const OurTeam = () => {
  const Handleclick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    <>
      <ReactHelmet>
        <OrganizationSchema />
        <title>
          Our Team | Meet the Exceptional Minds Behind Pixl
        </title>
      </ReactHelmet>

      {/* firstimage div starts */}
      <div className='container-fluid mt-4'>
        <img src={aboutusimage} alt="pixl" style={{ width: "100%" }} className='image-fluid' />
      </div>
      {/* firstimage div ends */}

      {/* meet the pixl team div starts  */}
      <div className='container my-3'>
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='fw-bold mt-3'>Meet the Pixl Team</h1>
            <p>At Pixl, our strength lies in our diverse and dedicated team of creative minds and technical wizards. Together, we bring a wealth of experience, passion, and innovation to every project we undertake.</p>
            <p>We believe in the power of collaboration and synergy. Each member of the Pixl team is not just a professional but a contributor to our shared vision. From web designers and developers to UI/UX experts and digital marketers, our team is united by a common goal – delivering excellence in every pixel.</p>
            <p>We’re a diverse team of creative thinkers, tech wizards, and digital strategists dedicated to crafting remarkable web experiences and driving digital success. Get to know the passionate individuals who bring their expertise and enthusiasm to every project:</p>

            <Link to={'/contact-us'} onClick={Handleclick}><div className='btn text-light px-4 py-3 fs-5 mb-5 fw-bold' style={{ backgroundColor: '#E11E5B' }}>Contact Us </div>
            </Link>
          </div>
        </div>
      </div>
      {/* meet the pixl team div starts  */}
      {/* ceo div starts */}
      <div className='container mb-5 ' >
        <div className='row'>
          <div className='col-lg-3 col-md-2'>
            <div className='row'><img className="rounded-4" src={ceo} style={{ width: '100%' }} alt="pixl web designers" />
              <h5 className='my-2'> Bharath Gupta</h5>
              <h5 style={{ color: '#B3B3B3' }}>CEO</h5>
            </div>
          </div>
          <div className='col-lg-9 col-md-10'>
            <div className='row'>
              <h4>Bharath Gupta</h4>
              <h4>CEO of PIXL</h4>
            </div>
            <div className='row'><p>Bharath Gupta is the visionary leader behind Pixl, bringing years of expertise in web development and digital marketing to the table. With a keen eye for detail and a commitment to excellence, Bharath ensures that Pixl remains at the forefront of technological innovation and digital strategy. His strategic insights and relentless drive for perfection inspire our team to push boundaries and exceed expectations.</p></div>
            <div className='row'><p>With a rich background in technology and a passion for innovation, Bharath has established himself as a visionary leader in the industry.</p></div>
            <div className='row'><p>Bharath’s journey in the digital world began with a deep-seated fascination for the intersection of design and technology. Armed with a degree in Computer Science and a relentless curiosity, he embarked on a mission to revolutionize the way businesses connect with their audiences online.</p></div>
          </div>
        </div>
      </div>
      {/* ceo div starts */}
      {/* md div starts */}
      <div className='container mb-5'>
        <div className='row'>
          <div className='col-lg-3 col-md-2'>
            <div className='row'><img className="rounded-4" src={md} alt="pixl designers" />
              <h5 className='my-2'> Anju Soni</h5>
              <h5 style={{ color: '#B3B3B3' }}>Managing Director</h5>
            </div>
          </div>
          <div className='col-lg-9 col-md-10'>
            <div className='row'>
              <h4>Anju Soni</h4>
              <h4>Managing Director of Pixl</h4>
            </div>
            <div className='row'><p>At the helm of Pixl’s innovative journey is our Managing Director, Anju Soni. Anju’s visionary leadership and unwavering dedication have been instrumental in propelling Pixl to the forefront of the digital landscape. With over a decade of experience in the tech industry, she combines a profound understanding of technology with a keen eye for design and user experience.</p></div>
            <div className='row'><p>Anju’s passion for digital transformation is the driving force behind Pixl’s success. Her expertise spans across web design and development, UI/UX, mobile app development, and digital marketing. Under her guidance, Pixl has not only grown into a full-service digital agency but has also earned a reputation for excellence and creativity.</p></div>
          </div>
        </div>
      </div>
      {/* md div starts */}
      {/* team div starts  */}
      <section style={{ backgroundColor: "#F2F2F2" }}>
        {/* first row starts */}
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-md-3 my-4 border-0'>
              <div className="card border-0">
                <img src={rani} className="card-img-top rounded-3" alt="pixl developers" />
                <div className="card-body">
                  <h5 className="card-title">Rani Singh</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Sr. Hr</p>
                  <hr />
                  <a href={"https://www.linkedin.com/in/rani-kumari-904398240"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
            <div className='col-md-3 '>  <div className="card my-4 border-0">
              <img src={navya} className="card-img-top rounded-3 border-0" alt="pixl web designers" />
              <div className="card-body">
                <h5 className="card-title">Navya Yelle</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>HR</p>
                <hr />
                <a href={"http://linkedin.com/in/navya-netha-7539562ab"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>
              <div className="card border-0 my-4">
                <img src={asif} className="card-img-top rounded-3" alt="pixl developers" />
                <div className="card-body">
                  <h5 className="card-title">Shaik Asif</h5>
                  <p className="card-text " style={{ color: "#B3B3B3" }}>Sr. Php Developer</p>
                  <hr />
                  <a href={"http://www.linkedin.com/in/shaik-asif-9896319a"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="card my-4 border-0">
                <img src={manohar} className="card-img-top rounded-3 " alt="pixl india" />
                <div className="card-body">
                  <h5 className="card-title">Chitti Manohar Sai</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Sr. Full Stack Developer</p>
                  <hr />
                  <a href={"https://www.linkedin.com/in/manohar-chitti-0667827b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div></div>
          </div>
        </div>
        {/* first row ends */}
        {/* first row starts */}
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-md-3'>
              <div className="card my-4 border-0">
                <img src={sravan} className="card-img-top rounded-3" alt="pixl" />
                <div className="card-body">
                  <h5 className="card-title">Yellamalli Sravan</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Sr. Frontend Developer</p>
                  <hr />
                  <a href={"http://www.linkedin.com/bdthemes/"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div></div>
            <div className='col-md-3'>
              <div className="card border-0 my-4">
                <img src={viswa} className="card-img-top rounded-3" alt="pixl designers" />
                <div className="card-body">
                  <h5 className="card-title">Visweswara Reddy</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Sr . Android Developer</p>
                  <hr />
                  <a href={"https://www.linkedin.com/in/visweswara-reddy-pothamsetti-801690239?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
            <div className='col-md-3'>  <div className="card  my-4 border-0">
              <img src={varun} className="card-img-top rounded-3" alt="pixl" />
              <div className="card-body">
                <h5 className="card-title">Varun M</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Sr . Android Developer</p>
                <hr />
                <a href="https://www.linkedin.com/in/mulukanoor-varun-35330522b/" ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>
              <div className="card my-4 border-0">
                <img src={saicharan} className="card-img-top rounded-3" alt="pixl india" />
                <div className="card-body">
                  <h5 className="card-title">Saicharan G</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Android Developer</p>
                  <hr />
                  <a href="https://www.linkedin.com/in/saicharan-gattu-918355246" ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div></div>
          </div>
        </div>
        {/* first row ends */}
        {/* first row starts */}
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-md-3'>  <div className="card  my-4 border-0">
              <img src={dolly} className="card-img-top rounded-3" alt="pixl" />
              <div className="card-body">
                <h5 className="card-title">Devyani Kawade</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>WordPress Developer</p>
                <hr />
                <a href={"https://www.linkedin.com/in/devyani-kawade-3405a32b1?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>
              <div className="card my-4 border-0">
                <img src={hruthik} className="card-img-top rounded-3" alt="pixl india" />
                <div className="card-body">
                  <h5 className="card-title">Hruthik</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Front End Developer</p>
                  <hr />
                  <a href={"https://www.linkedin.com/in/hruthik-sai-399179229?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div></div>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={kiran} className="card-img-top rounded-3" alt="pixl india" />
              <div className="card-body">
                <h5 className="card-title">D Yuva Kiran</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>React Developer</p>
                <hr />
                <a href={"http://www.linkedin.com/in/yuva-kiran19"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={gopi} className="card-img-top rounded-3" alt="pixl" />
              <div className="card-body">
                <h5 className="card-title">A Nagagopi</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>React Developer</p>
                <hr />
                <a href={"https://www.linkedin.com/in/nagagopi-animisettiti"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
          </div>
        </div>
        {/* first row ends */}
        {/* first row starts */}
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-md-3'>  <div className="card border-0  my-4">
              <img src={rajendra} className="card-img-top rounded-3" alt="pixl web designers" />
              <div className="card-body">
                <h5 className="card-title">Rajender B</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Angular Developer </p>
                <hr />
                <a href={"http://linkedin.com/in/rajender-undefined-4a83ba306"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>
              <div className="card border-0 my-4">
                <img src={balaji} className="card-img-top rounded-3" alt="pixl designers" />
                <div className="card-body">
                  <h5 className="card-title">Siva Narayana Balaji</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Python Developer</p>
                  <hr />
                  <a href={"https://www.linkedin.com/in/balaji0104?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
            <div className='col-md-3'>  <div className="card  my-4 border-0">
              <img src={hari} className="card-img-top rounded-3" alt="pixl designers" />
              <div className="card-body">
                <h5 className="card-title">Hariom Lohare</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Python Developer</p>
                <hr />
                <a href={"https://www.linkedin.com/in/hariom-lohare-21360b211"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>  <div className="card border-0 my-4" >
              <img src={koteswari} className="card-img-top rounded-3" alt="pixl developers" />
              <div className="card-body">
                <h5 className="card-title">Koteshwari</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Python Developer</p>
                <hr />
                <a href={"http://www.linkedin.com/in/k-koteshwari-305747252"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
          </div>
        </div>
        {/* first row ends */}
        {/* first row starts */}
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-md-3'>  <div className="card border-0  my-4">
              <img src={saranyapython} className="card-img-top rounded-3" alt="pixl" />
              <div className="card-body">
                <h5 className="card-title">Saranya</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Python Developer </p>
                <hr />
                <a href="http://www.linkedin.com/in/tsaranya-sai-lakshmi" ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>  <div className="card border-0  my-4">
              <img src={shivakrishna} className="card-img-top rounded-3" alt="pixl india" />
              <div className="card-body">
                <h5 className="card-title">Siva Krishna  </h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Python Developer </p>
                <hr />
                <a href={"https://www.linkedin.com/in/siva-krishna-aar/"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>
              <div className="card border-0 my-4">
                <img src={swamy} className="card-img-top rounded-3" alt="pixl developers" />
                <div className="card-body">
                  <h5 className="card-title">Yerriswamy M</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Python Developer </p>
                  <hr />
                  <a href={"https://www.linkedin.com/in/majjari-yerri-swamy-8b6a1528b"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={pavan} className="card-img-top rounded-3" alt="pixl" />
              <div className="card-body">
                <h5 className="card-title">Pavan Kumar</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>UI/UX Designer</p>
                <hr />
                <a href="https://www.linkedin.com/in/pavan-kumar-t-2395312a4/" ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
          </div>
        </div>
        {/* first row ends */}
        {/* first row starts */}
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-md-3'>
              <div className="card border-0 my-4">
                <img src={nimisha} className="card-img-top rounded-3" alt="pixl web designers" />
                <div className="card-body">
                  <h5 className="card-title">Amudala Nimisha Reddy</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>UI/UX Designer</p>
                  <hr />
                  <a href={"https://www.linkedin.com/in/nimisha-reddy-amudala-493400293/"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
            <div className='col-md-3'>  <div className="card border-0  my-4">
              <img src={mahesh} className="card-img-top rounded-3" alt="pixl developers" />
              <div className="card-body">
                <h5 className="card-title">Mahesh A</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Sales CRE</p>
                <hr />
                <a href={"https://www.linkedin.com/in/mahesh-a-250330246?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={harsha} className="card-img-top rounded-3" alt="pixl designers" />
              <div className="card-body">
                <h5 className="card-title">Sri Harsha</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Business Analyst</p>
                <hr />
                <a href={"http://linkedin.com/in/sri-harsha-k-v-"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={shiva} className="card-img-top rounded-3" alt="pixl" />
              <div className="card-body">
                <h5 className="card-title">Shiva Kumar</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                <hr />
                <a href={"https://www.linkedin.com/in/shiva-kumar-matpathi-9072b12a9"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
          </div>
        </div>
        {/* first row ends */}
        {/* first row starts */}
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={bhanu} className="card-img-top rounded-3" alt="pixl web designers" />
              <div className="card-body">
                <h5 className="card-title">Bhanu Prasad</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                <hr />
                <a href={"https://www.linkedin.com/in/bhanu-prasad-26a989272?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"} ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>
              <div className="card border-0 my-4">
                <img src={ayesha} className="card-img-top rounded-3" alt="pixl" />
                <div className="card-body">
                  <h5 className="card-title">Ayesha Shaik</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                  <hr />
                  <a href="https://www.linkedin.com/in/ayesha-shaik-5357b0301" ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={divya} className="card-img-top rounded-3" alt="pixl india" />
              <div className="card-body">
                <h5 className="card-title">Divya</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                <hr />
                <a href="https://www.linkedin.com/in/inti-divya-57391a228" ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={vijaya} className="card-img-top rounded-3" alt="pixl web designers" />
              <div className="card-body">
                <h5 className="card-title">VijayaLaxmi Arya</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE </p>
                <hr />
                <a href="https://www.linkedin.com/in/vijayalaxmi-arya-3127752a3" ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
          </div>
        </div>
        {/* first row ends */}
        {/* first row starts */}
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={sharanyabde} className="card-img-top rounded-3" alt="pixl developers" />
              <div className="card-body">
                <h5 className="card-title">Sharanya</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE </p>
                <hr />
                <a href="https://linkedin.com/in/sharanya-kaithi-05946a316" ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>
              <div className="card border-0 my-4">
                <img src={geeshmitha} className="card-img-top rounded-3" alt="pixl india" />
                <div className="card-body">
                  <h5 className="card-title">Geshmitha </h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                  <hr />
                  <a href="http://linkedin.com/in/geshmitha-katakamsetti-95a088285" ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={pavanbde} className="card-img-top rounded-3" alt="pixl" />
              <div className="card-body">
                <h5 className="card-title">Pavan Solanke </h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                <hr />
                <a href="https://www.linkedin.com/in/pavan-solanke-9b8187316" ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={gouse} className="card-img-top rounded-3" alt="pixl web designers" />
              <div className="card-body">
                <h5 className="card-title">Gouse Moin</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                <hr />
                <a href="http://linkedin.com/in/gouse-moin-5a5416315" ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
          </div>
        </div>
        {/* first row ends */}
        {/* first row starts */}
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-md-3'>  <div className="card border-0 my-4">
              <img src={chandra} className="card-img-top rounded-3" alt="pixl developers" />
              <div className="card-body">
                <h5 className="card-title">Chandra Shekar Vudutha</h5>
                <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                <hr />
                <a href="https://www.linkedin.com/in/chandra-shekar-437a5a211?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" ><FaLinkedinIn className='text-danger' /></a>
              </div>
            </div></div>
            <div className='col-md-3'>
              <div className="card border-0 my-4">
                <img src={yohan} className="card-img-top rounded-3" alt="pixl" />
                <div className="card-body">
                  <h5 className="card-title">Yohan</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                  <hr />
                  <a href={"http://linkedin.com/in/yandamuria-yohan-56b599313"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="card border-0 my-4">
                <img src={karthikbde} className="card-img-top rounded-3" alt="pixl designers" />
                <div className="card-body">
                  <h5 className="card-title">Karthik P </h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                  <hr />
                  <a href={"https://www.linkedin.com/in/karthik-phallibaghi-karthik-59872a315?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app  "} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="card border-0 my-4">
                <img src={prasanthbde} className="card-img-top rounded-3" alt="pixl india" />
                <div className="card-body">
                  <h5 className="card-title">Prasanth</h5>
                  <p className="card-text" style={{ color: "#B3B3B3" }}>Sales BDE</p>
                  <hr />
                  <a href={"https://linkedin.com/in/suguru-prashanth-3bb591315"} ><FaLinkedinIn className='text-danger' /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* first row ends */}
      </section>
      {/* team div starts  */}
    </>
  )
}

export default OurTeam