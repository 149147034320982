import React, { useEffect, useState } from "react";
import './landingpage.css';
import "@splidejs/react-splide/css";
import girlimage from "../assets/pixl-web-designers.webp";
import ReactHelmet from 'react-helmet'
import { Link } from "react-router-dom";
import OrganizationSchema from '../OrganizationSchema';
import sulekhaimage from '../assets/suleka.webp';
import airpodsgirlimage from '../assets/airpodsimage.png';
import { FaWindowClose } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import wefeauturedonimage from '../assets/feauturedimage.jpg';
import { FaPhoneVolume } from "react-icons/fa";



const LanidngPage = () => {
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const texts = ["Websites", "Ecommerce", "Mobile Apps", "UI / UX", "Brands"];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const letterInterval = setInterval(() => {
      setCurrentLetterIndex((prevIndex) => {
        if (prevIndex < texts[currentTextIndex].length - 1) {
          return prevIndex + 1;
        } else {
          return prevIndex;
        }
      });
    }, 100); // Speed of typing animation

    const textInterval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentTextIndex((prevTextIndex) => (prevTextIndex + 1) % texts.length);
        setCurrentLetterIndex(0);
        setFade(false);
      }, 500);
    }, 2500);

    return () => {
      clearInterval(letterInterval);
      clearInterval(textInterval);
    };
  }, [currentTextIndex, texts.length]);



  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <ReactHelmet>
        <OrganizationSchema />
        <title>
          Pixl - A Global Web Design and Digital Tech Agency
        </title>
        <link rel="preload" as="image" href={girlimage} fetchPriority="high" />
      </ReactHelmet>
      {/* innovations  div starts for small  scrrens starts*/}
      <div className="container my-4 homepage-small-screens mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <img src={girlimage} alt="pixl" className="img-fluid w-100" />
          </div>
          <div className="col-12 mt-5">
            <div className="text-center">
              <span className="fw-bold" style={{ fontSize: '40px' }}>We Create</span>
              <h1 className={`fw-bold ${fade ? 'hidden' : ''}`} style={{ color: '#E11E5B', fontSize: '50px' }}>
                {texts[currentTextIndex].substring(0, currentLetterIndex + 1)}
              </h1>            </div>


            <div className="text-center">
              <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2  ps-4 pe-4 btn-lg mt-2 text-light fw-semibold fs-4" style={{ backgroundColor: "#1D0543" }}>
                <FaPhoneVolume />+91 73823 73824
              </button></a>
            </div>
            <p className="mt-5 fw-medium" style={{ fontSize: "0.999rem" }}>
              Pixl, based in Hyderabad, has over 16 years of experience in web design. With a skilled team of 60+ experts, we serve clients globally across all industries. At Pixl, we specialize in creating visually appealing and user-friendly websites tailored to your needs.
            </p>
            <p className="mt-2 fw-medium" style={{ fontSize: "0.999rem" }}>
              Whether it’s e-commerce platforms, corporate sites, or portfolios, we blend creativity with technology to deliver outstanding results. Partner with us to elevate your online presence and achieve digital success.
            </p>

            <div className="p-1 mt-4">
              <img src={sulekhaimage} alt="pixl developers" className="img-fluid w-100" />
            </div>

          </div>
        </div>
      </div>
      {/* innovations  div ends for small screens*/}
      {/* innovations  div starts  for large scrrens starts*/}
      <div className="container mt-5 homepage-large-screens">
        <div className="row">
          <div className="col-7 d-flex flex-column justify-content-center">
            <span className="fw-bold" style={{ fontSize: '70px' }}>We Create</span>
            <h1 className={`fw-bold ${fade ? 'hidden' : ''}`} style={{ color: '#E11E5B', fontSize: '50px' }}>
              {texts[currentTextIndex].substring(0, currentLetterIndex + 1)}
            </h1>            <div className="">
              <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2  ps-4 pe-4 btn-lg mt-2 text-light fw-semibold fs-4" style={{ backgroundColor: "#1D0543" }}>
                <FaPhoneVolume /> +91 73823 73824
              </button></a>
            </div>
            <p className="mt-3 fw-medium" style={{ fontSize: "0.999rem" }}>
              Pixl, based in Hyderabad, has over 16 years of experience in web design. With a skilled team of 60+ experts, we serve clients globally across all industries. At Pixl, we specialize in creating visually appealing and user-friendly websites tailored to your needs.
            </p>
            <p className="mt-2 fw-medium" style={{ fontSize: "0.999rem" }}>
              Whether it’s e-commerce platforms, corporate sites, or portfolios, we blend creativity with technology to deliver outstanding results. Partner with us to elevate your online presence and achieve digital success.
            </p>

            <div className="text-center">
              <img src={sulekhaimage} alt="pixl india" />
            </div>
          </div>
          <div className="col-5">
            <img src={girlimage} alt="pixl web designers" className="img-fluid w-100" />
          </div>
        </div>
      </div>
      {/* innovations  div ends for large screens*/}
      {/* a6+ years section starts for small screens */}
      <section style={{ backgroundColor: '#E11E5B' }}>
        <div className="container-fluid px-4 yearsdiv-small-screens">
          <div className="container py-2">
            <div className="row">
              <div className="col-6 border-end border-2">
                <h2 className='text-light fw-bold text-center mt-3'>16 +</h2>
                <p className='text-center fw-medium'>Years of  <br /> Experience</p>
              </div>
              <div className="col-6">
                <h2 className='text-light fw-bold text-center mt-3'>3,000 +</h2>
                <p className='text-center fw-medium'>Websites <br /> Designed</p>
              </div>
              <div className="col-6 border-top border-end border-2">
                <h2 className='text-light fw-bold text-center mt-3'>60 +</h2>
                <p className='text-center fw-medium'>Expert Staff</p>
              </div>
              <div className="col-6 border-top border-2">
                <h2 className='text-light fw-bold text-center mt-3'>56 +</h2>
                <p className='text-center fw-medium'>Awards</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* a6+ years section ends for small screens */}

      {/* a6+ years section starts for large screens */}
      <section style={{ backgroundColor: '#E11E5B' }}>
        <div className="container-fluid yearsdiv-large-screens">
          <div className="container">
            <div className="row">
              <div className="col-3 border-end">
                <h2 className='text-light fw-bold text-center mt-3'>16 +</h2>
                <p className='text-center fw-medium'>Years of  <br /> Experience</p>
              </div>
              <div className="col-3">
                <h2 className='text-light fw-bold text-center mt-3'>3,000 +</h2>
                <p className='text-center fw-medium'>Websites Designed</p>
              </div>
              <div className="col-3 border-top border-end">
                <h2 className='text-light fw-bold text-center mt-3'>60 +</h2>
                <p className='text-center fw-medium'>Expert Staff</p>
              </div>
              <div className="col-3 border-top">
                <h2 className='text-light fw-bold text-center mt-3'>56 +</h2>
                <p className='text-center fw-medium'>Awards</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* a6+ years section ends for large screens */}

      {/* our services div starts */}
      <section style={{ backgroundColor: '#FFF2FD' }} >
        <div className="container pt-4 home-padding-section">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              {/* large screens start */}
              <div className="ms-4 yearsdiv-large-screens">
                <div className="text-center">
                  <h3 className="fw-medium">#1 Website Designing Company</h3>
                  <h3 className="fw-medium">in Hyderabad</h3>
                  <div className="py-4">
                    <h1 className="fw-semibold">
                      <span>Our</span>
                      <span style={{ position: "relative" }}>
                        <span>
                          <span>
                            <span style={{ position: "relative", zIndex: "+999" }}>Services</span>
                            <span><svg className="landing-svg-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none">
                              <path
                                d="M14.4,111.6c0,0,202.9-33.7,471.2,0c0,0-194-8.9-397.3,24.7c0,0,141.9-5.9,309.2,0"
                                style={{ animationPlayState: 'running', strokeWidth: "19px", stroke: "#83DDFF", fill: "none" }}
                              />
                            </svg></span>
                          </span>
                        </span>
                      </span>
                    </h1>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">Startup Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">Ecommerce Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">Corporate Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">Catalogue Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">Real Estate Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">All Industries Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="text-center pt-3">
                  <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2  ps-4 pe-4 btn-lg mt-2 text-light fw-semibold fs-4" style={{ backgroundColor: "#07CE78" }}>
                    <FaPhoneVolume />   +91 73823 73824
                  </button></a>
                </div>
              </div>
              {/* large screens end */}
              {/* small screens start */}
              <div className="mt-4 yearsdiv-small-screens">
                <div className="text-center">
                  <h3 className="fw-medium">#1 Website Designing Company</h3>
                  <h3 className="fw-medium">in Hyderabad</h3>
                  <div className="py-4">
                    <h1 className="fw-semibold">
                      <span>Our</span>
                      <span style={{ position: "relative" }}>
                        <span>
                          <span>
                            <span className="ms-2" style={{ position: "relative", zIndex: "+999" }}>Services</span>
                            <span><svg className="landing-svg-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none">
                              <path
                                d="M14.4,111.6c0,0,202.9-33.7,471.2,0c0,0-194-8.9-397.3,24.7c0,0,141.9-5.9,309.2,0"
                                style={{ animationPlayState: 'running', strokeWidth: "19px", stroke: "#83DDFF", fill: "none" }}
                              />
                            </svg></span>
                          </span>
                        </span>
                      </span>
                    </h1>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">Startup Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">Ecommerce Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">Corporate Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">Catalogue Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">Real Estate Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="ui-e-headline-text">
                        <span className="whitespace"> </span>
                        <span>
                          <span className="ui-e-headline-text ui-e-headline-highlighted">All Industries Website Design</span>
                          <span className="uicore-svg-wrapper">
                            <svg
                              className="landing-svg-underline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 500 150"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                                style={{ animationPlayState: 'running', stroke: "#FFBBD6", strokeWidth: "16px", fill: "none" }}
                              />
                            </svg></span></span><span className="whitespace">
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="text-center pt-3">
                  <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2 btn-lg text-light fw-semibold fs-4" style={{ backgroundColor: "#07CE78" }}>
                    <FaPhoneVolume />    +91 73823 73824
                  </button></a>
                </div>
              </div>
              {/* small screens end */}
            </div>
            <div className="col-md-6 mt-4" >
              <img src={airpodsgirlimage} alt="pixl designers" className="img-fluid w-100" style={{ borderRadius: '20px' }} />
            </div>
          </div>
        </div>
      </section>
      {/* our services div ends */}
      {/* our packages div starts for small screens */}
      <div className="container mt-5 our-pacakagesdiv-cards-small-screens">
        <div className="row">
          <h1 className="text-center">Our Packages</h1>
          <div className="col-12 mt-4">
            <div className="card landing-cards-shadow">
              <div className="card-body">
                <h2>Starter</h2>
                <hr />
                <h1>Rs.6999</h1>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Free Domain</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />5 Pages Design</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Responsive Design</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />5 GB Space</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />5 Emails</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" />SSL Certificate</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" />Free Maintenance</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" />Basic SEO</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" />Social Accounts</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" />Admin Panel</p>
                <hr />
                <div>
                  <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-semibold" style={{ backgroundColor: "#E11E5B" }}>
                    +91 73823 73824
                  </button></a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="card landing-cards-shadow">
              <div className="card-body">
                <h2>Starter</h2>
                <hr />
                <h1>Rs.7999</h1>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Free Domain</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />8 Pages Design</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Responsive Design</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />5 GB Space</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />15 Emails</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />SSL Certificate</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Free Maintenance</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" />Basic SEO</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" />Social Accounts</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" />Admin Panel</p>
                <hr />
                <div>
                  <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-semibold" style={{ backgroundColor: "#E11E5B" }}>
                    +91 73823 73824
                  </button></a>
                </div>
              </div>
            </div></div>
          <div className="col-12 mt-4">
            <div className="card landing-cards-shadow">
              <div className="card-body">
                <h2>Starter</h2>
                <hr />
                <h1>Rs.8999</h1>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Free Domain</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />8-10 Pages Design</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Responsive Design</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />10 GB Space</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />25 Emails</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />SSL Certificate</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Free Maintenance</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Basic SEO</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Social Accounts</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" />Admin Panel</p>
                <hr />
                <div>
                  <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-semibold" style={{ backgroundColor: "#E11E5B" }}>
                    +91 73823 73824
                  </button></a>
                </div>
              </div>
            </div></div>
        </div>
      </div>
      {/* our packages div ends for small screens */}

      {/* our packages div starts for large screens */}
      <div className="container mt-5 our-pacakagesdiv-cards-large-screens">
        <div className="row">
          <h1 className="text-center">Our Packages</h1>
          <div className="col-4 mt-5">
            <div className="card landing-cards-shadow">
              <div className="card-body">
                <h2>Starter</h2>
                <hr />
                <h1>Rs.6999</h1>
                <hr />
                <p className="fw-medium d-flex align-items-center"> <FaCheck className="me-2" />Free Domain</p>
                <hr />
                <p className="fw-medium d-flex align-items-center"> <FaCheck className="me-2" />5 Pages Design</p>
                <hr />
                <p className="fw-medium d-flex align-items-center"> <FaCheck className="me-2" />Responsive Design</p>
                <hr />
                <p className="fw-medium d-flex align-items-center"> <FaCheck className="me-2" />5 GB Space</p>
                <hr />
                <p className="fw-medium d-flex align-items-center"> <FaCheck className="me-2" />5 Emails</p>
                <hr />
                <p className="fw-medium d-flex align-items-center"> <FaWindowClose className="me-2" /> SSL Certificate</p>
                <hr />
                <p className="fw-medium d-flex align-items-center"><FaWindowClose className="me-2" />Free Maintenance</p>
                <hr />
                <p className="fw-medium d-flex align-items-center"> <FaWindowClose className="me-2" />Basic SEO</p>
                <hr />
                <p className="fw-medium d-flex align-items-center"> <FaWindowClose className="me-2" />Social Accounts</p>
                <hr />
                <p className="fw-medium d-flex align-items-center"> <FaWindowClose className="me-2" />Admin Panel</p>
                <hr />
                <div>
                  <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-semibold" style={{ backgroundColor: "#E11E5B" }}>
                    +91 73823 73824
                  </button></a>
                </div>
              </div>
            </div></div>
          <div className="col-4 mt-5">
            <div className="card landing-cards-shadow">
              <div className="card-body">
                <h2>Starter</h2>
                <hr />
                <h1>Rs.7999</h1>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> Free Domain</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> 8 Pages Design</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> Responsive Design</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> 5 GB Space</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> 15 Emails</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> SSL Certificate</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> Free Maintenance</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" /> Basic SEO</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" />Social Accounts</p>
                <hr />
                <p className="fw-medium"> <FaWindowClose className="me-2" />Admin Panel</p>
                <hr />
                <div>
                  <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-semibold" style={{ backgroundColor: "#E11E5B" }}>
                    +91 73823 73824
                  </button></a>
                </div>
              </div>
            </div></div>
          <div className="col-4 mt-5">
            <div className="card landing-cards-shadow">
              <div className="card-body">
                <h2>Starter</h2>
                <hr />
                <h1>Rs.8999</h1>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> Free Domain</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> 8-10 Pages Design</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> Responsive Design</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> 10 GB Space</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> 25 Emails</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> SSL Certificate</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> Free Maintenance</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> Basic SEO</p>
                <hr />
                <p className="fw-medium"> <FaCheck className="me-2" /> Social Accounts</p>
                <hr />
                <p className="fw-medium">  <FaCheck className="me-2" /> Admin Panel</p>
                <hr />
                <div>
                  <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-semibold" style={{ backgroundColor: "#E11E5B" }}>
                    +91 73823 73824
                  </button></a>
                </div>
              </div>
            </div></div>
        </div>
      </div>
      {/* our packages div ends for large screens */}
      {/* // Services We Offer div starts// */}
      <div className="container-fluid pb-5" style={{ backgroundColor: "#FAFAFA" }}>
        <div className="container">
          <h1 className="text-dark text-center pt-5 pb-3 fw-bold">We are Experts in</h1>
          <div className="row">
            <div className="col-md-4 mb-3">
              <Link to={'/web-designers'} className="text-decoration-none" onClick={handleClick}>
                <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#FFF7F9" }}>
                  <div className="card-body">
                    <h5 className="card-title fw-bold">
                      <i className="fa-solid fa-desktop p-3 rounded-3  text-light me-3" style={{ backgroundColor: '#30B47C' }}></i>
                      Web Design
                    </h5>
                    <p className="card-text">
                      Elevate your online presence with our expert web design
                      services, seamlessly blending creativity and functionality
                    </p>
                  </div>
                </div></Link>
            </div>
            <div className="col-md-4 mb-3">
              <Link to={'/web-developers'} className="text-decoration-none" onClick={handleClick}>   <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    <i className="fab fa-slideshare p-3 rounded-3  text-light me-3" style={{ backgroundColor: '#E11E5B' }} ></i> Web Development
                  </h5>
                  <p className="card-text">
                    From concept to code, we turn ideas into tangible success with
                    our exceptional web development expertise
                  </p>
                </div>
              </div></Link>
            </div>
            <div className="col-md-4 mb-3">
              <Link to={'/mobile-apps'} className="text-decoration-none" onClick={handleClick}>  <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#F3FFFA" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold"><i className="fa-solid fa-mobile  p-3 rounded-3  text-light me-3" style={{ backgroundColor: '#36C6F0' }}></i>App Development</h5>
                  <p className="card-text">
                    Ignite innovation and engagement with our cutting-edge mobile
                    app development that turns ideas into interactive experiences
                  </p>
                </div>
              </div></Link>
            </div>
            <div className="col-md-4 mb-3">
              <Link to={'/digital-marketing'} className="text-decoration-none" onClick={handleClick}>  <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#F3FFFA" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    <i className="fa-solid fa-bullhorn p-3 rounded-3  text-light me-3 " style={{ backgroundColor: '#E11E5B' }}  ></i>Digital Marketing</h5>
                  <p className="card-text">
                    Boost your online presence with our data-driven digital
                    marketing solutions to expand your business online
                  </p>
                </div>
              </div></Link>
            </div>
            <div className="col-md-4 mb-3">
              <Link to={'/ui-ux'} className="text-decoration-none" onClick={handleClick}>  <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold"><i className="fa-regular fa-eye p-3 rounded-3  text-light me-3 " style={{ backgroundColor: '#36C6F0' }}  ></i>UI/UX Development</h5>
                  <p className="card-text">
                    We go beyond aesthetics, meticulously designing interfaces
                    that not only delight users but also enhance usability
                  </p>
                </div>
              </div></Link>
            </div>
            <div className="col-md-4 mb-3">
              <Link to={'/mobile-apps'} className="text-decoration-none" onClick={handleClick}> <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">        <i className="fa-solid fa-desktop p-3 rounded-3  text-light me-3" style={{ backgroundColor: '#30B47C' }}></i>Custom Applications</h5>
                  <p className="card-text">
                    We specialize in custom applications for a seamless blend of
                    functionality, efficiency, and user-centric design
                  </p>
                </div>
              </div></Link>
            </div>
          </div>
          <div className="text-center">
            <a href="tel:+91 73823 73824" className="text-dark" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2  ps-4 pe-4 btn-lg mt-2 text-dark fw-semibold fs-4" style={{ backgroundColor: "#EBB12C" }}>
              <FaPhoneVolume />   +91 73823 73824
            </button></a>
          </div>
        </div>
      </div>
      {/* services div end */}
      {/* we are feautured on div starts */}
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col text-center">
            <h1>We're Featured On</h1>
            <img src={wefeauturedonimage} alt="pixl" className="" />
            <div className="text-center mt-2">
              <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2 ps-4 pe-4 btn-lg mt-2 text-light fw-semibold fs-4" style={{ backgroundColor: "#E11E5B" }}>
                <FaPhoneVolume />+91 73823 73824
              </button></a>
            </div>
          </div>
        </div>
      </div>
      {/* we are feautured on div ends */}
      {/* pixl section starts */}
      <section className="container-fluid py-5" style={{ backgroundColor: '#F8F9FA' }}>
        <div className="container shadow bg-white rounded-3 my-1 p-5 home-section-work-together">
          <span className="fs-3 text-dark fw-bold"> <i className="fa-solid fs-2 p-2  fa-headphones text-danger me-4 rounded-2 "></i>Pixl</span>
          <h1 className="my-4">Let's Work Together</h1>
          <p className="fs-5">
            From sleek designs to seamless functionality, we’re dedicated to building websites that not only look great but also deliver exceptional user experiences.
          </p>
          <p className="fs-5 fw-bold text-dark">Get in touch, and let the collaboration begin</p>
          <div className="text-center mt-2">
            <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2 ps-4 pe-4 btn-lg mt-2 text-light fw-semibold fs-4" style={{ backgroundColor: "#E11E5B" }}>
              <FaPhoneVolume />+91 73823 73824
            </button></a>
          </div>
        </div>
      </section>
      {/* pixl section ends */}
    </>
  );
};

export default LanidngPage;
