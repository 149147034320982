import React, { useState } from "react";
import './Contactus.css';
import calllogo from '../Contactus/assets/vault-insurance-contact-envelope-icon-2.webp';
import maillogo from '../Contactus/assets/vault-insurance-contact-envelope-icon-1.webp';
import ReactHelmet from 'react-helmet';
import OrganizationSchema from '../OrganizationSchema';
import axios from "axios";
import { sendQueryURL } from "../../api/Api";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import mumbailogo from '../Contactus/assets/mumbai.avif';
import hydloho from '../Contactus/assets/hyd.png'
import bnglogo from '../Contactus/assets/bang.png';
import chnlogo from '..//Contactus/assets/chd.avif'
import gurulogo from '../Contactus/assets/kolk.avif'
import punelogo from '../Contactus/assets/pune.png';
import vizaglogho from '../Contactus/assets/koch.avif';
import vjdlogo from '../Contactus/assets/chen.avif';

const Contactus = () => {
  const initialSendQueryData = {
    name: '',
    email: '',
    mobile: '',
    subject: '',
    message: ''
  };
  const [sendQuery, setSendQuery] = useState(initialSendQueryData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSendQuery((prevSendQuery) => ({
      ...prevSendQuery,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(sendQueryURL, sendQuery);
      notify();
      setSendQuery(initialSendQueryData);
      console.log('data from send query', response.data);
    } catch (error) {
      console.log('error', error);
      errorNotification();
    }
  }

  const notify = () => {
    toast.success("Your Query Is Successfully Submited...", {
      position: "top-right"
    });
  };

  const errorNotification = () => {
    toast.error("Something Went Wrong", {
      position: "top-right"
    });
  }

  return (
    <>
      <ToastContainer />
      <OrganizationSchema />
      <ReactHelmet>
        <title>
          Contact us | Pixl Designers India
        </title>
      </ReactHelmet>
      {/* cards div starts */}
      <section className="bg-gradianat">
        <div className="container mb-5">
          <div className="row">
            <h1 className="text-center text-dark my-5">Pixlify Internet Solutions Private Limited</h1>

            <div className="col-xl-3 col-lg-3 col-md-3 my-2">
              <div className="card custom-hover-card rounded-3">
                <div className="d-flex align-items-center justify-content-start">
                  <img src={hydloho} className="card-img-top" alt="pixl" style={{ width: '80px' }} />
                </div>
                <div className="card-body">
                  <h5 className="card-title" style={{ color: '#7D7F83' }}>Hyderabad</h5>
                  <p className="card-text text-dark fw-small">303, Block – A, The Platina, Kothaguda Road, Beside Radison Hotel, Gachibowli, Hyderabad 500032 Telangana</p>
                  <a href="https://maps.app.goo.gl/C6mJLqqw8R17rFJ29"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-5 text-decoration-none"
                    style={{ color: '#7D7F83' }}
                  >
                    <i className="bi bi-geo-alt fs-3 text-danger me-2"></i>View Location
                  </a>
                </div>
              </div>
            </div>

            <div className=" col-xl-3 col-lg-3 col-md-3 my-2">
              <div className="card custom-hover-card">
                <div className="d-flex align-items-center justify-content-start" style={{}}>
                  <img src={bnglogo} className="card-img-top ms-3" alt="pixl web designers" style={{ width: '80px' }} />
                </div>
                <div className="card-body">
                  <h5 className="card-title" style={{ color: '#7D7F83' }}>Bangalore</h5>
                  <p className="card-text text-dark fw-small">Sabari  Complex,  24,  Field     Marshal  Cariappa  Rd,  Shanthala Nagar  , Ashok Nagar,
                    Bengaluru, Karnataka Pin 560025</p>


                  <a
                    href="https://maps.app.goo.gl/cM39QAF6turGnqZi7"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-5 text-decoration-none"
                    style={{ color: '#7D7F83' }}
                  >
                    <i className="bi bi-geo-alt fs-3 text-danger me-2"></i>View Location
                  </a>
                </div>
              </div>
            </div>
            <div className=" col-xl-3 col-lg-3 col-md-3 my-2">
              <div className="card custom-hover-card">
                <div className="d-flex align-items-center justify-content-start" style={{}}>
                  <img src={vjdlogo} className="card-img-top" alt="pixl developers" style={{ width: '80px' }} />
                </div>

                <div className="card-body">
                  <h5 className="card-title" style={{ color: '#7D7F83' }}>Vijayawada</h5>
                  <p className="card-text text-dark fw-small">Flat No 502, Kavuri Towers, Kamayyathopu Centre, Kanuru,  Near Axis Bank, Vijayawada, Krishna, Andhra Pradesh – 520007</p>
                  <a
                    href="https://maps.app.goo.gl/ouwSCLCb3BRsb9nX7"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-5 text-decoration-none"
                    style={{ color: '#7D7F83' }}
                  >
                    <i className="bi bi-geo-alt fs-3 text-danger me-2"></i>View Location
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-2 my-2">
              <div className="card custom-hover-card">
                <div className="d-flex align-items-center justify-content-start" style={{}}>
                  <img src={vizaglogho} className="card-img-top ms-3" alt="pixl designers" style={{ width: '80px' }} />
                </div>

                <div className="card-body">
                  <h5 className="card-title" style={{ color: '#7D7F83' }}>Vizag</h5>
                  <p className="card-text text-dark fw-small">Waltair Main Road, D. No.12-1-16 Plot No.49, 3rd Floor, Survey No 1051, opposite HDFC Bank, Visakhapatnam, AP 530002</p>
                  <a
                    href="https://maps.app.goo.gl/xusBkB5EVKooty4Y8"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-5 text-decoration-none"
                    style={{ color: '#7D7F83' }}
                  >
                    <i className="bi bi-geo-alt fs-3 text-danger me-2"></i>View Location
                  </a>
                </div>
              </div>
            </div>
            <div className=" col-xl-3 col-lg-3 col-md-3 my-2">
              <div className="card custom-hover-card">
                <div className="d-flex align-items-center justify-content-start" style={{}}>
                  <img src={punelogo} className="card-img-top ms-2" alt="pixl india" style={{ width: '80px' }} />
                </div>

                <div className="card-body">
                  <h5 className="card-title" style={{ color: '#7D7F83' }}>Pune</h5>
                  <p className="card-text text-dark fw-small mx-">Ground Floor, Sky Vista, Airport Rd,, next to Eminence IT Park, Mhada Colony, Viman Nagar, Pune, Maharashtra 411014</p>
                  <a
                    href="https://maps.app.goo.gl/JTrQWStXPmZDKyEC9"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-5 text-decoration-none"
                    style={{ color: '#7D7F83' }}
                  >
                    <i className="bi bi-geo-alt fs-3 text-danger me-2"></i>View Location
                  </a>
                </div>
              </div>
            </div>
            <div className=" col-xl-3 col-lg-3 col-md-3 my-2">
              <div className="card custom-hover-card">
                <div className="d-flex align-items-center justify-content-start" style={{}}>
                  <img src={gurulogo} className="card-img-top ms-3" alt="pixl" style={{ width: '80px' }} />
                </div>

                <div className="card-body">
                  <h5 className="card-title" style={{ color: '#7D7F83' }}>Gurugram</h5>
                  <p className="card-text text-dark fw-small mx-">Level 9, Spaze i-Tech Park, A1 Tower,, Badshahpur Sohna Rd Hwy, Block S, Sector 49, Gurugram, Haryana 122018</p>

                  <a
                    href="https://maps.app.goo.gl/izoQhNscvQoLfgZ3A"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-5 text-decoration-none"
                    style={{ color: '#7D7F83' }}
                  >
                    <i className="bi bi-geo-alt fs-3 text-danger me-2"></i>View Location
                  </a>                 </div>
              </div>
            </div>
            <div className=" col-xl-3 col-lg-3 col-md-3 my-2">
              <div className="card custom-hover-card">
                <div className="d-flex align-items-center justify-content-start" style={{}}>
                  <img src={chnlogo} className="card-img-top" alt="pixl india" style={{ width: '80px' }} />
                </div>

                <div className="card-body">
                  <h5 className="card-title" style={{ color: '#7D7F83' }}>Chennai</h5>
                  <p className="card-text text-dark fw-small mx-">Shakti Tower 1, 766, Anna Salai, Thousand Lights, Beside Financial Technologies Limited,Tamil Nadu Chennai, Tamil Nadu 600002</p>
                  <a
                    href=" https://maps.app.goo.gl/hZQx4kNEm4LmdAbf6"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-5 text-decoration-none"
                    style={{ color: '#7D7F83' }}
                  >
                    <i className="bi bi-geo-alt fs-3 text-danger me-2"></i>View Location
                  </a>                 </div>
              </div>
            </div>
            <div className=" col-xl-3 col-lg-3 col-md-3 my-2">
              <div className="card custom-hover-card">
                <div className="d-flex align-items-center justify-content-start" style={{}}>
                  <img src={mumbailogo} className="card-img-top ms-3" alt="pixl developers" style={{ width: '80px' }} />
                </div>

                <div className="card-body">
                  <h5 className="card-title" style={{ color: '#7D7F83' }}>Mumbai</h5>
                  <p className="card-text text-dark fw-small mx-">1st floor, Tiffany Building, Arcadia Cir, Hiranandani Estate,, Thane West, Thane, Mumbai, Maharashtra pin 400607</p>
                  <a
                    href="https://maps.app.goo.gl/ArN1sTAnxVPdodJG6"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-5 text-decoration-none"
                    style={{ color: '#7D7F83' }}
                  >
                    <i className="bi bi-geo-alt fs-3 text-danger me-2"></i>View Location
                  </a>                 </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* cards div ends */}
      {/* Call us section starts */}
      <section>
        <div className="container mb-5 px-4">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12 mb-4 ">
              <div className="row custom-hover-card p-3 align-items-center rounded-3">
                <div className="col-auto">
                  <img src={calllogo} alt="pixl" style={{ width: "50px" }} />
                </div>
                <div className="col">
                  <p style={{ color: "#7D7F83", fontSize: "30px" }}>Call Us</p>
                  <a href="tel:+7382373824" className="text-dark fw-small" style={{ textDecoration: 'none' }}>
                    +91 73823 73824
                  </a>
                  <p className="fw-small">  <a href="tel:+91 89779 57495" className="text-dark fw-small" style={{ textDecoration: 'none' }}>
                    +91 89779 57495
                  </a><span className="ms-2">(For Carriers)</span></p>
                </div>
              </div>
              <div className="row  custom-hover-card p-3 align-items-center mt-4 rounded-3">
                <div className="col-auto">
                  <img src={maillogo} alt="pixl india" style={{ width: "50px" }} />
                </div>
                <div className="col">
                  <p style={{ color: "#7D7F83", fontSize: "30px" }}>Email Us</p>
                  <span className="text-dark fw-small fs-5">connect@pixl.in</span>
                </div>
              </div>
            </div>
            {/* form div starts */}
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="ms-4 p-5 contact-us-from-padding" style={{ backgroundColor: "#FAFAFA" }}>
                <h5 className="text-center my-3 text-dardk fw-bold">Have any questions?</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-12 col-md-6 text-dark fw-bold">
                      <input type="text" className="form-control-input" placeholder="Your name" value={sendQuery.name} name='name' onChange={handleInputChange} required />
                    </div>
                    <div className="col-12 col-md-6">
                      <input type="email" className="form-control-input" placeholder="Your email" value={sendQuery.email} name='email' onChange={handleInputChange} required />
                    </div>
                  </div>
                  <div className="row g-3 mt-2">
                    <div className="col-12">
                      <input type="text" className="form-control-input" placeholder="Your contact number" value={sendQuery.mobile} name='mobile' onChange={handleInputChange} required />
                    </div>
                  </div>
                  <div className="row g-3 mt-2">
                    <div className="col-12">
                      <input type="text" className="form-control-input" placeholder="Your message subject" value={sendQuery.subject} name='subject' onChange={handleInputChange} required />
                    </div>
                  </div>
                  <div className="row g-3 mt-2">
                    <div className="col-12">
                      <textarea className="form-control-input" rows="4" placeholder="Your message" value={sendQuery.message} name='message' onChange={handleInputChange} required></textarea>
                    </div>
                  </div>
                  <div className="row g-3 mt-2">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 d-flex justify-content-between align-items-center">
                      <span className="">By clicking the button, you agree to our terms and consent to cookie usage in the browser.</span>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                      <button className="text-light fw-bold px-3 py-3 rounded-3" type="submit" style={{ backgroundColor: "#E11E5B", border: "none" }}>Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* form div ends */}
          </div>
        </div>
      </section>
      {/* Call us section ends */}

    </>
  );
};

export default Contactus;
