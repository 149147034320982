import React, { useEffect } from 'react'
import mainlogo from './assets/dark.png'
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import { Link } from 'react-router-dom'
import '../Footer/footer.css'

const Footer = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}, [])

	const handleClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}
	return (
		<>
			{/* <!-- =======================
Footer START large--> */}
			<div className="py-5 container-fluid text-light" style={{ backgroundColor: "#101828" }}>
				<div className="container py-2">
					{/* <!-- Row START --> */}
					<div className="row">
						{/* <!-- Widget 1 START --> */}
						<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
							{/* <!-- logo --> */}
							<Link to={'/'} onClick={handleClick}><img className="h-40px" src={mainlogo} alt="logo" /></Link>
							<p className="my-3">303, Block – A, The Platina,Kothaguda Road, Gachibowli Hyderabad – 500032</p>
							<p className='d-flex flex-column'>
								<span><FaPhoneSquareAlt className='me-2' />+91 73823 73824</span>
								<span><MdMailOutline className='me-2' />connect@pixl.in</span></p>
								<p><span>For Careers :</span>
								<span> +91 89779 57495</span></p>
						</div>
						{/* <!-- Widget 1 END -->
						<!-- Widget 2 START --> */}
						<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 mb-4	">
							<h5 className="color-white">Quick Links</h5>
							<ul className="nav flex-column text-primary-hover">
								<li className="nav-item"><Link onClick={handleClick} className="nav-link text-light" to={'/'}> Home</Link></li>
								<li className="nav-item"> <Link onClick={handleClick} to={'/about-us'} className="nav-link text-light">About Us</Link></li>
								<li className="nav-item"><Link onClick={handleClick} className="nav-link text-light" to={'/our-team'}> Our team</Link></li>
								<li className="nav-item"><Link onClick={handleClick} className="nav-link text-light" to={'/contact-us'}>Contact us </Link></li>
							</ul>
						</div>
						{/* <!-- Link block --> */}
						{/* <!-- Link block --> */}
						<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 mb-4">
							<h5 className="color-white">Services</h5>
							<ul className="nav flex-column text-primary-hover">
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/web-designers'}> Web Designers</Link></li>
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/digital-marketing'}> Digital Marketing </Link></li>
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/mobile-apps'}>  Mobile App Developers</Link></li>
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/ui-ux'}>UI/UX Developement</Link></li>
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/web-developers'}>  Web Developers</Link></li>
							</ul>
						</div>
						{/* <!-- Link block --> */}
						<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
							<h5 className="color-white"> Digital Marketing </h5>
							<ul className="nav flex-column text-primary-hover">
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/seo-services'}> SEO Services </Link></li>
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/pay-per-click-advertising'}> Pay-Per-Click Advertising </Link></li>
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/social-media-marketing'}>Social Media Marketing</Link></li>
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/email-marketing'}> Email Marketing </Link></li>
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/influencer-partnerships'}>Influncer Partnerships </Link></li>
								<li className="nav-item"><Link className="nav-link text-light" onClick={handleClick} to={'/content-marketing'}>Content Marketing</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			{/* footer end large */}
			<div className="container-fluid bg-black py-3">
				<div className="container">
					<p className='text-center text-light'>All Rights Reserved © <a href="https://pixl.in/" className='text-danger text-decoration-none'>Pixl</a></p>
					<ul className='nav d-flex justify-content-center mt-4'>
						<a href="https://www.facebook.com/pixldotin"><li className='nav-item'><i className="bi bi-facebook text-light p-2 rounded-2" style={{ backgroundColor: "#3B5998" }}></i></li>
						</a>
						<a href="https://www.instagram.com/pixldotin"><li className='nav-item ms-3'><i className="bi bi-instagram text-light p-2 rounded-2" style={{ backgroundColor: "#AD1196" }}></i></li>
						</a>
						<a href="https://twitter.com/pixldotin"><li className='nav-item mx-3'><i className="bi bi-twitter-x text-light p-2 rounded-2" style={{ backgroundColor: "#69727D" }}></i></li>
						</a>
						<a href="https://www.linkedin.com/company/pixldotin"><li className='nav-item'><i className="bi bi-linkedin text-light p-2 rounded-2" style={{ backgroundColor: "#0077B5" }}></i></li>
						</a>
						<li className='nav-item ms-3'><i className="bi bi-quora text-light p-2 rounded-2" style={{ backgroundColor: "#69727D" }}></i></li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default Footer;