import axios from 'axios';
import React, { useState } from 'react'
import { sendQueryURL } from '../../api/Api';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const SeparateForm = () => {
    const initialSendQueryFormData = {
        name: '',
        email: '',
        mobile: '',
        subject:'',
        message: ''
    };
    const [sendQueryData, setSendQueryData] = useState(initialSendQueryFormData);

    const handleInputSendQuery = (e) => {
        const { name, value } = e.target;
        setSendQueryData((prevSendQueryData) => ({
            ...prevSendQueryData,
            [name]: value
        }));
    };

    const handleSubmitSendQuery = async (event) => {
        event.preventDefault();
        try {
            // console.log("Submiting form");
            const response = await axios.post(sendQueryURL, sendQueryData);
            notify();
            setSendQueryData(initialSendQueryFormData);
            // console.log('data from send query', response.data);
        } catch (error) {
            // console.log('error', error);
        }
    }

    const notify = () => {
        toast.success("Your Query Is Successfully Submited...", {
            className: 'custom-toast'
        });
    };
    return (
        <>
            <form onSubmit={handleSubmitSendQuery}>
                <div className="mb-3">
                    <input type="text" required placeholder='Your Name' name='name' value={sendQueryData.name} onChange={handleInputSendQuery} className="form-control p-2" />
                </div>
                <div className="mb-3">
                    <input type="email" required placeholder='example@email.com' name='email' value={sendQueryData.email} onChange={handleInputSendQuery} className="form-control p-2" />
                </div>
                <div className="mb-3">
                    <input type="tel" required placeholder='Your Contact Number' name='mobile' value={sendQueryData.mobile} onChange={handleInputSendQuery} className="form-control p-2" />
                </div>
                <div className="mb-3">
                    <input type="text" required placeholder='Your Subject' name='subject' value={sendQueryData.subject} onChange={handleInputSendQuery} className="form-control p-2" />
                </div>
                <div>
                    <textarea required className='form-control' placeholder='Your Message Here' name="message" value={sendQueryData.message} onChange={handleInputSendQuery} id="" cols="55" rows="5"></textarea>
                </div>
                <div className='my-4'>
                    <button type="submit" className="btn  btn-lg rounded-2 py-3 px-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>SEND MESSAGE</button>
                </div>
            </form>
        </>
    )
}

export default SeparateForm