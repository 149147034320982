import React from 'react'
import ReactHelmet from 'react-helmet'
import searchengine from './assets/Digital-Marketing.png'
import seoservicesprecise from './assets/22.png'
import expertise from './assets/vfdc.png'
import education from './assets/education_icon.png'
import financial from './assets/financial-services.png'
import fitness from './assets/Fitness.png'
import healthcare from './assets/healthcare.png'
import hospitality from './assets/hospitality.png'
import it from './assets/information-technology.png'
import legal from './assets/Legal.png'
import manufacturing from './assets/manufacturing.png'
import marketing from './assets/marketing-communication.png'
import travel from './assets/travel.png'
import seo from './assets/digital-mar-social-main-img.png'
import { FaHeadphones } from "react-icons/fa6";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ani from "../assets/ani.png";
import daily from "../assets/daily.png";
import hans from "../assets/hans.png";
import jio from "../assets/jio.png";
import latestly from "../assets/latestly.png";
import lokmat from "../assets/lokmat.png";
import na from "../assets/na.png";
import SANGRI from "../assets/SANGRI.png";
import web from "../assets/web.png";
import "@splidejs/react-splide/css";
import '../Social Media Marketing/socialmediamarketing.css'
import OrganizationSchema from '../OrganizationSchema';
import SeparateForm from '../Seperate Form/SeparateForm'
import { ToastContainer } from 'react-toastify'
const SocialMediaMarketing = () => {
    const splideOptions = {
        type: "loop",
        perPage: 6,
        autoplay: true,
        interval: 3000,
        pauseOnHover: false,
        arrows: false,
        pagination: false,
        drag: false,
        gap: "1rem",
        breakpoints: {
            1024: { perPage: 4 },
            768: { perPage: 3 },
            480: { perPage: 2 },
            320: { perPage: 1 },
        },
    };
    return (
        <>
            <ToastContainer />
            <ReactHelmet>
                <OrganizationSchema />
                <title>
                    Social Media Marketing | SMM Services by Pixl India
                </title>
            </ReactHelmet>
            {/* for large screens start */}
            <div className="container-fluid social-media-marketing-firstcontainer-lgdevice" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8">
                            <h1>Social Media Marketing</h1>
                            <h1><span style={{ color: "#E11E5B" }}>Igniting Your Brand's</span>Digital Presence</h1>
                            <div className='my-4'>
                                <p>In an era where connections happen in real-time, harnessing the power of social media is not just a strategy; it’s a necessity. Explore how our Social Media Marketing services can propel your brand forward, foster meaningful connections, and drive tangible results.</p>
                                <p>Fueled by strategic thinkers, creative designers, content creators, and innovative technologists, we redefine global digital success through the artistry and impact of Social Media Marketing.</p>
                            </div>
                            <div>
                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                            <img src={searchengine} alt="pixl" style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* for large screens end */}
            {/* for small and medium screens start */}
            <div className="container-fluid social-media-marketing-firstcontainer-sm-mddevice" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                            <img src={searchengine} alt="pixl web designers" style={{ width: "100%" }} />
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-lg-8 pt-5">
                            <h1>Social Media Marketing</h1>
                            <h1><span style={{ color: "#E11E5B" }}>Igniting Your Brand's</span>Digital Presence</h1>
                            <div className='my-4'>
                                <p>In an era where connections happen in real-time, harnessing the power of social media is not just a strategy; it’s a necessity. Explore how our Social Media Marketing services can propel your brand forward, foster meaningful connections, and drive tangible results.</p>
                                <p>Fueled by strategic thinkers, creative designers, content creators, and innovative technologists, we redefine global digital success through the artistry and impact of Social Media Marketing.</p>
                            </div>
                            <div>

                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for small and medium screens end */}
            {/* second container start */}
            <div className="container my-5">
                <div className='container'>
                    <h1 className='text-center'>Our Comprehensive Social Media Marketing Services</h1>
                </div>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#FFF5F0" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Social Media Strategy Development</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Crafting a customized social media strategy aligned with business goals, target audience, and industry trends.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 social-media-marketing-space-for-cards">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3F7FF" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Content Creation and Curation</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Developing engaging and shareable content tailored to each social media platform to captivate the target audience.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3FFFA" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Platform Management</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Active management of various social media platforms, including content scheduling, posting, and audience engagement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#FFF5F0", padding: "22.5px" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Community Building</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Fostering an engaged and loyal online community around the brand through proactive engagement and interaction.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 social-media-marketing-space-for-cards">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3F7FF" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Social Media Advertising</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Designing and executing targeted advertising campaigns on social media platforms to reach specific demographics.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3FFFA" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Influencer Marketing</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Collaborating with influencers and thought leaders to amplify brand reach and credibility within the target audience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* second container end */}
            {/* third container start */}
            <section className="featured-section">
                <h1 className='text-center pb-3'>As Featured On</h1>
                <div className="container text-center text-dark">
                    <Splide options={splideOptions}>
                        <SplideSlide>
                            <img src={ani} alt="ANI" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={daily} alt="Daily" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={hans} alt="Hans" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={jio} alt="Jio" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={latestly} alt="Latestly" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={lokmat} alt="Lokmat" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={na} alt="NA" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={web} alt="Web" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={SANGRI} alt="Sangri" className="featured-logo" />
                        </SplideSlide>
                    </Splide>
                </div>
            </section>
            {/* third container end */}
            {/* fourth container start */}
            <div className="container-fluid" style={{ backgroundColor: "#F9F9F9" }}>
                <div className="container" style={{ padding: "100px 0px" }}>
                    <div className="row">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 pb-5">
                            <div className='rounded-4' style={{ backgroundColor: "#270E4D", padding: "60px 40px 60px 40px", position: "relative" }}>
                                <img src={seoservicesprecise} alt="pixl designers" style={{ width: "100%" }} />
                                <img src={expertise} alt="pixl developers" className='social-media-marketing-expertise' />
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center">
                            <h1 className='ms-5 social-media-marketing-text-center'>We're a Social Media Marketing agency crafting experiences to elevate and scale businesses</h1>
                            <p className='ms-5 social-media-marketing-text-center pt-4 pb-2'>We create captivating Social Media Marketing experiences that elevate your business to unprecedented heights, reshaping your online presence with thoughtful and powerful solutions.</p>
                            <div className='ms-5 social-media-marketing-text-center'>
                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* fourth container end */}
            {/* fifth container start industries we serve */}
            <div className="container my-5">
                <div className="row">
                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12">
                        <h2>Industries We Serve</h2>
                        <p className="fs-4">
                            Utilizing a dynamic strategy, we seamlessly navigate through diverse industries, delivering innovative solutions that exceed limitations and elevate your presence in the world of Social Media Marketing.
                        </p>

                        <div className="d-flex flex-wrap gap-2 social-media-marketing-flex-wrap-icons">
                            <div className="text-center">
                                <img src={healthcare} alt="pixl india" />
                                <p>Healthcare</p>
                            </div>
                            <div className="text-center">
                                <img src={financial} alt="pixl web designers" />
                                <p>Financial</p>
                            </div>
                            <div className="text-center">
                                <img src={hospitality} alt="pixl designers" />
                                <p>Hospitality</p>
                            </div>
                            <div className="text-center">
                                <img src={education} alt="pixl developers" />
                                <p>Education</p>
                            </div>
                            <div className="text-center">
                                <img src={it} alt="pixl" />
                                <p>IT</p>
                            </div>
                            <div className="text-center">
                                <img src={fitness} alt="pixl india" />
                                <p>Fitness</p>
                            </div>
                            <div className="text-center">
                                <img src={marketing} alt="pixl developers" />
                                <p>Marketing</p>
                            </div>
                            <div className="text-center">
                                <img src={travel} alt="pixl designers" />
                                <p>Travel</p>
                            </div>
                            <div className="text-center">
                                <img src={legal} alt="pixl" />
                                <p>Legal</p>
                            </div>
                            <div className="text-center">
                                <img src={manufacturing} alt="pixl india" />
                                <p>Manufacturing</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12">
                        <img src={seo} alt="pixl developers" className="img-fluid social-media-image" />
                    </div>
                </div>
            </div>
            {/* fifth container end industries we serve */}
            {/* six container start large device*/}
            <div className="container-fluid py-5 social-media-marketing-six-container-lg-device" style={{ backgroundColor: "#FAFAFA" }}>
                <div className="container bg-white">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-end">
                            <div>
                                <SeparateForm />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 social-media-marketing-form-border-bottom">
                            <div>
                                <FaHeadphones className='fs-1 ms-4' color='#E11E5B' />
                            </div>
                            <h1 className='ms-4 py-4'>
                                Let Pixl be your Social <br /> Media Marketing partner
                            </h1>
                            <p className='ms-4'>Our dedicated expert team is committed to delivering customized strategies that yield results in the realm of Social Media Marketing. Whether your goal is to enhance brand visibility, boost website traffic, or maximize conversions, we’ve got you covered.</p>
                            <p className='ms-4'>Get in touch, and let the collaboration begin</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* six container end large device*/}
            {/* six container sm and md device start */}
            <div className="container-fluid py-5 social-media-marketing-six-container-sm-md-device" style={{ backgroundColor: "#FAFAFA" }}>
                <div className="container bg-white">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-bottom social-media-marketing-form">
                            <div>
                                <SeparateForm />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 social-media-marketing-form">
                            <div>
                                <FaHeadphones className='fs-1 ms-4 social-media-marketing-content' color='#E11E5B' />
                            </div>
                            <h1 className='ms-4 py-4 social-media-marketing-content'>
                                Let Pixl be your SEO <br />partner
                            </h1>
                            <p className='ms-4 social-media-marketing-content'>Our dedicated team of SEO experts is committed to delivering customized strategies that generate results. Whether your goal is to enhance brand visibility, increase website traffic, or maximize conversions through Search Engine Optimization, we’ve got you covered</p>
                            <p className='ms-4 social-media-marketing-content'>Get in touch, and let the collaboration begin</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* six container sm and md device end */}
        </>
    )
}

export default SocialMediaMarketing