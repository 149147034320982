import React, { useEffect, useState } from 'react';
import { IoCheckmark } from "react-icons/io5";
import './Getquote.css';
import ani from "../assets/ani.png";
import daily from "../assets/daily.png";
import hans from "../assets/hans.png";
import jio from "../assets/jio.png";
import latestly from "../assets/latestly.png";
import lokmat from "../assets/lokmat.png";
import na from "../assets/na.png";
import web from "../assets/web.png";
import SANGRI from "../assets/SANGRI.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import precision from '../Getquote/assets/22.png';
import years from '../Getquote/assets/years.png';
import { Helmet } from 'react-helmet';
import { Select, MenuItem, FormControl, InputLabel, Chip, Box } from '@mui/material';
import OrganizationSchema from '../OrganizationSchema';
import axios from 'axios';
import { getRequirementURL, requestCallURL } from '../../api/Api';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Getquote = () => {
  const initialRequestCallData = {
    first_name: '',
    last_name: '',
    mobile: '',
    requirements: [],
    email: ''
  };

  const [requirementData, setRequirementData] = useState([]);
  const [requestCall, setRequestCall] = useState(initialRequestCallData);

  useEffect(() => {
    const fetchRequirementData = async () => {
      try {
        const response = await axios.get(getRequirementURL);
        // console.log('data from requirement', response.data.data);
        setRequirementData(response.data.data);
      } catch (error) {
        // console.log('error occurred while fetching data', error);
      }
    };
    fetchRequirementData();
  }, []);

  const handleRequestCallChange = (e) => {
    const { name, value } = e.target;
    setRequestCall((prevRequestCall) => ({
      ...prevRequestCall,
      [name]: value
    }));
  };

  const handleRequirementChange = (event) => {
    const { value } = event.target;
    setRequestCall((prevRequestCall) => ({
      ...prevRequestCall,
      requirements: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleRequestCallSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(requestCallURL, requestCall);
      notify();
      // console.log('post response', response.data);
      setRequestCall(initialRequestCallData);
    } catch (error) {
      // console.log('error occurred while posting', error);
    }
  };

  const splideOptions = {
    type: "loop",
    perPage: 6,
    autoplay: true,
    interval: 3000,
    pauseOnHover: false,
    arrows: false,
    pagination: false,
    drag: false,
    gap: "1rem",
    breakpoints: {
      1024: { perPage: 4 },
      768: { perPage: 3 },
      480: { perPage: 2 },
      320: { perPage: 1 },
    },
  };

  const notify = () => {
    toast.success("Your Call Is Scheduled Successfully...", {
      position: "top-right"
    });
  };

  return (
    <>
    <ToastContainer />
      <Helmet>
        <OrganizationSchema />
        <title>
          Hire Pixl | Pixl Company India
        </title>
      </Helmet>
      {/* Collaborate Section starts for small screens */}
      <section className="collaborate-section getquote-collabrate-md-sm">
        <div className='container'>
          <div className='row'>
            {/* Form Section */}
            <div className='col-12 d-flex flex-column align-items-center'>
              <h4 className='mx-5 my-3'>Schedule a Call</h4>
              <div className="form-container">
                <div className="form-header">
                  <div className="button">
                    <div className="close"></div>
                    <div className="minimize"></div>
                    <div className="maximize"></div>
                  </div>
                </div>
                <form className="call-form" onSubmit={handleRequestCallSubmit}>
                  <div className="input-group">
                    <input type="text" id="firstName" name="first_name" placeholder="First Name" value={requestCall.first_name} onChange={handleRequestCallChange} className='get-quote-input-group' required />
                    <input type="text" id="lastName" name="last_name" placeholder="Last Name" value={requestCall.last_name} onChange={handleRequestCallChange} className='get-quote-input-group' required />
                  </div>
                  <input type="tel" id="telephone" name="mobile" placeholder="Telephone" value={requestCall.mobile} onChange={handleRequestCallChange} className='get-quote-input-group' required />
                  <FormControl fullWidth className='my-2'>
                    <InputLabel id="requirement-label">Requirement</InputLabel>
                    <Select
                      labelId="requirement-label"
                      id="requirement"
                      multiple
                      value={requestCall.requirements}
                      name='requirements'
                      onChange={handleRequirementChange}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={requirementData.find(req => req.id === value)?.name || value} />
                          ))}
                        </Box>
                      )}
                      label="Requirement"
                    >
                      {requirementData.map((data) => (
                        <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <input type="email" id="email" name="email" placeholder="Email" value={requestCall.email} onChange={handleRequestCallChange} className='get-quote-input-group' required />
                  <button type="submit" className='bg-primary text-light fw-bold getquote-form-button'>Submit</button>
                </form>
              </div>
            </div>
            {/* form section ends */}
            <div className='col-12 mt-5'>
       
            <h1 className='fw-medium'>Collaborate With Us</h1>
              
            
              <h1 className='fw-medium'>Accelerate your Growth</h1>
              <div className='col'>
                <IoCheckmark className='text-danger fs-1' />
                <span className='mx-2 fs-4'>Tailored solutions, strategic excellence.</span>
              </div>
              <div className='col'>
                <IoCheckmark className='text-danger fs-1' />
                <span className='mx-2 fs-4'>Swift project delivery, beating market timelines.</span>
              </div>
              <div className='col'>
                <IoCheckmark className='text-danger fs-1' />
                <span className='mx-2 fs-4'>Comprehensive digital transformation solutions from</span>
              </div>
              <div className='col'>
                <IoCheckmark className='text-danger fs-1 invisible' />
                <span className='mx-2 fs-4'> start to finish.</span>
              </div>
              <div className='mt-4' >
                <button className="btn rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824"   style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Collaborate Section for small screens ends */}
      {/* Collaborate Section for large screens starts */}
      <section className="collaborate-section getquote-collabrate-lg">
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 d-flex flex-column justify-content-center mt-5'>
           
                <h1 className='fw-medium'>Collaborate With Us</h1>
              
            
                <h1 className='fw-medium'>Accelerate your Growth</h1>
             
              <div className='col mt-3'>
                <IoCheckmark className='text-danger fs-1' />
                <span className=' fs-4'>Tailored solutions, strategic excellence.</span>
              </div>
              <div className='col'>
                <IoCheckmark className='text-danger fs-1' />
                <span className='fs-4'>Swift project delivery, beating market timelines.</span>
              </div>
              <div className='col'>
                <IoCheckmark className='text-danger fs-1' />
                <span className='fs-4'>Comprehensive digital transformation solutions from start to</span>
              </div>
              <div className='col me-1'>
                <IoCheckmark className='text-danger fs-1 invisible' />
                <span className='fs-4'>finish.</span>
              </div>
              <div>
                <button className="btn rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </button>
              </div>
            </div>
            {/* Form Section */}
            <div className='col-md-5'>
              <h2 className='mx-5 my-3 fw-bold'>Schedule a Call</h2>
              <div className="form-container">
                <div className="form-header">
                  <div className="get-quote-button">
                    <div className="close"></div>
                    <div className="minimize"></div>
                    <div className="maximize"></div>
                  </div>
                </div>
                <form className="call-form" onSubmit={handleRequestCallSubmit}>
                  <div className="input-group">
                    <input type="text" id="firstName" name="first_name" value={requestCall.first_name} onChange={handleRequestCallChange} placeholder="First Name" required />
                    <input type="text" id="lastName" name="last_name" value={requestCall.last_name} onChange={handleRequestCallChange} placeholder="Last Name" required />
                  </div>
                  <input type="tel" id="telephone" name="mobile" value={requestCall.mobile} onChange={handleRequestCallChange} placeholder="Telephone" required />
                  <FormControl fullWidth className='my-2'>
                    <InputLabel id="requirement-label">Requirement</InputLabel>
                    <Select
                      labelId="requirement-label"
                      id="requirement"
                      multiple
                      value={requestCall.requirements}
                      name='requirements'
                      onChange={handleRequirementChange}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={requirementData.find(req => req.id === value)?.name || value} />
                          ))}
                        </Box>
                      )}
                      label="Requirement"
                    >
                      {requirementData.map((data) => (
                        <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <input type="email" id="email" name="email" value={requestCall.email} onChange={handleRequestCallChange} placeholder="Email" required />
                  <button type="submit" className='bg-primary text-light fw-bold getquote-form-button'>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Collaborate Section for large screens ends */}
      {/* Featured On Section starts */}
      <section className="featured-section">
        <div className="container text-center text-dark">
          <h2 className="my-3 fw-bold">As Featured On</h2>
          <Splide options={splideOptions}>
            <SplideSlide>
              <img src={ani} alt="ANI" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={daily} alt="Daily" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={hans} alt="Hans" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={jio} alt="Jio" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={latestly} alt="Latestly" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={lokmat} alt="Lokmat" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={na} alt="NA" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={web} alt="Web" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={SANGRI} alt="Sangri" className="featured-logo" />
            </SplideSlide>
          </Splide>
        </div>
      </section>
      {/* Featured On Section ends */}
      {/* precise div starts */}
      <section className="bg-light py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 mb-5 my-3 mb-md-0">
              <div className="rounded-4 image-precision" style={{ backgroundColor: '#270E4D' }}>
                <img src={precision} alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" className="img-fluid w-100 rounded-4 mx-2 my-3" />
                <img src={years} alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" className='years' style={{ bottom: '10px', right: '10px' }} />
              </div>
            </div>
            <div className="col-md-7">
              <div className="ms-4">
                <h1 className='fw-bold'>We're a digital curating</h1>
                <h1 className='fw-bold'>experiences to scale</h1>
                <h1 className='fw-bold'>businesses</h1>
                <p className="my-4">We craft immersive digital experiences that propel your business to new heights, redefining your business landscape through strategic and impactful solutions</p>
                <div>
                  <button className="btn rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                    <a href="tel:+7382373824" style={{ textDecoration: 'none', color: 'inherit' }}>
                      +91 73823 73824
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* precise div ends */}
    </>
  );
};

export default Getquote;
