import React, { useEffect } from "react";
import logo from "../assets/pixl-logo.png";
import "./Navbar.css"; // Import custom CSS
import { Link } from "react-router-dom";
const Navbar = () => {
  useEffect(() => {
    const navItems = document.querySelectorAll('.nav-item.dropdown');
    navItems.forEach((item) => {
      item.addEventListener('mouseover', () => {
        const dropdown = item.querySelector('.dropdown-menu');
        if (dropdown) {
          dropdown.classList.add('show');
        }
      });
      item.addEventListener('mouseout', () => {
        const dropdown = item.querySelector('.dropdown-menu');
        if (dropdown) {
          dropdown.classList.remove('show');
        }
      });
    });
  }, []);
  const Handleclick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    // <div className="bg-body-white w-100 shadow sticky-top">
    <div className="bg-white shadow sticky-top navbar-custom">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-white d-flex align-items-center justify-content-between bg-white">
          <div className="d-flex align-items-center">
            <Link to={'/'} onClick={Handleclick}> <img
              src={logo}
              alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad"
            /></Link>
          </div>
          <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" aria-label="Toggle navigation" aria-expanded="false">
            <span className="navbar-toggler-animation">
              <span></span><span></span><span></span>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav d-flex justify-content-evenly align-items-center w-100">
              <li className="nav-item">
                <Link to={'/'} className="nav-link hover-bg-danger text-dark fw-small " aria-current="page" onClick={Handleclick} >
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link to={'/about-us'} onClick={Handleclick}
                  className="nav-link dropdown-toggle hover-bg-danger text-dark fw-small "
                  role="button"
                  aria-expanded="false"
                >
                  About Us
                </Link>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item hover-bg-danger text-dark fw-small " to={'/life-pixl'} onClick={Handleclick}>Life @ PIXL</Link></li>
                  <li><Link className="dropdown-item hover-bg-danger text-dark fw-small " to={'/our-team'} onClick={Handleclick}>Our Team</Link></li>
                </ul>
              </li>
              <li className="nav-item"><Link className="nav-link hover-bg-danger text-dark fw-small " to={'/web-designers'} onClick={Handleclick}>Web Designers</Link></li>
              <li className="nav-item"><Link className="nav-link hover-bg-danger text-dark fw-small " to={'/web-developers'} onClick={Handleclick}>Web Developers</Link></li>
              <li className="nav-item"><Link className="nav-link hover-bg-danger text-dark fw-small " to={'/ui-ux'} onClick={Handleclick}>UI/UX</Link></li>
              <li className="nav-item"><Link className="nav-link hover-bg-danger text-dark fw-small " to={'/mobile-apps'} onClick={Handleclick}>Mobile Apps</Link></li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle hover-bg-danger text-dark fw-small "
                  to={'/digital-marketing'}
                  role="button"
                  aria-expanded="false"
                  onClick={Handleclick}
                >
                  Digital Marketing
                </Link>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item hover-bg-danger text-dark fw-small " to={'/seo-services'} onClick={Handleclick}>SEO Services</Link></li>
                  <li><Link className="dropdown-item hover-bg-danger text-dark fw-small " to={'/pay-per-click-advertising'} onClick={Handleclick}>Pay-Per-Click Advertising</Link></li>
                  <li><Link className="dropdown-item hover-bg-danger text-dark fw-small " to={'/social-media-marketing'} onClick={Handleclick}>Social Media Marketing</Link></li>
                  <li><Link className="dropdown-item hover-bg-danger text-dark fw-small " to={'/email-marketing'} onClick={Handleclick}>Email Marketing</Link></li>
                  <li><Link className="dropdown-item hover-bg-danger text-dark fw-small " to={'/influencer-partnerships'} onClick={Handleclick}>Influencer Partnerships</Link></li>
                  <li><Link className="dropdown-item hover-bg-danger text-dark fw-small  " to={'/content-marketing'} onClick={Handleclick}>Content Marketing</Link></li>
                </ul>
              </li>
              <li className="nav-item"><Link className="nav-link hover-bg-danger text-dark fw-small" to={'/contact-us'} onClick={Handleclick}>Contact Us</Link></li>
            </ul>
            <Link to={'/get-quote'} className="text-decoration-none" onClick={Handleclick}>
              <div className="row">
                <button style={{ backgroundColor: '#E11E5B' }} className="btn text-light fw-small ms-3">Get Quote</button>
              </div>
            </Link>
          </div>
        </nav>
      </div>
      {/* off canvas start */}
      <div className="offcanvas offcanvas-start navbar-offcanvas" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ maxWidth: "300px" }}>
        <div className="offcanvas-header">
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to={'/'} className="nav-link hover-bg-danger text-dark fw-small " aria-current="page" onClick={Handleclick} >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/about-us'}
                onClick={Handleclick}
                className="nav-link dropdown-toggle text-dark fw-small "
                data-bs-toggle="dropdown"
                role="button"
                aria-expanded="false"      
              >
                About Us
              </Link>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item text-dark fw-small hover-bg-danger" to={'/about-us'} onClick={Handleclick}>About Us</Link></li>
                <li><Link className="dropdown-item text-dark fw-small hover-bg-danger" to={'/life-pixl'} onClick={Handleclick}>Life @ PIXL</Link></li>
                <li><Link className="dropdown-item text-dark fw-small hover-bg-danger" to={'/our-team'} onClick={Handleclick}>Our Team</Link></li>
              </ul>
            </li>
            <li className="nav-item"><Link className="nav-link hover-bg-danger text-dark fw-small " to={'/web-designers'} onClick={Handleclick}>Web Designers</Link></li>
            <li className="nav-item"><Link className="nav-link hover-bg-danger text-dark fw-small " to={'/web-developers'} onClick={Handleclick}>Web Developers</Link></li>
            <li className="nav-item"><Link className="nav-link hover-bg-danger text-dark fw-small " to={'/ui-ux'} onClick={Handleclick}>UI/UX</Link></li>
            <li className="nav-item"><Link className="nav-link hover-bg-danger text-dark fw-small " to={'/mobile-apps'} onClick={Handleclick}>Mobile Apps</Link></li>
            <li className="nav-item">
              <Link
                className="nav-link dropdown-toggle text-dark fw-small "
                data-bs-toggle="dropdown"
                role="button"
                aria-expanded="false"
              >
                Digital Marketing
              </Link>
              <ul className="dropdown-menu">
              <li><Link className="dropdown-item text-dark fw-small " to={'/digital-marketing'} onClick={Handleclick}>Digital Marketing</Link></li>
                <li><Link className="dropdown-item text-dark fw-small " to={'/seo-services'} onClick={Handleclick}>SEO Services</Link></li>
                <li><Link className="dropdown-item text-dark fw-small " to={'/pay-per-click-advertising'} onClick={Handleclick}>Pay-Per-Click Advertising</Link></li>
                <li><Link className="dropdown-item text-dark fw-small " to={'/social-media-marketing'} onClick={Handleclick}>Social Media Marketing</Link></li>
                <li><Link className="dropdown-item text-dark fw-small " to={'/email-marketing'} onClick={Handleclick}>Email Marketing</Link></li>
                <li><Link className="dropdown-item text-dark fw-small " to={'/influencer-partnerships'} onClick={Handleclick}>Influencer Partnerships</Link></li>
                <li><Link className="dropdown-item text-dark fw-small " to={'/content-marketing'} onClick={Handleclick}>Content Marketing</Link></li>
              </ul>
            </li>
            <li className="nav-item"><Link className="nav-link hover-bg-danger text-dark fw-small" to={'/contact-us'} onClick={Handleclick}>Contact Us</Link></li>
          </ul>
          <div className="mt-2">
            <Link to={'/get-quote'} className="text-decoration-none" onClick={Handleclick}>
              <button style={{ backgroundColor: '#E11E5B' }} className="btn text-light fw-small">Get Quote</button>
            </Link>
          </div>
        </div>
      </div>
      {/* off canvas end */}
    </div>
  );
};
export default Navbar;
