import React from 'react'
import ReactHelmet from 'react-helmet'
import mobileappdev from './assets/mobile-app-development.png'
import { SlLike } from "react-icons/sl";
import { FaDumbbell } from "react-icons/fa";
import { TfiTimer } from "react-icons/tfi";
import mobileappdevservice from './assets/mobile-app-development-service.png'
import { ImMobile } from "react-icons/im";
import { FaHeadphones } from "react-icons/fa6";
import uiuxprecise from './assets/22.png'
import expertise from './assets/vfdc.png'
import '../Mobile Apps/mobileapps.css';
import OrganizationSchema from '../OrganizationSchema';
import SeparateForm from '../Seperate Form/SeparateForm';
import { ToastContainer } from 'react-toastify';

const MobileApps = () => {
    return (
        <>
            <ToastContainer />
            <ReactHelmet>
                <OrganizationSchema />
                <title>
                    Mobile App Developers | Pixl Android ios Mobile App Development
                </title>
            </ReactHelmet>
            {/* for large screens start */}
            <div className="container-fluid mobileapps-firstcontainer-lgdevice" style={{ backgroundColor: "#FFF5F0" }}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8">
                            <h1>Mobile App Developers</h1>
                            <div className='my-4'>
                                <p>Embark on a transformative journey with our expert mobile app development team, where innovation meets excellence. At Pixl , we specialize in crafting bespoke solutions that redefine user experiences across Android , ios , native, cross-platform, and progressive web applications. Our commitment to delivering top-notch performance, human-centered design, and a unique selling proposition sets us apart in the dynamic and competitive market.</p>
                                <p>Transform your app idea into a digital masterpiece with Pixl . Let us be your technology partner, guiding you through every step of the development journey. Experience the synergy of innovation, design excellence, and performance-driven development. Your success is our mission</p>
                            </div>
                            <div>
                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                            <img src={mobileappdev} alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* for large screens end */}
            {/* for small and medium screens start */}
            <div className="container-fluid mobileapps-firstcontainer-sm-mddevice" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 pb-4">
                            <img src={mobileappdev} alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" style={{ width: "100%" }} />
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-lg-8">
                            <h1>Mobile App Developers</h1>
                            <div className='my-4'>
                                <p>Embark on a transformative journey with our expert mobile app development team, where innovation meets excellence. At Pixl , we specialize in crafting bespoke solutions that redefine user experiences across Android , ios , native, cross-platform, and progressive web applications. Our commitment to delivering top-notch performance, human-centered design, and a unique selling proposition sets us apart in the dynamic and competitive market.</p>
                                <p>Transform your app idea into a digital masterpiece with Pixl . Let us be your technology partner, guiding you through every step of the development journey. Experience the synergy of innovation, design excellence, and performance-driven development. Your success is our mission</p>
                            </div>
                            <div>
                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for small and medium screens end */}
            {/* second container start */}
            <div className="container my-5">
                <div>
                    <h1>Why Choose Pixl for App Development</h1>
                    <p className='my-4'>Pixl has stood as a pioneering force in mobile application development for nearly a decade. Our team is a blend of seasoned professionals and skilled developers who possess a profound understanding of the intricacies involved in crafting modern apps. When you choose <a href="https://pixl.in/" className='text-danger text-decoration-none'>Pixl</a> as your partner for mobile application development, you are guaranteed an app that not only delivers outstanding performance but is also backed by exceptional support.</p>
                </div>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 mt-2">
                            <div
                                className="card border-0 p-3"
                                style={{ backgroundColor: "#FFF5F0" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-1 col-sm-2 col-2 card-title">
                                            <span>
                                                <SlLike className="bi bi-clock p-2 text-light rounded-2" fontSize={50} style={{ backgroundColor: "#30B47C" }} />
                                            </span>
                                        </div>
                                        <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-11 col-sm-10 col-10 d-flex align-items-center">
                                            <h6 className="ms-3 fw-bold">
                                                Well-versed with Tech Stacks</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-2 text-secondary text-start">
                                        Pixl boosts a team of senior mobile app developers, core contributors to leading frameworks like React Native and Flutter. This positions us to recommend the optimal tech journey for your project, providing a significant advantage in delivering excellence
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 mt-2">
                            <div
                                className="card border-0 p-3"
                                style={{ backgroundColor: "#F3F7FF" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-1 col-sm-2 col-2 card-title">
                                            <span>
                                                <FaDumbbell className="bi bi-clock p-2 text-light rounded-2" fontSize={50} style={{ backgroundColor: "#E11E5B" }} />
                                            </span>
                                        </div>
                                        <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-11 col-sm-10 col-10 d-flex align-items-center">
                                            <h6 className="ms-3 fw-bold">Strong Mobile Portfolio</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-2 text-secondary text-start">
                                        Pixl stands at the forefront with a robust mobile portfolio, spotlighting our mastery in creating exceptional mobile applications. Our track record speaks volumes, illustrating our commitment to innovation and client success. Explore the depth of our expertise, Contact us
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 mt-2">
                            <div
                                className="card border-0 p-3"
                                style={{ backgroundColor: "#F3FFFA" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-1 col-sm-2 col-2 card-title">
                                            <span>
                                                <TfiTimer className="bi bi-clock p-2 text-light rounded-2" fontSize={50} style={{ backgroundColor: "#30B47C" }} />
                                            </span>
                                        </div>
                                        <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-11 col-sm-10 col-10 d-flex align-items-center">
                                            <h6 className="ms-3 fw-bold">End-to-end Support</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-2 text-secondary text-start">
                                        We shoulder the responsibility of ensuring your business reaps the rewards of a mobile app. From conceptualization and development to ongoing post-deployment support, our team is committed to guiding you through the entire journey of the application creation process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* second container end */}
            {/* third container start */}
            <div
                className="container-fluid py-5"
                style={{ backgroundColor: "#F6F9FB" }}
            >
                <div className="container">
                    <div className="row">
                        <div
                            className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 pb-5"
                            style={{ position: "relative" }}
                        >
                            <div className="InsuranceTeamBG">
                                <img src={mobileappdevservice} alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" style={{ width: "100%" }} />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                            <div>
                                <ImMobile className='fs-1 ms-5 mobile-apps-design' />
                            </div>
                            <div className="py-4">
                                <h1 className="ms-5 mobile-apps-design">Mobile App Development <br />Services by Pixl
                                </h1>
                            </div>
                            <div>
                                <p className="ms-5 mobile-apps-design">
                                    We offer tailored mobile app development services, aligning with your needs and powered by our expertise. Our adept development team seamlessly integrates the latest framework updates for a contemporary app. With optimized timelines, we ensure both efficiency and cost savings for your project
                                </p>
                            </div>
                            <ul className="mt-4 mobile-apps-list-content">
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>Cross-platform Development</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>
                                        Mobile App Design</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>
                                        App Maintenance and Support</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>
                                        App Optimization</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>Third-party Integration</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>
                                        Quality Assurance</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* fourth container start */}
                <div className="container my-5 py-5">
                    <div className="row">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 pb-5">
                            <div className='rounded-4' style={{ backgroundColor: "#270E4D", padding: "60px 40px 60px 40px", position: "relative" }}>
                                <img src={uiuxprecise} alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" style={{ width: "100%" }} />
                                <img src={expertise} alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" className='ui-ux-expertise' />
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center">
                            <h1 className='ms-5 mobile-apps-design'>We specialize in sculpting transformative mobile app experiences that elevate businesses</h1>
                            <p className='ms-5 mobile-apps-design pt-4 pb-2'>Embark on a journey of digital transformation with our specialized mobile app development services. We sculpt transformative app experiences meticulously designed to elevate businesses, ensuring they thrive in the ever-evolving digital landscape. Elevate your brand with innovation and excellence.</p>
                            <div className='ms-5 mobile-apps-design'>
                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* fourth container end */}
                {/* fifth container start large device*/}
                <div className="container bg-white mobileapps-fifth-container-lg-device">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-end">
                            <div>
                                <SeparateForm />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 ui-ux-form-border-bottom">
                            <div>
                                <FaHeadphones className='fs-1 ms-4' color='#E11E5B' />
                            </div>
                            <h1 className='ms-4 py-4'>
                                Let Pixl be your Mobile App <br /> Development company
                            </h1>
                            <p className='ms-4'>Whether your goal is to enhance app visibility, drive increased downloads, or maximize user engagement, we’ve got the expertise to meet your mobile app development objectives. Your success is our priority.</p>
                            <p className='ms-4'>Get in touch, and let the collaboration begin</p>
                        </div>
                    </div>
                </div>
                {/* fifth container end large device*/}
                {/* fifth container sm and md device start */}
                <div className="container bg-white mobileapps-fifth-container-sm-md-device py-2">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 border-bottom">
                            <div>
                                <SeparateForm />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 mobileapps-form-border-bottom mt-4">
                            <div>
                                <FaHeadphones className='fs-1' color='#E11E5B' />
                            </div>
                            <h2 className='py-4'>
                                Let Pixl be your Mobile App Development company
                            </h2>
                            <p>Whether your goal is to enhance app visibility, drive increased downloads, or maximize user engagement, we’ve got the expertise to meet your mobile app development objectives. Your success is our priority.</p>
                            <p>Get in touch, and let the collaboration begin</p>
                        </div>
                    </div>
                </div>
                {/* fifth container sm and md device end */}
            </div>
            {/* third container end */}
        </>
    )
}

export default MobileApps