import React from "react";
import ReactHelmet from 'react-helmet'
import insuranceteam from "./assets/Insurance-Team-Image.webp";
import screenshot3 from './assets/Screenshot_3.png';
import webbg from './assets/web-bg.png';
import java from './assets/java.png';
import react from './assets/react.png';
import sql from './assets/sql.png';
import dotnet from './assets/dotnet.png';
import laravel from './assets/laravel.png';
import psql from './assets/psql.png';
import mysql from './assets/mysql.png';
import obj from './assets/obj.png';
import node from './assets/node.png';
import mongodb from './assets/mongodb.png';
import php from './assets/php.png';
import webservices from './assets/webservices.png';
import firebase from './assets/firebase.png';
import '../Web developers/webdevelopers.css'
import OrganizationSchema from '../OrganizationSchema';

const WebDevelopers = () => {
  return (
    <>
      <ReactHelmet>
      <OrganizationSchema />
        <title>
          Web Development | Web Developers Hyderabad India Pixl
        </title>
      </ReactHelmet>
      <div className="container-fluid" style={{ backgroundColor: "#F3FFFA" }}>
        <div className="container py-5">
          {/* large screens start */}
          <div className="row web-developers-first-large">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-6 d-flex flex-column justify-content-center">
              <h1 className="mb-4">Expert Web Development <br /> Services Tailored for Success</h1>
              <p>
                Revitalize your online presence with our cutting-edge web development services. We craft visually stunning and highly functional websites, tailored to meet your business’s unique needs.
              </p>
              <p className="my-4">
                Our dedicated team of experts excels in providing tailored services that transcend the boundaries of technology. From responsive design to dynamic functionality, we work seamlessly across a spectrum of technologies, ensuring your web presence is not just an online platform but a strategic asset that sets you apart in the digital landscape. Embrace innovation with our bespoke web development services, where your vision meets the limitless possibilities of technology
              </p>
              <div>
                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </button>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6">
              <div className="text-center">
                <img src={webbg} alt="pixl" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          {/* large screens end */}
          {/* small screens start */}
          <div className="row web-developers-first-small">
            <div className="col-12">
              <div className="text-center pb-4">
                <img src={webbg} alt="pixl web designers" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="col-12">
              <h1 className="mb-4">Expert Web Development <br /> Services Tailored for Success</h1>
              <p>
                Revitalize your online presence with our cutting-edge web development services. We craft visually stunning and highly functional websites, tailored to meet your business’s unique needs.
              </p>
              <p className="my-4">
                Our dedicated team of experts excels in providing tailored services that transcend the boundaries of technology. From responsive design to dynamic functionality, we work seamlessly across a spectrum of technologies, ensuring your web presence is not just an online platform but a strategic asset that sets you apart in the digital landscape. Embrace innovation with our bespoke web development services, where your vision meets the limitless possibilities of technology
              </p>
              <div>
                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824" style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </button>
              </div>
            </div>
          </div>
          {/* small screens end */}
        </div>
      </div>
      <div
        className="container-fluid pt-5"
        style={{ backgroundColor: "#F6F9FB" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-xxl-5 col-xl-5 col-lg-5 col-md-5"
              style={{ position: "relative" }}
            >
              <div className="InsuranceTeamBG">
                <img src={insuranceteam} alt="pixl designers" style={{ width: "100%" }} />
                {/* first box start */}
                <div
                  className="container-fluid first-box-container"
                  style={{ position: "absolute", top: "250px", left: "-100px" }}
                >
                  <div className="row">
                    <div className="col-6 bg-white rounded-3 py-2">
                      <div className="row py-4">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                          <span>
                            <i className="bi bi-check-circle fs-2 text-danger"></i>
                          </span>
                        </div>
                        <div className="col-9 d-flex flex-column align-items-start">
                          <span className="text-danger mb-1 fs-5 fw-semibold">3000+</span>
                          <span className="text-secondary fs-5 fw-semibold">Happy Clients</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6"></div>
                  </div>
                </div>

                {/* first box end */}
                {/* second box start large screens*/}
                <div
                  className="container-fluid second-box-container-large"
                  style={{ position: "absolute", top: "350px", left: "-30px" }}
                >
                  <div className="row">
                    <div className="col-xxl-2 col-xl-2 col-lg-2"></div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3"></div>
                    <div className="col-xxl-7 col-xl-7 col-lg-7 bg-white rounded-3 py-2">
                      <div className="row py-4">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                          <span>
                            <i className="bi bi-check-circle fs-2 text-danger"></i>
                          </span>
                        </div>
                        <div className="col-9 d-flex flex-column align-items-start">
                          <span className="text-danger mb-1 fs-5 fw-semibold">30+</span>
                          <span className="text-secondary fs-5 fw-semibold">Creative Designers</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* second box end large screens*/}
                {/* second box start sm md screens */}
                <div
                  className="container-fluid second-box-container-sm-md"
                >
                  <div className="row">
                    <div className="col-md-9 col-sm-7 col-10 bg-white rounded-3 py-2">
                      <div className="row py-4">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                          <span>
                            <i className="bi bi-check-circle fs-2 text-danger"></i>
                          </span>
                        </div>
                        <div className="col-9 d-flex flex-column align-items-start">
                          <span className="text-danger mb-1 fs-5 fw-semibold">30+</span>
                          <span className="text-secondary fs-5 fw-semibold">Creative Designers</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1 col-sm-3 col-1"></div>
                    <div className="col-md-2 col-sm-2 col-1"></div>
                  </div>
                </div>
                {/* second box end sm md screens */}

                {/* third box start large screens*/}
                <div
                  className="container-fluid third-box-container-large"
                  style={{ position: "absolute", top: "620px", left: "30px" }}
                >
                  <div className="row">
                    <div className="col-xxl-2 col-xl-2 col-lg-2"></div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3"></div>
                    <div className="col-xxl-7 col-xl-7 col-lg-7 bg-white rounded-3 py-2">
                      <div
                        className="row py-4"
                        style={{
                          boxShadow:
                            "0px 35px 70px 0px rgba(0, 23.000000000000025, 102.99999999999994, 0.05)",
                        }}
                      >
                        <div className="col-3 d-flex justify-content-center align-items-center">
                          <span>
                            <i className="bi bi-check-circle fs-2 text-danger"></i>
                          </span>
                        </div>
                        <div className="col-9 d-flex flex-column align-items-start">
                          <span className="text-danger mb-1 fs-5 fw-semibold">16+</span>
                          <span className="text-secondary fs-5 fw-semibold">Years of Experience</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* third box end large screens*/}
                {/* third box start sm-md screens */}
                <div
                  className="container-fluid third-box-container-sm-md"
                >
                  <div className="row">
                    <div className="col-md-2 col-sm-5 col-2"></div>
                    <div className="col-md-10 col-sm-7 col-10 bg-white rounded-3 py-2">
                      <div
                        className="row py-4"
                      >
                        <div className="col-3 d-flex justify-content-center align-items-center">
                          <span>
                            <i className="bi bi-check-circle fs-2 text-danger"></i>
                          </span>
                        </div>
                        <div className="col-9 d-flex flex-column align-items-start">
                          <span className="text-danger mb-1 fs-5 fw-semibold">16+</span>
                          <span className="text-secondary fs-5 fw-semibold">Years of Experience</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* third box end sm-md screens */}
              </div>
            </div>
            {/* large screens embark start */}
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 web-developer-embark-large">
              <div>
                <i className="bi bi-circle-fill fs-1 ms-5"></i>
              </div>
              <div className="py-4">
                <h2 className="ms-5">
                  Transformative Web Development Solutions at Your Fingertips with PIXL
                </h2>
              </div>
              <div>
                <p className="ms-5 lh-lg fs-5">
                  Our dedicated team of experts collaborates with you to determine optimal development strategies, craft seamless functionalities, and ensure a robust digital presence for your brand
                </p>
              </div>
              <ul className="mt-4 fs-5 ">
                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none", }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Tailored Solutions</span>
                </li>
                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>
                    Seamless functionality by a dedicated team</span>
                </li>
                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>
                    Strategic collaboration for optimal development</span>
                </li>
                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Delivering excellence in every line of code</span>
                </li>
                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Seamless Content Integration</span>
                </li>
              </ul>
            </div>
            {/* large screens embark end */}
            {/* small screens embark start */}
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-7 web-developer-embark-small">
              <div>
                <i className="bi bi-circle-fill fs-1"></i>
              </div>
              <div className="py-4">
                <h2>
                  Transformative Web Development Solutions at Your Fingertips with PIXL
                </h2>
              </div>
              <div>
                <p className="lh-lg fs-5">
                  Our team of dedicated experts will collaborate with you to
                  determine the optimal design, craft engaging content, and
                  curate appropriate visuals
                </p>
              </div>
              <ul className="mt-4 fs-5 web-des-embark-list">
                <li style={{ listStyle: "none", }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Tailored Solutions</span>
                </li>
                <li style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>User-Centric Design</span>
                </li>
                <li style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Responsive Layouts</span>
                </li>
                <li style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Optimized Loading Speed</span>
                </li>
                <li style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Seamless Content Integration</span>
                </li>
              </ul>
            </div>
            {/* small screens embark end */}
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-xxl-7 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <h3 className="my-4">We Hold Expertise in the Following Technologies</h3>
            <p className="">We specify the elements to guarantee the quality of service (QoS) on networks with planned architecture and properly integrated services</p>
            <div className="d-flex flex-wrap gap-3 mt-4 web-dev-languages-wrap">
              <img className="languages-image" src={java} alt="pixl developers" width={150} />
              <img className="languages-image" src={react} alt="pixl india" width={150} />
              <img className="languages-image" src={sql} alt="pixl web designers" width={150} />
              <img className="languages-image" src={dotnet} alt="pixl developers" width={150} />
              <img className="languages-image" src={laravel} alt="pixl" width={150} />
              <img className="languages-image" src={psql} alt="pixl india" width={150} />
              <img className="languages-image" src={mysql} alt="pixl designers" width={150} />
              <img className="languages-image" src={obj} alt="pixl web designers" width={150} />
              <img className="languages-image" src={node} alt="pixl developers" width={150} />
              <img className="languages-image" src={mongodb} alt="pixl" width={150} />
              <img className="languages-image" src={php} alt="pixl developers" width={150} />
              <img className="languages-image" src={webservices} alt="pixl designers" width={150} />
              <img className="languages-image" src={firebase} alt="pixl india" width={150} />
            </div>
          </div>
          <div className="col-xxl-5 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 web-dev-image">
            <img src={screenshot3} alt="pixl developers" style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default WebDevelopers;
