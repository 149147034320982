import React from "react";
import ReactHelmet from 'react-helmet'
import wpcontent from "./assets/34.png";
import insuranceteam from "./assets/Insurance-Team-Image.webp";
import { FaDirections } from "react-icons/fa";
import "../Web Designers/webdesigners.css";
import OrganizationSchema from '../OrganizationSchema';

const WebDesigners = () => {
  return (
    <>
      <ReactHelmet>
      <OrganizationSchema />
        <title>
          Web Design Services | Website Designing | Pixl Web Designers
        </title>
      </ReactHelmet>
      <div className="container-fluid" style={{ backgroundColor: "#F3FFFA" }}>
        <div className="container py-5">
          {/* large screens start */}
          <div className="row pt-3 web-designers-largescreen">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-6 d-flex flex-column justify-content-center">
              <h1 className="mb-4">We Create Inspirational Websites</h1>
              <p className="lh-lg">
                Elevate your online presence with Pixl, where we specialize in
                crafting bespoke websites tailored to your unique vision. Our
                professional website building service ensures not only a
                stunning visual appeal but also optimal functionality on mobile
                devices.
              </p>
              <p className="my-4 lh-lg">
                Collaborating with our friendly experts, you’ll experience a
                seamless journey from design selection to engaging copy
                creation, all geared towards enhancing user experience. At Pixl,
                we don’t just create websites; we sculpt digital experiences
                that captivate, convert, and leave a lasting impression
              </p>
              <div>
                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </button>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6">
              <div className="text-center">
                <img src={wpcontent} alt="pixl" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          {/* large screens end */}
          {/* small screens start */}
          <div className="row web-designers-mobileview">
            <div className="col-12">
              <div className="text-center pb-4">
                <img src={wpcontent} alt="pixl developers" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="col-12">
              <h1 className="mb-4">We Create Inspirational Websites</h1>
              <p className="lh-lg">
                Elevate your online presence with Pixl, where we specialize in
                crafting bespoke websites tailored to your unique vision. Our
                professional website building service ensures not only a
                stunning visual appeal but also optimal functionality on mobile
                devices.
              </p>
              <p className="my-4 lh-lg">
                Collaborating with our friendly experts, you’ll experience a
                seamless journey from design selection to engaging copy
                creation, all geared towards enhancing user experience. At Pixl,
                we don’t just create websites; we sculpt digital experiences
                that captivate, convert, and leave a lasting impression
              </p>
              <div>
                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </button>
              </div>
            </div>
          </div>
          {/* small screens end */}
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-xxl-4 col-xl-4 col-lg-4">
            <div
              className="card border-0 p-3"
              style={{ backgroundColor: "#FFF5F0" }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-1 col-sm-2 col-2 card-title">
                    <span>
                      <i
                        className="bi bi-clock p-2 text-light rounded-2 fs-3"
                        style={{ backgroundColor: "#30B47C" }}
                      ></i>
                    </span>
                  </div>
                  <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-10 col-sm-10 col-10 d-flex align-items-center">
                    <h6>On Time Delivery</h6>
                  </div>
                </div>
                <p className="card-text mt-2">
                  We’re committed to on-time web design delivery, ensuring your digital presence launches precisely when you need it
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 web-design-cards-padding">
            <div
              className="card border-0 p-3"
              style={{ backgroundColor: "#F3F7FF" }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-1 col-sm-2 col-2 card-title">
                    <span>
                      <i
                        className="bi bi-asterisk p-2 text-light rounded-2 fs-3"
                        style={{ backgroundColor: "#E11E5B" }}
                      ></i>
                    </span>
                  </div>
                  <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-11 col-sm-10 col-10 d-flex align-items-center">
                    <h6>Creative Approach</h6>
                  </div>
                </div>
                <p className="card-text mt-2">
                  Our design approach is extraordinary , infusing innovation into every project for unique solutions that captivate your audience
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4">
            <div
              className="card border-0 p-3"
              style={{ backgroundColor: "#F3FFFA" }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-1 col-sm-2 col-2 card-title">
                    <span>
                      <i
                        className="bi bi-currency-dollar p-2 text-light rounded-2 fs-3"
                        style={{ backgroundColor: "#E11E5B" }}
                      ></i>
                    </span>
                  </div>
                  <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-11 col-sm-10 col-10 d-flex align-items-center">
                    <h6>Affordable Pricing</h6>
                  </div>
                </div>
                <p className="card-text mt-2">
                  Our affordable pricing guarantees top-tier services, letting
                  you achieve your digital goals without compromise
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid pt-5"
        style={{ backgroundColor: "#F6F9FB" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-xxl-6 col-xl-6 col-lg-6 col-md-5"
              style={{ position: "relative" }}
            >
              <div className="InsuranceTeamBG">
                <img src={insuranceteam} alt="pixl india" style={{ width: "100%" }} />
                {/* first box start */}
                <div
                  className="container-fluid first-box-container"
                  style={{ position: "absolute", top: "250px", left: "-30px" }}
                >
                  <div className="row">
                    <div className="col-5 bg-white rounded-3 py-2">
                      <div className="row py-4">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                          <span>
                            <i className="bi bi-check-circle fs-2 text-danger"></i>
                          </span>
                        </div>
                        <div className="col-9 d-flex flex-column align-items-start">
                          <span className="text-danger mb-1 fs-5 fw-semibold">3000+</span>
                          <span className="text-secondary fs-5 fw-semibold">Happy Clients</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-4"></div>
                  </div>
                </div>

                {/* first box end */}
                {/* second box start large screens*/}
                <div
                  className="container-fluid second-box-container-large"
                  style={{ position: "absolute", top: "350px", left: "-30px" }}
                >
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-4"></div>
                    <div className="col-6 bg-white rounded-3 py-2">
                      <div className="row py-4">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                          <span>
                            <i className="bi bi-check-circle fs-2 text-danger"></i>
                          </span>
                        </div>
                        <div className="col-9 d-flex flex-column align-items-start">
                          <span className="text-danger mb-1 fs-5 fw-semibold">30+</span>
                          <span className="text-secondary fs-5 fw-semibold">Creative Designers</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* second box end large screens*/}
                {/* second box start sm md screens */}
                <div
                  className="container-fluid second-box-container-sm-md"
                >
                  <div className="row">
                    <div className="col-md-9 col-sm-7 col-10 bg-white rounded-3 py-2">
                      <div className="row py-4">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                          <span>
                            <i className="bi bi-check-circle fs-2 text-danger"></i>
                          </span>
                        </div>
                        <div className="col-9 d-flex flex-column align-items-start">
                          <span className="text-danger mb-1 fs-5 fw-semibold">30+</span>
                          <span className="text-secondary fs-5 fw-semibold">Creative Designers</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-2 col-2"></div>
                  </div>
                </div>
                {/* second box end sm md screens */}

                {/* third box start large screens*/}
                <div
                  className="container-fluid third-box-container-large"
                  style={{ position: "absolute", top: "770px", left: "30px" }}
                >
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-4"></div>
                    <div className="col-6 bg-white rounded-3 py-2">
                      <div
                        className="row py-4"
                        style={{
                          boxShadow:
                            "0px 35px 70px 0px rgba(0, 23.000000000000025, 102.99999999999994, 0.05)",
                        }}
                      >
                        <div className="col-3 d-flex justify-content-center align-items-center">
                          <span>
                            <i className="bi bi-check-circle fs-2 text-danger"></i>
                          </span>
                        </div>
                        <div className="col-9 d-flex flex-column align-items-start">
                          <span className="text-danger mb-1 fs-5 fw-semibold">16+</span>
                          <span className="text-secondary fs-5 fw-semibold">Years of Experience</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* third box end large screens*/}
                {/* third box start sm-md screens */}
                <div
                  className="container-fluid third-box-container-sm-md"
                >
                  <div className="row">
                    <div className="col-md-2 col-sm-5 col-2"></div>
                    <div className="col-md-10 col-sm-7 col-10 bg-white rounded-3 py-2">
                      <div
                        className="row py-4"
                      >
                        <div className="col-3 d-flex justify-content-center align-items-center">
                          <span>
                            <i className="bi bi-check-circle fs-2 text-danger"></i>
                          </span>
                        </div>
                        <div className="col-9 d-flex flex-column align-items-start">
                          <span className="text-danger mb-1 fs-5 fw-semibold">16+</span>
                          <span className="text-secondary fs-5 fw-semibold">Years of Experience</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* third box end sm-md screens */}
              </div>
            </div>
            {/* large screens embark start */}
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-7 web-design-embark-large">
              <div>
                <i className="bi bi-circle-fill fs-1 ms-5"></i>
              </div>
              <div className="py-4">
                <h1 className="ms-5">
                  Embark on Your Digital <br /> Journey Today
                </h1>
              </div>
              <div>
                <p className="ms-5 lh-lg fs-5">
                  Our team of dedicated experts will collaborate with you to
                  determine the optimal design, craft engaging content, and
                  curate appropriate visuals
                </p>
              </div>
              <ul className="mt-4 fs-5 ">
                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none", }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Tailored Solutions</span>
                </li>
                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>User-Centric Design</span>
                </li>
                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Responsive Layouts</span>
                </li>
                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Optimized Loading Speed</span>
                </li>
                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Seamless Content Integration</span>
                </li>
              </ul>
            </div>
            {/* large screens embark end */}
            {/* small screens embark start */}
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-7 web-design-embark-small">
              <div>
                <i className="bi bi-circle-fill fs-1"></i>
              </div>
              <div className="py-4">
                <h2>
                  Embark on Your Digital <br /> Journey Today
                </h2>
              </div>
              <div>
                <p className="lh-lg fs-5">
                  Our team of dedicated experts will collaborate with you to
                  determine the optimal design, craft engaging content, and
                  curate appropriate visuals
                </p>
              </div>
              <ul className="mt-4 fs-5 web-des-embark-list">
                <li style={{ listStyle: "none", }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Tailored Solutions</span>
                </li>
                <li style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>User-Centric Design</span>
                </li>
                <li style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Responsive Layouts</span>
                </li>
                <li style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Optimized Loading Speed</span>
                </li>
                <li style={{ listStyle: "none" }}>
                  <span className="me-4">
                    <i className="bi bi-check2 text-danger fs-2"></i>
                  </span>
                  <span>Seamless Content Integration</span>
                </li>
              </ul>
            </div>
            {/* small screens embark end */}
          </div>
        </div>
      </div>
      <div className="container my-5 py-3 webdesigners-container-largedevice">
        {/* h1 large screen strat */}
        <h1 className="text-center">Our Web Design Process</h1>
        {/* h1 large screen end */}
        <div className="row mt-5">
          <div
            className="col-4 col-md-4 col-sm-12 text-center mt-5"
            style={{ position: "relative" }}
          >
            <span
              className="p-5 rounded-circle"
              style={{ boxShadow: "0 2px 6px -2px #c7c7c7" }}
            >
              <FaDirections className="fs-1 text-danger" />
              <span className="rounded-4 py-1 px-3 webdesigners-span-steps">
                Step 01
              </span>
            </span>
            <p className="text-center mt-5 fw-bold">Design</p>
            <p className="text-center text-secondary">
              Craft a visually appealing and user-friendly blueprint for the
              website, focusing on wireframes, visual aesthetics, and responsive
              design to create an engaging and intuitive user experience{" "}
            </p>
          </div>
          <div
            className="col-4 col-md-4 col-sm-12 text-center mt-5"
            style={{ position: "relative" }}
          >
            <span
              className="p-5 rounded-circle"
              style={{ boxShadow: "0 2px 6px -2px #c7c7c7" }}
            >
              <FaDirections className="fs-1 text-danger" />
              <span className="rounded-4 py-1 px-3 webdesigners-span-steps">
                Step 02
              </span>
            </span>
            <p className="text-center mt-5 fw-bold">Develop</p>
            <p className="text-center text-secondary">
              Translate the design into functional reality by coding the
              website, implementing features, and conducting thorough testing to
              ensure a seamless and bug-free performance across various devices
              and browsers
            </p>
          </div>
          <div
            className="col-4 col-md-4 col-sm-12 text-center mt-5"
            style={{ position: "relative" }}
          >
            <span
              className="p-5 rounded-circle"
              style={{ boxShadow: "0 2px 6px -2px #c7c7c7" }}
            >
              <FaDirections className="fs-1 text-danger" />
              <span className="rounded-4 py-1 px-3 webdesigners-span-steps">
                Step 03
              </span>
            </span>
            <p className="text-center mt-5 fw-bold">Deploy</p>
            <p className="text-center text-secondary">
              Launch the finalized website to a hosting server, making it
              accessible to the public. This involves the final steps of
              optimization, ensuring the site's efficiency, and initiating
              ongoing maintenance as needed
            </p>
          </div>
        </div>
      </div>
      {/* small devices start*/}
      <div className="container my-5 webdesigners-container-smalldevice">
        <h1 className="text-center">Our Web Design Process</h1>
        <div className="row mt-5">
          <div
            className="col-12 text-center mt-5"
            style={{ position: "relative" }}
          >
            <span
              className="p-5 rounded-circle"
              style={{ boxShadow: "0 2px 6px -2px #c7c7c7" }}
            >
              <FaDirections className="fs-1 text-danger" />
              <span className="rounded-4 py-1 px-3 webdesigners-span-steps">
                Step 01
              </span>
            </span>
            <p className="text-center mt-5">Design</p>
            <p className="text-center">
              Craft a visually appealing and user-friendly blueprint for the
              website, focusing on wireframes, visual aesthetics, and responsive
              design to create an engaging and intuitive user experience{" "}
            </p>
          </div>
          <div
            className="col-12 text-center mt-5"
            style={{ position: "relative" }}
          >
            <span
              className="p-5 rounded-circle"
              style={{ boxShadow: "0 2px 6px -2px #c7c7c7" }}
            >
              <FaDirections className="fs-1 text-danger" />
              <span className="rounded-4 py-1 px-3 webdesigners-span-steps">
                Step 02
              </span>
            </span>
            <p className="text-center mt-5">Develop</p>
            <p className="text-center">
              Translate the design into functional reality by coding the
              website, implementing features, and conducting thorough testing to
              ensure a seamless and bug-free performance across various devices
              and browsers
            </p>
          </div>
          <div
            className="col-12 text-center mt-5"
            style={{ position: "relative" }}
          >
            <span
              className="p-5 rounded-circle"
              style={{ boxShadow: "0 2px 6px -2px #c7c7c7" }}
            >
              <FaDirections className="fs-1 text-danger" />
              <span className="rounded-4 py-1 px-3 webdesigners-span-steps">
                Step 03
              </span>
            </span>
            <p className="text-center mt-5">Deploy</p>
            <p className="text-center">
              Launch the finalized website to a hosting server, making it
              accessible to the public. This involves the final steps of
              optimization, ensuring the site's efficiency, and initiating
              ongoing maintenance as needed
            </p>
          </div>
        </div>
      </div>
      {/* small devices end */}
    </>
  );
};

export default WebDesigners;
