import React from "react";
import './Home.css';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import girlimage from "../assets/pixl-web-designers.webp";
import Clutch1 from "../assets/Clutch1.png";
import GoodFirms from "../assets/GoodFirms.png";
import ISO from "../assets/ISO.png";
import Trustpilot from "../assets/Trustpilot.png";
import Google from "../assets/Google.png";
import ani from "../assets/ani.png";
import daily from "../assets/daily.png";
import hans from "../assets/hans.png";
import jio from "../assets/jio.png";
import latestly from "../assets/latestly.png";
import lokmat from "../assets/lokmat.png";
import na from "../assets/na.png";
import SANGRI from "../assets/SANGRI.png";
import web from "../assets/web.png";
import groupimage from "../assets/group image.jpg";
import presize from "../assets/precise.png";
import chairsimage from "../assets/pixl-india image.png";
import healthcare from "../assets/healthcare-qkmtt8b9gfqtfk9gmd9jkg610fx8ero6a5s9le38l8.png";
import education from "../assets/education_icon-qkmttew4s9ztotzwjy3xjwi9650swnean2cnybthdo.png";
import financial from "../assets/financial-services-qkmttd0gelx91m2muxaoewzbzda2h96tyt1ozrw9q4.png";
import it from "../assets/information-technology-qkmtt4jwp3lo54ex8bn1ah46mwfrjz98xn6boa8ta4.png";
import hospitality from "../assets/hospitality-qkmtt6fl2ro8scc6xcgafgn3to6hzdgplwhamu60xo.png";
import fitness from "../assets/Fitness-qkmttb4s0xuoee5d5whf9xgesljc1uzdajqq17z22k.png";
import marketing from "../assets/marketing-communication-qkmtsxz1d9cnvuohaqsnb0ryh7c723j4kqlxbcikho.png";
import travel from "../assets/travel-qkmtsw3czla38mr7lpze6191aflgmpbnwhaycslcu4.png";
import legal from "../assets/Legal-qkmtt1qe4lht6aj0osf5kztsuqtnwvy1x97v8gczss.png";
import manufacturing from '../assets/manufacturing-qkmtszupqxf8j2lqzrlwg0avnz2xhhql8zww9wfs58.png';
import verifiedagencey from '../assets/Verified-Agency-v3.svg';
import ReactHelmet from 'react-helmet'
import { Link } from "react-router-dom";
import OrganizationSchema from '../OrganizationSchema';

const Home = () => {
  const splideOptions = {
    type: "loop",
    perPage: 6,
    autoplay: true,
    interval: 3000,
    pauseOnHover: false,
    arrows: false,
    pagination: false,
    drag: true,
    gap: "1rem",
    breakpoints: {
      768: {
        perPage: 3,
      }
    }


  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <ReactHelmet>
        <OrganizationSchema />
        <title>
          Pixl - A Global Web Design and Digital Tech Agency
        </title>
        <link rel="preload" as="image" href={girlimage} fetchPriority="high" />
      </ReactHelmet>
      {/* innovations  div starts  for below 1024 scrrens starts*/}
      <div className="container my-4 homepage-small-screens mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <img src={girlimage} alt="pixl" className="img-fluid w-100" />
          </div>
          <div className="col-12 mt-5">
            <h1 className="fw-bold">We Inspire <span style={{ color: "#E11E5B" }}>Innovations</span> that</h1>
            <h1 className="fw-bold">Drive Success with Precision</h1>
            <p className="mt-3" style={{fontSize:"0.999rem"}}>
              PIXL specializes in enhancing your digital footprint through exceptional digital marketing services,
              innovative web design solutions, user-friendly mobile applications, and cutting-edge UI/UX designs.
            </p>
            <p className="mt-2" style={{fontSize:"0.999rem"}}>
              Our expertise transforms contemporary businesses, seamlessly navigating them into the digital age.
            </p>
            <div>
              <Link to={'/contact-us'} onClick={handleClick}><button className="btn btn-dark btn-lg text-light px-5 py-3 rounded-3 fw-bold border-0" style={{ backgroundColor: '#E11E5B' }}>
                Contact us
              </button></Link>
            </div>
            <div className="text-center text-dark mt-5">
              <Splide options={splideOptions}>
                <SplideSlide>
                  <img src={Clutch1} alt="Clutch" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={GoodFirms} alt="GoodFirms" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={ISO} alt="ISO" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={Trustpilot} alt="Trustpilot" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={Google} alt="Google" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={Clutch1} alt="Clutch" className="img-fluid" />
                </SplideSlide>
              </Splide>

            </div>
          </div>
        </div>
      </div>
      {/* innovations  div starts  for below 1024 scrrens ends*/}
      {/* innovations  div starts  for above 1024 scrrens starts*/}
      <div className="container mt-5 homepage-large-screens">
        <div className="row">
          <div className="col-7 d-flex flex-column justify-content-center">
            <h1 className="fw-bold">We Inspire <span style={{ color: "#E11E5B" }}>Innovations</span></h1>
            <h1 className="fw-bold">that Drive Success with Precision</h1>
            <p className="py-3">
              PIXL specializes in enhancing your digital footprint through exceptional digital marketing services,
              innovative web design solutions, user-friendly mobile applications, and cutting-edge UI/UX designs.
            </p>
            <p className="">
              Our expertise transforms contemporary businesses, seamlessly navigating them into the digital age.
            </p>
            <div className="py-3">
              <Link to={'/contact-us'} onClick={handleClick}><button className="btn  btn-lg text-light px-5 py-3 rounded-3 fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                Contact us
              </button></Link>
            </div>
            <div className="row text-center text-dark mt-5">
              <Splide options={splideOptions}>
                <SplideSlide>
                  <img src={Clutch1} alt="Clutch" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={GoodFirms} alt="GoodFirms" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={ISO} alt="ISO" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={Trustpilot} alt="Trustpilot" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={Google} alt="Google" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={Clutch1} alt="Clutch" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={GoodFirms} alt="GoodFirms" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                  <img src={ISO} alt="ISO" className="img-fluid" />
                </SplideSlide>

              </Splide>
            </div>
          </div>
          <div className="col-5">
            <img src={girlimage} alt="pixl india" className="img-fluid w-100" />
          </div>
        </div>
      </div>
      {/* innovations  div starts  for above 1024 scrrens ends*/}
      {/* // Services We Offer div starts// */}
      <div className="container-fluid pb-5" style={{ backgroundColor: "#FAFAFA" }}>
        <div className="container">
          <h1 className="text-dark text-center pt-5 pb-3">Services We Offer</h1>
          <div className="row">
            <div className="col-md-4 mb-3">
              <Link to={'/web-designers'} className="text-decoration-none" onClick={handleClick}>
                <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#FFF7F9" }}>
                  <div className="card-body">
                    <h5 className="card-title fw-bold">
                      <i className="fa-solid fa-desktop p-3 rounded-3  text-light me-3" style={{ backgroundColor: '#30B47C' }}></i>
                      Web Design
                    </h5>
                    <p className="card-text">
                      Elevate your online presence with our expert web design
                      services, seamlessly blending creativity and functionality
                    </p>
                  </div>
                </div></Link>
            </div>
            <div className="col-md-4 mb-3">
              <Link to={'/web-developers'} className="text-decoration-none" onClick={handleClick}>   <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    <i className="fab fa-slideshare p-3 rounded-3  text-light me-3" style={{ backgroundColor: '#E11E5B' }} ></i> Web Development
                  </h5>
                  <p className="card-text">
                    From concept to code, we turn ideas into tangible success with
                    our exceptional web development expertise
                  </p>
                </div>
              </div></Link>
            </div>
            <div className="col-md-4 mb-3">
              <Link to={'/mobile-apps'} className="text-decoration-none" onClick={handleClick}>  <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#F3FFFA" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold"><i className="fa-solid fa-mobile  p-3 rounded-3  text-light me-3" style={{ backgroundColor: '#36C6F0' }}></i>App Development</h5>
                  <p className="card-text">
                    Ignite innovation and engagement with our cutting-edge mobile
                    app development that turns ideas into interactive experiences
                  </p>
                </div>
              </div></Link>
            </div>
            <div className="col-md-4 mb-3">
              <Link to={'/digital-marketing'} className="text-decoration-none" onClick={handleClick}>  <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#F3FFFA" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    <i className="fa-solid fa-bullhorn p-3 rounded-3  text-light me-3 " style={{ backgroundColor: '#E11E5B' }}  ></i>Digital Marketing</h5>
                  <p className="card-text">
                    Boost your online presence with our data-driven digital
                    marketing solutions to expand your business online
                  </p>
                </div>
              </div></Link>
            </div>
            <div className="col-md-4 mb-3">
              <Link to={'/ui-ux'} className="text-decoration-none" onClick={handleClick}>  <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold"><i className="fa-regular fa-eye p-3 rounded-3  text-light me-3 " style={{ backgroundColor: '#36C6F0' }}  ></i>UI/UX Development</h5>
                  <p className="card-text">
                    We go beyond aesthetics, meticulously designing interfaces
                    that not only delight users but also enhance usability
                  </p>
                </div>
              </div></Link>
            </div>
            <div className="col-md-4 mb-3">
              <Link to={'/mobile-apps'} className="text-decoration-none" onClick={handleClick}> <div className="card border-0 rounded-3 p-1" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">        <i className="fa-solid fa-desktop p-3 rounded-3  text-light me-3" style={{ backgroundColor: '#30B47C' }}></i>Custom Applications</h5>
                  <p className="card-text">
                    We specialize in custom applications for a seamless blend of
                    functionality, efficiency, and user-centric design
                  </p>
                </div>
              </div></Link>
            </div>
          </div>
        </div>
      </div>
      {/* services div end */}
      {/* as featuredon div starts */}
      <div className="container my-4">
        <div className="row text-center text-dark">
          <h2 className="my-3">As Featured On</h2>
          <Splide options={splideOptions}>
            <SplideSlide>
              <img src={ani} alt="ANI" className="responsive-img" />
            </SplideSlide>
            <SplideSlide>
              <img src={daily} alt="Daily" className="responsive-img" />
            </SplideSlide>
            <SplideSlide>
              <img src={hans} alt="Hans" className="responsive-img" />
            </SplideSlide>
            <SplideSlide>
              <img src={jio} alt="Jio" className="responsive-img" />
            </SplideSlide>
            <SplideSlide>
              <img src={latestly} alt="Latestly" className="responsive-img" />
            </SplideSlide>
            <SplideSlide>
              <img src={lokmat} alt="Lokmat" className="responsive-img" />
            </SplideSlide>
            <SplideSlide>
              <img src={na} alt="NA" className="responsive-img" />
            </SplideSlide>
            <SplideSlide>
              <img src={web} alt="Web" className="responsive-img" />
            </SplideSlide>
            <SplideSlide>
              <img src={SANGRI} alt="Sangri" className="responsive-img" />
            </SplideSlide>
          </Splide>
        </div>
      </div>
      <section className="container-fluid Unleashing-Excellence-bg-image" >
        <div className="container py-5 home-padding-sectionn">
          <div className="row">
            <div className="col-md-6" >
              <img src={groupimage} alt="pixl designers" className="img-fluid" style={{ borderRadius: '20px' }} />
            </div>
            <div className="col-md-6">
              {/* large screens start */}
              <div className="ms-4 home-large-screens">
                <h1 className="fw-semibold">Unleashing Excellence</h1>
                <p className="fs-4 fw-bold" style={{ color: "#6F6F6F" }}>
                  Delivering Outstanding Digital Experiences
                </p>
                <p className="fs-5">
                  Maximize your business potential with our collaborative approach.
                  From websites to UI/UX, custom applications to mobile development,
                  we at Pixl do it all. Let’s create extraordinary digital
                  experiences that truly connect with your audience
                </p>
                <hr />
                <h4 className="fw-bold"> <i className="fa-solid fa-bullseye pe-3"></i>Our Mission</h4>
                <span className="fs-5">
                  Fueled by innovation, our mission is to transform your digital
                  presence, propelling your business to new heights
                </span>
                <div className="col-md-4 py-3">
                  <Link to={'/about-us'} onClick={handleClick}><button className="text-light px-4 py-3 rounded-3 fw-bold" style={{ backgroundColor: "#E11E5B", border: "none" }}>Read More</button></Link>
                </div>
              </div>
              {/* large screens end */}
              {/* small screens start */}
              <div className="mt-4 home-small-screens">
                <h1 className="fw-semibold">Unleashing Excellence</h1>
                <p className="fs-4 fw-bold" style={{ color: "#6F6F6F" }}>
                  Delivering Outstanding Digital Experiences
                </p>
                <p>
                  Maximize your business potential with our collaborative approach.
                  From websites to UI/UX, custom applications to mobile development,
                  we at Pixl do it all. Let’s create extraordinary digital
                  experiences that truly connect with your audience
                </p>
                <hr />
                <h4 className="fw-bold"> <i className="fa-solid fa-bullseye pe-3"></i>Our Mission</h4>
                <span>
                  Fueled by innovation, our mission is to transform your digital
                  presence, propelling your business to new heights
                </span>
                <Link to={'about-us'} onClick={handleClick}> <div className="col-md-4 pt-4">

                  <button className="text-light px-4 py-3 rounded-3 fw-bold" style={{ backgroundColor: "#E11E5B", border: "none" }}>Read More</button>
                </div></Link>
              </div>
              {/* small screens end */}
            </div>
          </div>
        </div>
      </section>
      {/* Unleashing Excellence ends */}
      {/* precise div starts */}
      <div className="container my-5 home-margin-section">
        <div className="row py-5 home-padding-section">
          <div className="col-md-5 text bg-dark" style={{ borderRadius: '20px' }}>
            <img src={presize} alt="pixl developers" className="img-fluid" />
          </div>
          <div className="col-md-7 d-flex flex-column justify-content-center">
            {/* large screens start */}
            <div className="ms-4 home-large-screens">
              <h1 className="fw-semibold fs-1">Pixl Is A Digital Agency <br />Curating Experiences That <br /> Scale Businesses</h1>
              <p className="fs-4 py-2">
                At Pixl, we craft immersive digital experiences that propel your
                business to new heights, redefining your business landscape
                through strategic and impactful solutions
              </p>
              <div className="col-md-4 py-3">
                <Link to={'/contact-us'} onClick={handleClick}><button className="text-light px-3 py-3 rounded-3 fw-bold" style={{ backgroundColor: "#E11E5B", border: "none" }}>Connect With Us</button></Link>
              </div>
            </div>
            {/* large screens end */}
            {/* small screens start */}
            <div className="mt-3 home-small-screens">
              <h1 className="fw-semibold fs-1">Pixl Is A Digital Agency <br />Curating Experiences <br />That Scale Businesses</h1>
              <p className="fs-4 py-2">
                At Pixl, we craft immersive digital experiences that propel your
                business to new heights, redefining your business landscape
                through strategic and impactful solutions
              </p>
              <Link to={'/contact-us'} onClick={handleClick}>  <div className="col-md-4 py-3 home-padding-section">
                <button className="text-light px-3 py-3 rounded-3 fw-bold" style={{ backgroundColor: "#E11E5B", border: "none" }}>Conect With Us</button>
              </div></Link>

            </div>
            {/* small screens end */}
          </div>
        </div>
      </div>
      {/* precise div ends */}
      {/* Industries We Serve starts */}
      <section>
        {/* Industries We Serve starts */}
        <div className="container pt-4">
          <div className="row">
            <div className="col">
              <h1 className="fw-semibold">Industries We Serve</h1>
              <p className="fs-5">
                With a dynamic approach, we at Pixl seamlessly navigate diverse
                industries, delivering cutting-edge solutions that transcend
                boundaries and elevate your digital presence
              </p>
              <div className="container">
                <div className="row pt-4 text-center row-gap-4">
                  <div className="col-4  col-lg-2 col-sm-3 col-md-3">
                    <img src={healthcare} alt="Healthcare" />
                    <span className='text-secondary d-block mt-2'>Healthcare</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 ">
                    <img src={financial} alt="Financial" />
                    <span className='text-secondary d-block mt-2'>Financial</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={hospitality} alt="Hospitality" />
                    <span className='text-secondary d-block mt-2'>Hospitality</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 ">
                    <img src={education} alt="Education" />
                    <span className='text-secondary d-block mt-2'>Education</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={it} alt="IT" />
                    <span className='text-secondary d-block mt-2'>IT</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={fitness} alt="Fitness" />
                    <span className='text-secondary d-block mt-2'>Fitness</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={marketing} alt="Marketing " />
                    <span className='text-secondary d-block mt-2'>Marketing</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4">
                    <img src={travel} alt="Travel" />
                    <span className='text-secondary d-block mt-2'>Travel</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4">
                    <img src={legal} alt="Legal" />
                    <span className='text-secondary d-block mt-2'>Legal</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4">
                    <img src={manufacturing} alt="Legal" />
                    <span className='text-secondary d-block mt-2'>Manufacturing</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src={chairsimage} alt="pixl web designers" className="img-fluid" />
            </div>
          </div>
        </div>
        {/* Industries We Serve ends */}
      </section>
      {/* Industries We Serve ends */}
      {/* pixl section starts */}
      <section className="container-fluid py-5" style={{ backgroundColor: '#F8F9FA' }}>
        <div className="container shadow bg-white rounded-3 my-1 p-5 home-section-work-together">
          <span className="fs-3 text-dark fw-bold"> <i className="fa-solid fs-2 p-2  fa-headphones text-danger me-4 rounded-2 "></i>Pixl</span>
          <h1 className="my-4">Let's Work Together</h1>
          <p className="fs-5">
            From sleek designs to seamless functionality, we’re dedicated to building websites that not only look great but also deliver exceptional user experiences.
          </p>
          <p className="fs-5 fw-bold text-dark">Get in touch, and let the collaboration begin</p>
          <a href="tel:+91 73823 73824" className="text-light" style={{ textDecoration: 'none', color: 'inherit' }}><button className="btn rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-semibold" style={{ backgroundColor: "#E11E5B" }}>
            +91 73823 73824
          </button></a>
        </div>
      </section>
      {/* pixl section ends */}
      {/* Frequently asked questions. section starts */}
      <section className="container-fluid py-5" style={{ backgroundColor: "#F8F9FA" }}>
        <div className="container py-4">
          <div className="row" >
            <div className="col-lg-6 col-12 mb-4 d-flex flex-column justify-content-center" >
              <div><h1 className="fs-1 fw-semibold">Frequently asked questions.</h1>
                <p className="fs-5">Do you have more questions about Pixl?</p>
                <div className="pb-4">
                  <a href="tel:+91 73823 73824" className=" fs-5 text-danger fw-bold"> <span className="text-dark">call: +91 73823 73824 </span></a>
                </div>
              </div>
              <div>
                <a href={'https://www.designrush.com/'}><img src={verifiedagencey} alt="pixl" style={{ width: '50%' }} /></a>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="accordion" id="accordionExample" >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button fs-4 text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      What services do you offer in web design and development?
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>Pixl specializes in comprehensive web design and development services, including responsive website design, custom web development, content management systems (CMS), and e-commerce solutions. Our goal is to create visually appealing and functionally robust websites tailored to your business needs</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed fs-4 text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      How do you approach UI/UX design for websites and applications?
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>Our UI/UX design process is user-centric and focused on creating intuitive and seamless experiences. We conduct thorough user research, wireframing, and prototyping to ensure a user-friendly interface. Our designs not only enhance usability but also align with your brand identity for a cohesive digital presence</span>      </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed fs-4 text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      What mobile app development services do you provide?
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>At Pixl , We offer end-to-end mobile app development services for iOS and Android platforms. From conceptualization and design to development and deployment, our team ensures that your mobile app meets industry standards and provides a smooth and engaging user experience</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed fs-4 text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                      How can digital marketing benefit my business?
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>Digital marketing is a powerful strategy to enhance your online presence, reach a wider audience, and boost business growth. Pixl digital marketing services include search engine optimization (SEO), social media marketing, content marketing, and pay-per-click (PPC) advertising. We tailor strategies to your business goals, driving targeted traffic and maximizing ROI</span>      </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed fs-4 text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                      Do you provide ongoing support and maintenance for websites and apps?
                    </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>Yes, Pixl offers ongoing support and maintenance services to ensure your websites and applications remain secure, up-to-date, and perform optimally. Our team monitors for updates, fixes bugs, and provides technical support to address any issues, allowing you to focus on your business while we take care of the technical details.</span>      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Frequently asked questions. section ends */}
    </>
  );
};

export default Home;
