import React from 'react'
import ReactHelmet from 'react-helmet'
import searchengine from './assets/PPC-top-image.webp'
import seoservicesprecise from './assets/22.png'
import expertise from './assets/vfdc.png'
import education from './assets/education_icon.png'
import financial from './assets/financial-services.png'
import fitness from './assets/Fitness.png'
import healthcare from './assets/healthcare.png'
import hospitality from './assets/hospitality.png'
import it from './assets/information-technology.png'
import legal from './assets/Legal.png'
import manufacturing from './assets/manufacturing.png'
import marketing from './assets/marketing-communication.png'
import travel from './assets/travel.png'
import seo from './assets/PPC-campaign.png'
import { FaHeadphones } from "react-icons/fa6";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ani from "../assets/ani.png";
import daily from "../assets/daily.png";
import hans from "../assets/hans.png";
import jio from "../assets/jio.png";
import latestly from "../assets/latestly.png";
import lokmat from "../assets/lokmat.png";
import na from "../assets/na.png";
import SANGRI from "../assets/SANGRI.png";
import web from "../assets/web.png";
import "@splidejs/react-splide/css";
import '../Pay Per Click Advertising/payperclickadvertising.css';
import OrganizationSchema from '../OrganizationSchema';
import SeparateForm from '../Seperate Form/SeparateForm'
import { ToastContainer } from 'react-toastify'

const PayPerClickAdvertising = () => {
    const splideOptions = {
        type: "loop",
        perPage: 6,
        autoplay: true,
        interval: 3000,
        pauseOnHover: false,
        arrows: false,
        pagination: false,
        drag: false,
        gap: "1rem",
        breakpoints: {
            1024: { perPage: 4 },
            768: { perPage: 3 },
            480: { perPage: 2 },
            320: { perPage: 1 },
        },
    };
    return (
        <>
            <ToastContainer />
            <ReactHelmet>
                <OrganizationSchema />
                <title>
                    Pay-Per-Click Advertising Services | Pixl Digital Marketing PPC
                </title>
            </ReactHelmet>
            {/* for large screens start */}
            <div className="container-fluid pay-per-click-firstcontainer-lgdevice" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 d-flex flex-column justify-content-center">
                            <h1>Unlocking Digital Potential</h1>
                            <h1><span style={{ color: "#E11E5B" }}>Dive into the World of</span>Pay-Per-Click Mastery</h1>
                            <div className='my-4'>
                                <p>Our PPC services are designed to propel your brand to the forefront of online visibility, ensuring strategic placements that resonate with your target audience. Explore how our expert team leverages the power of paid advertising to drive immediate and measurable results for your business.</p>
                                <p>Fueled by strategic minds, creative designers, content creators, and innovative technologists, we redefine global digital success through the precision and impact of Pay-Per-Click Advertising.</p>
                            </div>
                            <div>
                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                            <img className='ms-2' src={searchengine} alt="pixl" style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* for large screens end */}
            {/* for small and medium screens start */}
            <div className="container-fluid pay-per-click-firstcontainer-sm-mddevice" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="container py-3  ">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                            <img src={searchengine} alt="pixl web designers" style={{ width: "100%" }} />
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-lg-8">
                            <h1>Unlocking Digital Potential</h1>
                            <h1><span style={{ color: "#E11E5B" }}>Dive into the World of </span> Pay-Per-Click Mastery</h1>
                            <div className='my-4'>
                                <p>Our PPC services are designed to propel your brand to the forefront of online visibility, ensuring strategic placements that resonate with your target audience. Explore how our expert team leverages the power of paid advertising to drive immediate and measurable results for your business.</p>
                                <p>Fueled by strategic minds, creative designers, content creators, and innovative technologists, we redefine global digital success through the precision and impact of Pay-Per-Click Advertising.</p>
                            </div>
                            <div>
                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for small and medium screens end */}
            {/* second container start */}
            <div className="container my-5">
                {/* pay per click large screen start */}
                <div className='container pay-per-click-container-large'>
                    <h1 className='text-center'>Our Pay-Per-Click Advertising Services Unleash Digital</h1>
                    <h1 className="text-center">Success</h1>
                </div>
                {/* pay per click large screen end */}
                {/* pay per click sm md screen start */}
                <div className='container pay-per-click-container-sm-md'>
                    <h2 className='text-center'>Our Pay-Per-Click Advertising Services Unleash Digital</h2>
                    <h2 className="text-center">Success</h2>
                </div>
                {/* pay per click sm md screen end */}
                <div className="container my-5">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#FFF5F0" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Strategic Campaign Development</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Craft customized Pay-Per-Click (PPC) campaigns aligned with business goals, ensuring precision targeting and impactful results.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 pay-per-click-space-for-cards">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3F7FF" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Keyword Research and Optimization</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Conduct in-depth keyword research to optimize ad relevance, minimize costs, and enhance the effectiveness of PPC campaigns and search behaviors.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3FFFA" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Ad Copy Creation and A/B Testing</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Develop compelling ad copies and continuously test variations to maximize click-through rates and improve overall ad performance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#FFF5F0", padding: "22.5px" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Landing Page Optimization</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Optimize landing pages to enhance user experience, improve conversion rates, and ensure a seamless journey from click to conversion.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 pay-per-click-space-for-cards">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3F7FF" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Ad Extensions and Enhancements</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Utilize ad extensions and enhancements to provide additional information, increase ad visibility, and encourage user engagement and keep links updated.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3FFFA" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Ad Performance Reporting</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Provide detailed and transparent reports on ad performance, offering insights into key metrics and the overall success of PPC campaigns for report.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* second container end */}
            {/* third container start */}
            {/* Featured On Section  starts*/}
            <section className="featured-section">
                <h1 className='text-center pb-3'>As Featured On</h1>
                <div className="container text-center text-dark">
                    <Splide options={splideOptions}>
                        <SplideSlide>
                            <img src={ani} alt="ANI" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={daily} alt="Daily" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={hans} alt="Hans" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={jio} alt="Jio" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={latestly} alt="Latestly" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={lokmat} alt="Lokmat" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={na} alt="NA" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={web} alt="Web" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={SANGRI} alt="Sangri" className="featured-logo" />
                        </SplideSlide>
                    </Splide>
                </div>
            </section>
            {/* Featured On Section ends*/}
            {/* third container end */}
            {/* fourth container start */}
            <div className="container-fluid" style={{ backgroundColor: "#F9F9F9" }}>
                <div className="container" style={{ padding: "100px 0px" }}>
                    <div className="row">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 pb-5">
                            <div className='rounded-4' style={{ backgroundColor: "#270E4D", padding: "60px 40px 60px 40px", position: "relative" }}>
                                <img src={seoservicesprecise} alt="pixl designers" style={{ width: "100%" }} />
                                <img src={expertise} alt="pixl developers" className='pay-per-click-expertise' />
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center">
                            <h1 className='ms-5 pay-per-click-text-center'>We're a Pay-Per-Click Advertising agency curating experiences to scale businesses</h1>
                            <p className='ms-5 pay-per-click-text-center pt-4 pb-2'>We craft compelling Pay-Per-Click Advertising campaigns that propel your business to unprecedented levels, reshaping your online landscape with thoughtful and powerful solutions.</p>
                            <div className='ms-5 pay-per-click-text-center'>
                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* fourth container end */}
            {/* fifth container start industries we serve */}
            <div className="container my-5">
                <div className="row">
                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12 d-flex flex-column justify-content-center">
                        <h2>Industries We Serve</h2>
                        <p className="fs-4">
                            Deploying a dynamic strategy, we seamlessly navigate through diverse industries, offering innovative Pay-Per-Click Advertising solutions that surpass limitations and amplify your online presence.
                        </p>

                        <div className="d-flex flex-wrap gap-2 pay-per-click-flex-wrap-icons">
                            <div className="text-center">
                                <img src={healthcare} alt="pixl india" />
                                <p>Healthcare</p>
                            </div>
                            <div className="text-center">
                                <img src={financial} alt="pixl" />
                                <p>Financial</p>
                            </div>
                            <div className="text-center">
                                <img src={hospitality} alt="pixl developers" />
                                <p>Hospitality</p>
                            </div>
                            <div className="text-center">
                                <img src={education} alt="pixl designers" />
                                <p>Education</p>
                            </div>
                            <div className="text-center">
                                <img src={it} alt="pixl web designers" />
                                <p>IT</p>
                            </div>
                            <div className="text-center">
                                <img src={fitness} alt="pixl india" />
                                <p>Fitness</p>
                            </div>
                            <div className="text-center">
                                <img src={marketing} alt="pixl" />
                                <p>Marketing</p>
                            </div>
                            <div className="text-center">
                                <img src={travel} alt="pixl developers" />
                                <p>Travel</p>
                            </div>
                            <div className="text-center">
                                <img src={legal} alt="pixl" />
                                <p>Legal</p>
                            </div>
                            <div className="text-center">
                                <img src={manufacturing} alt="pixl india" />
                                <p>Manufacturing</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12">
                        <img src={seo} alt="pixl designers" className="img-fluid pay-per-click-image" />
                    </div>
                </div>
            </div>
            {/* fifth container end industries we serve */}
            {/* six container start large device*/}
            <div className="container-fluid py-5 pay-per-click-six-container-lg-device" style={{ backgroundColor: "#FAFAFA" }}>
                <div className="container bg-white">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-end">
                            <div>
                                <SeparateForm />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 pay-per-click-form-border-bottom">
                            <div>
                                <FaHeadphones className='fs-1 ms-4' color='#E11E5B' />
                            </div>
                            <h1 className='ms-4 py-4'>
                                Let Pixl be your Pay-Per-Click <br /> Advertising partner
                            </h1>
                            <p className='ms-4'>Our expert team is committed to delivering personalized Pay-Per-Click Advertising strategies that yield results. Whether you seek to enhance brand visibility, drive website traffic, or maximize conversions, we’ve got you covered.</p>
                            <p className='ms-4'>Get in touch, and let the collaboration begin</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* six container end large device*/}
            {/* six container sm and md device start */}
            <div className="container-fluid py-5 pay-per-click-six-container-sm-md-device" style={{ backgroundColor: "#FAFAFA" }}>
                <div className="container bg-white">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-bottom pay-per-click-form">
                            <div>
                                <SeparateForm />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 pay-per-click-form">
                            <div>
                                <FaHeadphones className='fs-1 ms-4 pay-per-click-content' color='#E11E5B' />
                            </div>
                            <h1 className='ms-4 py-4 pay-per-click-content'>
                                Let Pixl be your SEO <br />partner
                            </h1>
                            <p className='ms-4 pay-per-click-content'>Our dedicated team of SEO experts is committed to delivering customized strategies that generate results. Whether your goal is to enhance brand visibility, increase website traffic, or maximize conversions through Search Engine Optimization, we’ve got you covered</p>
                            <p className='ms-4 pay-per-click-content'>Get in touch, and let the collaboration begin</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* six container sm and md device end */}
        </>
    )
}

export default PayPerClickAdvertising