// src/components/OrganizationSchema.js
import React from 'react';
import { Helmet } from 'react-helmet';

const OrganizationSchema = () => {
  const schemaData = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Pixl",
    "legalName": "Pixl",
    "founder": {
      "@type": "Person",
      "name": "Bharath Gupta"
    },
    "foundingDate": "2020",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Global"
    },
    "numberOfEmployees": "56",
    "url": "https://pixl.in",
    "sameAs": [
      "https://www.linkedin.com/company/pixldotin",
      "https://www.instagram.com/pixldotin",
      "https://www.facebook.com/pixldotin",
      "https://twitter.com/pixldotin"
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
};

export default OrganizationSchema;
