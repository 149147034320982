import React, { useState } from "react";
import girlimage from '../Digital Marketing/assets/social-media-marketing-concept-marketing-with-applications_23-2150063134.png';
import './digitalmarketing.css';
import uiuxprecise from '../Ui Ux/assets/22.png'
import expertise from '../Ui Ux/assets/vfdc.png'
import healthcare from "../assets/healthcare-qkmtt8b9gfqtfk9gmd9jkg610fx8ero6a5s9le38l8.png";
import education from "../assets/education_icon-qkmttew4s9ztotzwjy3xjwi9650swnean2cnybthdo.png";
import financial from "../assets/financial-services-qkmttd0gelx91m2muxaoewzbzda2h96tyt1ozrw9q4.png";
import it from "../assets/information-technology-qkmtt4jwp3lo54ex8bn1ah46mwfrjz98xn6boa8ta4.png";
import hospitality from "../assets/hospitality-qkmtt6fl2ro8scc6xcgafgn3to6hzdgplwhamu60xo.png";
import fitness from "../assets/Fitness-qkmttb4s0xuoee5d5whf9xgesljc1uzdajqq17z22k.png";
import marketing from "../assets/marketing-communication-qkmtsxz1d9cnvuohaqsnb0ryh7c723j4kqlxbcikho.png";
import travel from "../assets/travel-qkmtsw3czla38mr7lpze6191aflgmpbnwhaycslcu4.png";
import legal from "../assets/Legal-qkmtt1qe4lht6aj0osf5kztsuqtnwvy1x97v8gczss.png";
import ReactHelmet from 'react-helmet'
import chairsimage from "../assets/pixl-india image.png";
import { FaHeadphones } from "react-icons/fa";
import OrganizationSchema from '../OrganizationSchema';
import SeparateForm from "../Seperate Form/SeparateForm";
import { ToastContainer } from "react-toastify";


const testimonials = [
  {
    title: "Pixl is a trusted ally for anyone seeking top-notch digital marketing services",
    client: "Naina, Client",
    text: "Their team is not only knowledgeable but also genuinely passionate about helping businesses succeed online. The personalized strategies they crafted for our brand resulted in a significant increase in leads and conversions."
  },
  {
    title: "Pixl's expertise and dedication have truly made a positive impact on clients",
    client: "Aadhya, Client",
    text: "We are thrilled with the exceptional digital marketing services provided by Pixl! Their team demonstrated a deep understanding of our brand and industry, creating targeted strategies that significantly boosted our online presence."
  },
  {
    title: "Choosing Pixl for digital marketing was one of the best decisions we made",
    client: "Sandeep, Client",
    text: "Their creative and data-driven approach set them apart. From social media management to SEO optimization, Pixl delivered tangible results. Our online visibility has soared, and we've seen a notable improvement in customer engagement."
  }

];

const DigitalMarketing = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  const getVisibleTestimonials = () => {
    return [
      testimonials[currentIndex],
      testimonials[(currentIndex + 1) % testimonials.length],
      testimonials[(currentIndex + 2) % testimonials.length]
    ];
  };

  return (
    <>
      <ToastContainer />
      <ReactHelmet>
        <OrganizationSchema />
        <title>
          Digital Marketing Services | Pixl Hyderabad India
        </title>
      </ReactHelmet>
      {/* we create sections starts */}
      <section style={{ backgroundColor: "#FFF7F9" }} className="mb-5">
        {/* we create digital div starts for small screens */}
        <div className="container digital-marketing-small-screens">
          <div className="row">
            <div className="col-12 py-3">
              <div className="row"><img src={girlimage} className="rounded-2" alt="pixl" /></div>
            </div>
            <div className="col-12 ">
              <div className="row"><h1 className="fw-bold">We Create</h1></div>
              <div className="row"><h1 className="fw-bold "> <span style={{ color: "#E11E5B" }}>Digital Strategies that</span> Drive Leads</h1></div>
              <div className="row"><p>Unlock the potential of your business with our expertly crafted digital marketing strategies. At PIXL , we go beyond conventional approaches, creating dynamic and results-driven plans that not only boost your online presence but also drive high-quality leads. Harness the power of innovation and precision – let us be the catalyst for your digital success</p></div>
              <div className="row"><p>Backed by strategic thinkers, creative designers, content creators, and innovative technologists, we redefine digital success on a global scale</p></div>
              <div>
                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-2 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* we create digital div ends  for small screns*/}
        {/* we create digital div starts for large screens */}
        <div className="container digital-marketing-large-screens">
          <div className="row">
            <div className="col-xl-7 col-lg-7 my-5">
              <div className="row"><h1 className="fw-bold">We Create</h1></div>
              <div className="row"><h1> <span style={{ color: "#E11E5B" }}>Digital Strategies that</span> Drive Leads</h1></div>
              <div className="row"><p>Unlock the potential of your business with our expertly crafted digital marketing strategies. At PIXL , we go beyond conventional approaches, creating dynamic and results-driven plans that not only boost your online presence but also drive high-quality leads. Harness the power of innovation and precision – let us be the catalyst for your digital success</p></div>
              <div className="row"><p>Backed by strategic thinkers, creative designers, content creators, and innovative technologists, we redefine digital success on a global scale</p></div>
              <div>
                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </button>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 mb-5 my-5">
              <div className="row"><img src={girlimage} className="rounded-2" alt="pixl web designers" /></div>
            </div>
          </div>
        </div>
        {/* we create digital div ends  for large screns*/}
      </section>
      {/* we create sections ends */}
      {/* Our Digital Marketing Services setion strats */}
      <section style={{ backgroundColor: '#FFFFFF' }} className="mb-5">
        <h1 className="text-center my-5 fw-bold">Our Digital Marketing Services</h1>
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    <i aria-hidden="true" className="ti ti-desktop"></i>Search Engine Optimization
                  </h5>
                  <p className="card-text">
                    Our dedicated team employs strategic techniques to enhance your website’s visibility, driving organic traffic and ensuring top rankings and great results.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    Pay-Per-Click Advertising
                  </h5>
                  <p className="card-text">
                    With precise audience targeting and measurable results, Pixl’s PPC expertise transforms your advertising investment into tangible online success.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#F3FFFA" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Social Media Marketing</h5>
                  <p className="card-text">
                    Fuel your brand’s success with Pixl’s compelling strategies to amplify your presence across platforms, engage your audience, and drive meaningful results.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#F3FFFA" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Email Marketing</h5>
                  <p className="card-text">
                    Nurture leads and cultivate customer loyalty through effective email marketing campaigns to potential clients worldwide.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Influencer Partnerships</h5>
                  <p className="card-text">
                    Leverage the influence of industry-specific thought leaders and influencers to promote your products or services.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Content Marketing</h5>
                  <p className="card-text">
                    Quality content is the cornerstone of online success. We craft a content strategy tailored to your audience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Digital Marketing Services setion ends */}
      {/* precise section strats */}
      <section className="container-fluid py-5" style={{ backgroundColor: '#F9F9F9' }}>
        {/* precise div strats */}
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-5 mb-5 my-3 mb-md-0">
              <div className='rounded-4' style={{ backgroundColor: "#270E4D", padding: "60px 40px 60px 40px", position: "relative" }}>
                <img src={uiuxprecise} alt="pixl designers" style={{ width: "100%" }} />
                <img src={expertise} alt="pixl developers" className='ui-ux-expertise' />
              </div>
            </div>
            <div className="col-md-7">
              <div className="ms-4">
                <h1 className="fw-semibold">We're a digital marketing
                  agency curating experiences
                  to scale businesses</h1>
                <p className="my-4 fs-5">We craft immersive digital experiences that propel your business to new heights, redefining your business landscape through strategic and impactful solutions</p>
                <div className='btn text-light px-2 py-3' style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824"  className="text-light fw-bold" style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* precise div ends */}
      </section>
      {/* industries we serve  section strats*/}
      <section>
        {/* Industries We Serve starts */}
        <div className="container mb-5">
          <div className="row my-5">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h1 className="fw-bold">Industries We Serve</h1>
              <p className="fs-5">
                With a dynamic approach, we at Pixl seamlessly navigate diverse
                industries, delivering cutting-edge solutions that transcend
                boundaries and elevate your digital presence
              </p>
              <div className="container">
                <div className="row my-4 text-center row-gap-4">
                  <div className="col-4  col-lg-2 col-sm-3 col-md-3">
                    <img src={healthcare} alt="Healthcare" />
                    <span className='text-secondary d-block mt-2'>Healthcare</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 ">
                    <img src={financial} alt="Financial" />
                    <span className='text-secondary d-block mt-2'>Financial</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={hospitality} alt="Hospitality" />
                    <span className='text-secondary d-block mt-2'>Hospitality</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 ">
                    <img src={education} alt="Education" />
                    <span className='text-secondary d-block mt-2'>Education</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={it} alt="IT" />
                    <span className='text-secondary d-block mt-2'>IT</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={fitness} alt="Fitness" />
                    <span className='text-secondary d-block mt-2'>Fitness</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={marketing} alt="Marketing " />
                    <span className='text-secondary d-block mt-2'>Marketing</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4">
                    <img src={travel} alt="Travel" />
                    <span className='text-secondary d-block mt-2'>Travel</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4">
                    <img src={legal} alt="Legal" />
                    <span className='text-secondary d-block mt-2'>Legal</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src={chairsimage} alt="pixl india" className="img-fluid" />
            </div>
          </div>
        </div>
        {/* Industries We Serve ends */}
      </section>
      {/* Industries We Serve ends */}
      {/* industries we serve  section strats*/}
      {/* Words of praise from our clients starts */}
      <section className="container-fluid py-5" style={{ backgroundColor: '#DDFDF2' }}>
        <div className="container pt-5">
          <div className="row">
            <div className="col">
              <h1 className="fw-bold">Words of praise from our clients</h1>
              <p className="fs-5">Listen to what our digital marketing clients share about their experience working with us</p>
            </div>
          </div>
          <div className="row">
            {getVisibleTestimonials().map((testimonial, index) => (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4" key={index}>
                <div className="card client-praise-cards mb-4 p-3">
                  <div className="card-body">
                    <h5 className="card-title fw-bold">{testimonial.title}</h5>
                    <p className="card-text text-secondary text-start">{testimonial.client}</p>
                    <p className="card-text">{testimonial.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <i className="bi bi-arrow-left-circle-fill fs-1 mx-5" onClick={handlePrevClick}></i>
            <i className="bi bi-arrow-right-circle-fill fs-1 mx-5" onClick={handleNextClick}></i>
          </div>
        </div>
      </section>
      {/* Words of praise from our clients ends */}
      {/* form div starts */}
      <div className="container-fluid py-5 social-media-marketing-six-container-lg-device" style={{ backgroundColor: "#FAFAFA" }}>
        <div className="container bg-white">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-end">
              <div>
                <SeparateForm />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 social-media-marketing-form-border-bottom">
              <div>
                <FaHeadphones className='fs-1 ms-4' color='#E11E5B' />
              </div>
              <h1 className='ms-4 py-4'>
                Let Pixl be your Social <br /> Media Marketing partner
              </h1>
              <p className='ms-4'>Our dedicated expert team is committed to delivering customized strategies that yield results in the realm of Social Media Marketing. Whether your goal is to enhance brand visibility, boost website traffic, or maximize conversions, we’ve got you covered.</p>
              <p className='ms-4'>Get in touch, and let the collaboration begin</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 social-media-marketing-six-container-sm-md-device" style={{ backgroundColor: "#FAFAFA" }}>
        <div className="container bg-white">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-bottom social-media-marketing-form">
              <div>
                <SeparateForm />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 social-media-marketing-form">
              <div>
                <FaHeadphones className='fs-1 ms-4 social-media-marketing-content' color='#E11E5B' />
              </div>
              <h1 className='ms-4 py-4 social-media-marketing-content'>
                Let Pixl be your SEO <br />partner
              </h1>
              <p className='ms-4 social-media-marketing-content'>Our dedicated team of SEO experts is committed to delivering customized strategies that generate results. Whether your goal is to enhance brand visibility, increase website traffic, or maximize conversions through Search Engine Optimization, we’ve got you covered</p>
              <p className='ms-4 social-media-marketing-content'>Get in touch, and let the collaboration begin</p>
            </div>
          </div>
        </div>
      </div>
      {/* form div ends */}
    </>
  )
};

export default DigitalMarketing;
