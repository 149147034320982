import React from 'react'
import ReactHelmet from 'react-helmet'
import searchengine from './assets/serac-engine-optimization.png'
import seoservicesprecise from './assets/22.png'
import expertise from './assets/vfdc.png'
import education from './assets/education_icon.png'
import financial from './assets/financial-services.png'
import fitness from './assets/Fitness.png'
import healthcare from './assets/healthcare.png'
import hospitality from './assets/hospitality.png'
import it from './assets/information-technology.png'
import legal from './assets/Legal.png'
import manufacturing from './assets/manufacturing.png'
import marketing from './assets/marketing-communication.png'
import travel from './assets/travel.png'
import seo from './assets/Search-Engine-Optimization.png'
import { FaHeadphones } from "react-icons/fa6";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ani from "../assets/ani.png";
import daily from "../assets/daily.png";
import hans from "../assets/hans.png";
import jio from "../assets/jio.png";
import latestly from "../assets/latestly.png";
import lokmat from "../assets/lokmat.png";
import na from "../assets/na.png";
import SANGRI from "../assets/SANGRI.png";
import web from "../assets/web.png";
import "@splidejs/react-splide/css";
import '../SEO Services/seoservices.css';
import OrganizationSchema from '../OrganizationSchema';
import SeparateForm from '../Seperate Form/SeparateForm'
import { ToastContainer } from 'react-toastify'

const SEOServices = () => {
    const splideOptions = {
        type: "loop",
        perPage: 6,
        autoplay: true,
        interval: 3000,
        pauseOnHover: false,
        arrows: false,
        pagination: false,
        drag: false,
        gap: "1rem",
        breakpoints: {
            1024: { perPage: 4 },
            768: { perPage: 3 },
            480: { perPage: 2 },
            320: { perPage: 1 },
        },
    };
    return (
        <>
            <ToastContainer />
            <ReactHelmet>
                <OrganizationSchema />
                <title>
                    SEO Services | Seo Services India by Pixl
                </title>
            </ReactHelmet>
            {/* for large screens start */}
            <div className="container-fluid seo-services-firstcontainer-lgdevice" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 d-flex flex-column justify-content-center">
                            <h1>SEO Services</h1>
                            <h1><span style={{ color: "#E11E5B" }}>Elevate Your</span> Digital Presence</h1>
                            <div className='my-4'>
                                <p>At Pixl, we don’t just practice SEO; we define it. With a rich history spanning 16 years, our journey has been marked by innovation, expertise, and a relentless pursuit of excellence in the digital marketing landscape.</p>
                                <p>Fueled by strategic SEO experts, creative web designers, content creators, and innovative technologists, we redefine global digital success through the art and science of Search Engine Optimization</p>
                            </div>
                            <div>

                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>

                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                            <img src={searchengine} alt="pixl" style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* for large screens end */}
            {/* for small and medium screens start */}
            <div className="container-fluid seo-services-firstcontainer-sm-mddevice" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                            <img src={searchengine} alt="pixl web designers" style={{ width: "100%" }} />
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-lg-8 pt-5">
                            <h1>SEO Services</h1>
                            <h1><span style={{ color: "#E11E5B" }}>Elevate Your</span> Digital Presence</h1>
                            <div className='my-4'>
                                <p>At Pixl, we don’t just practice SEO; we define it. With a rich history spanning 16 years, our journey has been marked by innovation, expertise, and a relentless pursuit of excellence in the digital marketing landscape.</p>
                                <p className='fs-5'>Fueled by strategic SEO experts, creative web designers, content creators, and innovative technologists, we redefine global digital success through the art and science of Search Engine Optimization</p>
                            </div>
                            <div>

                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for small and medium screens end */}
            {/* second container start */}
            <div className="container my-5">
                <div className='container'>
                    <h1 className='text-center'>Comprehensive SEO Services Tailored for Your Success</h1>
                </div>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#FFF7F9" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">On-Page Optimization</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Fine-tune your website’s structure, content, and meta tags to enhance its relevance to search engines, resulting in improved rankings.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 seo-services-space-for-cards">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3F7FF" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Off-Page Optimization and Link Building</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Build a robust online presence through strategic link-building activities, establishing your website as an authoritative source in your niche.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3FFFA" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Technical SEO Audits</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Identify and rectify technical issues that may hinder search engine performance, ensuring a seamless and optimized website experience for users.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3F7FF", padding: "22.5px" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Content Creation and Optimization</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Craft compelling, SEO-friendly content that resonates with your audience, delivering value while aligning with search engine algorithms for optimization.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 seo-services-space-for-cards">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#F3FFFA" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Local SEO Strategies</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Strengthen your local presence with targeted optimization, ensuring your business stands out in location-based searches and improve your local visibility.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div
                                className="card border-0 p-2"
                                style={{ backgroundColor: "#FFF7F9" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="fw-bold">Mobile SEO Optimization</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-3">
                                        Optimize your website for mobile users, delivering a seamless experience that caters to the growing trend of mobile search to ensure performance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* second container end */}
            {/* third container start */}
            {/* Featured On Section  starts*/}
            <section className="featured-section">
                <h1 className='text-center pb-3'>As Featured On</h1>
                <div className="container text-center text-dark">
                    <Splide options={splideOptions}>
                        <SplideSlide>
                            <img src={ani} alt="ANI" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={daily} alt="Daily" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={hans} alt="Hans" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={jio} alt="Jio" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={latestly} alt="Latestly" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={lokmat} alt="Lokmat" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={na} alt="NA" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={web} alt="Web" className="featured-logo" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={SANGRI} alt="Sangri" className="featured-logo" />
                        </SplideSlide>
                    </Splide>
                </div>
            </section>
            {/* Featured On Section ends*/}
            {/* third container end */}
            {/* fourth container start */}
            <div className="container-fluid" style={{ backgroundColor: "#F9F9F9" }}>
                <div className="container" style={{ padding: "100px 0px" }}>
                    <div className="row">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 pb-5">
                            <div className='rounded-4' style={{ backgroundColor: "#270E4D", padding: "60px 40px 60px 40px", position: "relative" }}>
                                <img src={seoservicesprecise} alt="pixl designers" style={{ width: "100%" }} />
                                <img src={expertise} alt="pixl developers" className='ui-ux-expertise' />
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center">
                            <h1 className='ms-5 seo-services-text-center'>SEO Powered Growth Elevate Your Business with Our Expertise</h1>
                            <p className='ms-5 seo-services-text-center pt-4 pb-2 fs-5'>Our comprehensive SEO services are designed to enhance online visibility, drive organic traffic, and optimize conversion rates, ensuring your brand achieves unparalleled success in the digital landscape.</p>
                            <div className='ms-5 seo-services-text-center'>
                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* fourth container end */}
            {/* fifth container start industries we serve */}
            <div className="container my-5">
                <div className="row">
                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12">
                        <h2>Industries We Serve</h2>
                        <p className="fs-4">
                            With a dynamic SEO approach, we adeptly navigate diverse industries, providing cutting-edge optimization solutions that transcend boundaries and elevate your digital presence
                        </p>

                        <div className="d-flex flex-wrap gap-2 seo-services-flex-wrap-icons">
                            <div className="text-center">
                                <img src={healthcare} alt="pixl india" />
                                <p>Healthcare</p>
                            </div>
                            <div className="text-center">
                                <img src={financial} alt="pixl developers" />
                                <p>Financial</p>
                            </div>
                            <div className="text-center">
                                <img src={hospitality} alt="pixl designers" />
                                <p>Hospitality</p>
                            </div>
                            <div className="text-center">
                                <img src={education} alt="pixl" />
                                <p>Education</p>
                            </div>
                            <div className="text-center">
                                <img src={it} alt="pixl web designers" />
                                <p>IT</p>
                            </div>
                            <div className="text-center">
                                <img src={fitness} alt="pixl" />
                                <p>Fitness</p>
                            </div>
                            <div className="text-center">
                                <img src={marketing} alt="pixl developers" />
                                <p>Marketing</p>
                            </div>
                            <div className="text-center">
                                <img src={travel} alt="pixl designers" />
                                <p>Travel</p>
                            </div>
                            <div className="text-center">
                                <img src={legal} alt="pixl india" />
                                <p>Legal</p>
                            </div>
                            <div className="text-center">
                                <img src={manufacturing} alt="pixl web designers" />
                                <p>Manufacturing</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12 seo-serv-image">
                        <img src={seo} alt="pixl" className="img-fluid" />
                    </div>
                </div>
            </div>
            {/* fifth container end industries we serve */}
            {/* six container start large device*/}
            <div className="container-fluid py-5 seo-services-six-container-lg-device" style={{ backgroundColor: "#FAFAFA" }}>
                <div className="container bg-white">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-end">
                            <div>
                                <SeparateForm />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 px-3 pt-5 seo-services-form-border-bottom">
                            <div>
                                <FaHeadphones className='fs-1 ms-5' color='#E11E5B' />
                            </div>
                            <h1 className='ms-5 py-4'>
                                Let Pixl be your SEO<br /> partner
                            </h1>
                            <p className='ms-5'>Our dedicated team of SEO experts is committed to delivering customized strategies that generate results. Whether your goal is to enhance brand visibility, increase website traffic, or maximize conversions through Search Engine Optimization, we’ve got you covered</p>
                            <p className='ms-5 text-start'>Get in touch, and let the collaboration begin</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* six container end large device*/}
            {/* six container sm and md device start */}
            <div className="container-fluid py-5 seo-services-six-container-sm-md-device" style={{ backgroundColor: "#FAFAFA" }}>
                <div className="container bg-white">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-bottom seo-services-form">
                            <div>
                                <SeparateForm />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 seo-services-form">
                            <div>
                                <FaHeadphones className='fs-1 ms-5 seo-services-content' color='#E11E5B' />
                            </div>
                            <h1 className='ms-5 py-4 seo-services-content'>
                                Let Pixl be your SEO <br />partner
                            </h1>
                            <p className='py-3 seo-services-content fs-5'>Our dedicated team of SEO experts is committed to delivering customized strategies that generate results. Whether your goal is to enhance brand visibility, increase website traffic, or maximize conversions through Search Engine Optimization, we’ve got you covered</p>
                            <p className='ms-5 seo-services-content fw-bold text-secondary'>Get in touch, and let the collaboration begin</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* six container sm and md device end */}
        </>
    )
}

export default SEOServices