import React from 'react';
import aboutusimage from '../About us/assets/Untitled-design-4.png';
import weimage from '../About us/assets/we.jpg';
import ReactHelmet from 'react-helmet'
import { Link } from 'react-router-dom';
import OrganizationSchema from '../OrganizationSchema';
const Aboutus = () => {
  const Handleclick=()=>{
    window.scrollTo({
     top:0,
     behavior:'smooth'
    })
  }

  return (
    <>
      <ReactHelmet>
      <OrganizationSchema />
        <title>
        About Us - A  Global Web Design and Digital Tech Agencey 
        </title>
      </ReactHelmet>
      {/* firstimage div starts */}
      <div className='mb-4'>
        <img src={aboutusimage} alt="pixl" style={{ width: "100%" }} className='image-fluid' />
      </div>
      {/* firstimage div ends */}
      {/* title div startsa */}
      <div>
        <h1 className='text-center fw-bold'>Pixlify Internet Solutions Private Limited</h1>
        <h4 className='text-center mb-5'>Delivering Innovations With Precision</h4>
      </div>
      {/* title div ends*/}
{/* we image div starts */}
      <div className='container'>
        <div className='row'>
          <div className='col-xl-5 mb-5'><img src={weimage} style={{ width: '100%' }} alt="pixl india" /></div>
          <div className='col-xl-7 fs-5 d-flex flex-column justify-content-center'>
            <div className='row'><p>Welcome to Pixl, where creativity meets innovation! We are a dynamic team of experts passionate about transforming digital landscapes through our comprehensive suite of services in web design and development, UI/UX, mobile app development, and digital marketing</p></div>
            <div className='row'><p>At Pixl, we believe in the power of pixels to tell stories and create impactful digital experiences. Established with a vision to redefine online presence, our journey began with a commitment to delivering excellence in every pixel we design and develop. Over the years, we’ve evolved into a full-service digital agency, proudly serving clients across diverse industries</p></div>
          </div> 
        </div>
      </div>
      {/* we image div ends */}
    </>
  )
}

export default Aboutus