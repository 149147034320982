import React from 'react';
import girlimages from '../Influncerpartnership/assets/64f9621140f35b04cd3f6e95_af-showcase-2-qiwx6uz8z18lzzx0zi22m0vh8iolyjufoc4q14m25s.png';
import healthcare from "../assets/healthcare-qkmtt8b9gfqtfk9gmd9jkg610fx8ero6a5s9le38l8.png";
import education from "../assets/education_icon-qkmttew4s9ztotzwjy3xjwi9650swnean2cnybthdo.png";
import financial from "../assets/financial-services-qkmttd0gelx91m2muxaoewzbzda2h96tyt1ozrw9q4.png";
import it from "../assets/information-technology-qkmtt4jwp3lo54ex8bn1ah46mwfrjz98xn6boa8ta4.png";
import hospitality from "../assets/hospitality-qkmtt6fl2ro8scc6xcgafgn3to6hzdgplwhamu60xo.png";
import fitness from "../assets/Fitness-qkmttb4s0xuoee5d5whf9xgesljc1uzdajqq17z22k.png";
import marketing from "../assets/marketing-communication-qkmtsxz1d9cnvuohaqsnb0ryh7c723j4kqlxbcikho.png";
import travel from "../assets/travel-qkmtsw3czla38mr7lpze6191aflgmpbnwhaycslcu4.png";
import legal from "../assets/Legal-qkmtt1qe4lht6aj0osf5kztsuqtnwvy1x97v8gczss.png";
import ani from "../assets/ani.png";
import daily from "../assets/daily.png";
import hans from "../assets/hans.png";
import jio from "../assets/jio.png";
import latestly from "../assets/latestly.png";
import lokmat from "../assets/lokmat.png";
import na from "../assets/na.png";
import web from "../assets/web.png";
import SANGRI from "../assets/SANGRI.png";
import uiuxprecise from '../Ui Ux/assets/22.png'
import expertise from '../Ui Ux/assets/vfdc.png'
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import greenimage from '../Influncerpartnership/assets/graphic-3-1024x1024.png';
import ReactHelmet from 'react-helmet'
import { FaHeadphones } from 'react-icons/fa';
import OrganizationSchema from '../OrganizationSchema';
import SeparateForm from '../Seperate Form/SeparateForm';
import { ToastContainer } from 'react-toastify';


const Influncerpartnership = () => {
  const splideOptions = {
    type: "loop",
    perPage: 6,
    autoplay: true,
    interval: 3000,
    pauseOnHover: false,
    arrows: false,
    pagination: false,
    drag: false,
    gap: "1rem",
    breakpoints: {
      1024: { perPage: 4 },
      768: { perPage: 3 },
      480: { perPage: 2 },
      320: { perPage: 1 },
    },
  };
  return (
    <>
      <ToastContainer />
      <ReactHelmet>
        <OrganizationSchema />
        <title>
          Influencer Partnerships | Amplify Your Brand With Pixl
        </title>
      </ReactHelmet>
      {/* we create sections starts */}
      <section style={{ backgroundColor: "#FFF7F9" }} className="mb-5">
        {/* we create digital div starts for small screens */}
        <div className="container digital-marketing-small-screens">
          <div className="row">
            <div className="col-12 my-5">
              <div className="row"><img src={girlimages} className="rounded-5" alt="pixl" /></div>
            </div>
            <div className="col-12 my-2">
              <div className="row"><h1 className='fw-bold'>Influencer Partnerships</h1></div>
              <div className="row"><h1 className='fw-bold'> <span style={{ color: "#E11E5B" }}>Elevate Your Brand with </span> Authentic Influence</h1></div>
              <div className="row"></div>
              <div className="row"><p>In an era where consumers crave authenticity, influencer partnerships emerge as a dynamic strategy to connect with your audience on a personal level. Explore the benefits and possibilities as we delve into the world of impactful collaborations..</p></div>
              <div className="row"><p className='fs-4'>Fueled by strategic email marketing experts, creative designers, content creators, and innovative technologists, we redefine global digital success through impactful and tailored email marketing strategies</p></div>
              <div>
                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* we create digital div ends  for small screns*/}
        {/* we create digital div starts for large screens */}
        <div className="container digital-marketing-large-screens">
          <div className="row">
            <div className="col-xl-7 col-lg-7 my-5 d-flex flex-column justify-content-center">
              <div className="row"><h1 className='fw-bold'>Influencer Partnerships</h1></div>
              <div className="row"><h1 className='fw-bold'> <span style={{ color: "#E11E5B" }}>Elevate Your Brand with </span>Authentic</h1></div>
              <div className="row"><h1 className='fw-bold'>Influence</h1></div>
              <div className="row"><p>In an era where consumers crave authenticity, influencer partnerships emerge as a dynamic strategy to connect with your audience on a personal level. Explore the benefits and possibilities as we delve into the world of impactful collaborations..</p></div>
              <div className="row"><p>Fueled by strategic email marketing experts, creative designers, content creators, and innovative technologists, we redefine global digital success through impactful and tailored email marketing strategies</p></div>
              <div>
                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                  <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                    +91 73823 73824
                  </a>
                </button>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 mb-5 my-5">
              <div className="row"><img src={girlimages} className="rounded-5" alt="pixl india" /></div>
            </div>
          </div>
        </div>
        {/* we create digital div ends  for large screns*/}
      </section>
      {/* we create sections ends */}
      {/* Our Digital Marketing Services setion strats */}
      <section style={{ backgroundColor: '#FFFFFF' }} className="mb-5">
        <div className="container">
          <h1 className="text-center my-5 fw-bold">Crafting Authentic Connections and Amplifying Influence</h1>
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    <i aria-hidden="true" className="ti ti-desktop"></i> Influencer Identification and Outreach
                  </h5>
                  <p className="card-text">
                    Strategically identify influencers whose values align with your brand and initiate meaningful collaborations through targeted outreach of their content.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    Campaign Strategy and Planning
                  </h5>
                  <p className="card-text">
                    Develop comprehensive influencer marketing strategies, outlining campaign objectives, key messages that ties together and re-engaging top-performing influencers for future campaigns.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#F3FFFA" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Authentic Content Creation</h5>
                  <p className="card-text">
                    Work with influencers to craft authentic and engaging content that resonates with their audience while aligning seamlessly with your brand’s narrative to assess the effectiveness of the partnership and content.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#F3FFFA" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Performance Analytics and Reporting</h5>
                  <p className="card-text">
                    Implement robust tracking mechanisms to measure the success of influencer campaigns, providing insights into engagement, reach, and overall impact.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Strategic Platform Selection</h5>
                  <p className="card-text">
                    Choose the most effective social media platforms and online channels based on influencer and audience preferences for enhanced local campaign that receives on each platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-2 border-0" style={{ backgroundColor: "#FFF7F9" }}>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Negotiation and Contract Management</h5>
                  <p className="card-text">
                    Handle influencer negotiations and contract agreements, ensuring a fair and mutually beneficial partnership that aligns with campaign goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Digital Marketing Services setion ends */}
      {/* Featured On Section  starts*/}
      <section className="featured-section">
        <div className="container text-center text-dark">
          <h2 className="my-3 fw-bold">As Featured On</h2>
          <Splide options={splideOptions}>
            <SplideSlide>
              <img src={ani} alt="ANI" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={daily} alt="Daily" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={hans} alt="Hans" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={jio} alt="Jio" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={latestly} alt="Latestly" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={lokmat} alt="Lokmat" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={na} alt="NA" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={web} alt="Web" className="featured-logo" />
            </SplideSlide>
            <SplideSlide>
              <img src={SANGRI} alt="Sangri" className="featured-logo" />
            </SplideSlide>
          </Splide>
        </div>
      </section>
      {/* Featured On Section ends*/}
      {/* precise section strats */}
      <section className='py-5 container-fluid' style={{ backgroundColor: '#F9F9F9' }}>
        {/* precise div strats */}
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-5 mb-5 my-3 mb-md-0">
              <div className='rounded-4' style={{ backgroundColor: "#270E4D", padding: "60px 40px 60px 40px", position: "relative" }}>
                <img src={uiuxprecise} alt="pixl developers" style={{ width: "100%" }} />
                <img src={expertise} alt="pixl web designers" className='ui-ux-expertise' />
              </div>
            </div>
            <div className="col-md-7">
              <div className="ms-4">
                <h1 className='fw-semibold'>We're an Influencer Partnerships
                  agency curating authentic
                  experiences to scale businesses..</h1>
                <p className="my-4 fs-5">We craft influential digital engagements that propel your business to unprecedented heights, reshaping your business landscape with thoughtful and impactful Influencer Partnerships..</p>
                <div>
                  <a href="tel:+91 73823 73824"  className="text-light fw-bold btn text-light px-2 py-3" style={{ textDecoration: 'none', color: 'inherit', backgroundColor: '#E11E5B' }}>
                    +91 73823 73824
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* precise div ends */}
      </section>
      {/* industries we serve  section strats*/}
      <section>
        {/* Industries We Serve starts */}
        <div className="container mb-5">
          <div className="row my-5">
            <div className="col d-flex flex-column justify-content-center">
              <h1 className="fw-bold">Industries We Serve</h1>
              <p className="fs-5">
                With a dynamic approach, we at Pixl seamlessly navigate diverse
                industries, delivering cutting-edge solutions that transcend
                boundaries and elevate your digital presence
              </p>
              <div className="container">
                <div className="row my-4 text-center row-gap-4">
                  <div className="col-4  col-lg-2 col-sm-3 col-md-3">
                    <img src={healthcare} alt="Healthcare" />
                    <span className='text-secondary d-block mt-2'>Healthcare</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 ">
                    <img src={financial} alt="Financial" />
                    <span className='text-secondary d-block mt-2'>Financial</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={hospitality} alt="Hospitality" />
                    <span className='text-secondary d-block mt-2'>Hospitality</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 ">
                    <img src={education} alt="Education" />
                    <span className='text-secondary d-block mt-2'>Education</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={it} alt="IT" />
                    <span className='text-secondary d-block mt-2'>IT</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={fitness} alt="Fitness" />
                    <span className='text-secondary d-block mt-2'>Fitness</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                    <img src={marketing} alt="Marketing " />
                    <span className='text-secondary d-block mt-2'>Marketing</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4">
                    <img src={travel} alt="Travel" />
                    <span className='text-secondary d-block mt-2'>Travel</span>
                  </div>
                  <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4">
                    <img src={legal} alt="Legal" />
                    <span className='text-secondary d-block mt-2'>Legal</span>
                  </div>
              
                </div>
             
              </div>
            </div>
            <div className="col-md-6">
              <img src={greenimage} alt="pixl designers" className="img-fluid" />
            </div>
          </div>
        </div>
        {/* Industries We Serve ends */}
      </section>
      {/* Industries We Serve ends */}
      {/* industries we serve  section strats*/}
      {/* form div starts */}
      <div className="container-fluid py-5 social-media-marketing-six-container-lg-device" style={{ backgroundColor: "#FAFAFA" }}>
        <div className="container bg-white">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-end">
              <div>
                <SeparateForm />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 social-media-marketing-form-border-bottom">
              <div>
                <FaHeadphones className='fs-1 ms-4' color='#E11E5B' />
              </div>
              <h1 className='ms-4 py-4'>
                Let Pixl be your Social <br /> Media Marketing partner
              </h1>
              <p className='ms-4'>Our dedicated expert team is committed to delivering customized strategies that yield results in the realm of Social Media Marketing. Whether your goal is to enhance brand visibility, boost website traffic, or maximize conversions, we’ve got you covered.</p>
              <p className='ms-4'>Get in touch, and let the collaboration begin</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 social-media-marketing-six-container-sm-md-device" style={{ backgroundColor: "#FAFAFA" }}>
        <div className="container bg-white">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-bottom social-media-marketing-form">
              <div>
                <SeparateForm />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 social-media-marketing-form">
              <div>
                <FaHeadphones className='fs-1 ms-4 social-media-marketing-content' color='#E11E5B' />
              </div>
              <h1 className='ms-4 py-4 social-media-marketing-content'>
                Let Pixl be your SEO <br />partner
              </h1>
              <p className='ms-4 social-media-marketing-content'>Our dedicated team of SEO experts is committed to delivering customized strategies that generate results. Whether your goal is to enhance brand visibility, increase website traffic, or maximize conversions through Search Engine Optimization, we’ve got you covered</p>
              <p className='ms-4 social-media-marketing-content'>Get in touch, and let the collaboration begin</p>
            </div>
          </div>
        </div>
      </div>
      {/* form div ends */}
    </>
  )
}

export default Influncerpartnership