import React from 'react';
import firstimage from '../Insights/assets/backlink-hyperlink-networking-internet-online-technology-concept-650x434.jpg';
import secondimage from '../Insights/assets/person-using-laptop-using-artificial-intelligence-generate-images-650x366.jpg';
import thirdimage from '../Insights/assets/magnifying-glass-with-seo-concepts-650x433.jpg';
import fourthimage from '../Insights/assets/fourtimage.jpg';
import fifthimage from '../Insights/assets/fifthimage.jpg';
import sixthimage from '../Insights/assets/sixthimage.jpg';
import seventhimage from '../Insights/assets/seventhimage.jpg';
import eightimage from '../Insights/assets/eightimage.jpg';
import ninthimage from '../Insights/assets/ninthimage.jpg';
import tenthimage from '../Insights/assets/tenthimage.jpg';
import elevenimage from '../Insights/assets/elevenethimage.jpg';
import twelthimage from '../Insights/assets/twelvelthimage.jpg';
import thirteenimage from '../Insights/assets/thirthenimage.jpg';
import fourteenimage from '../Insights/assets/fourtenimage.jpg';
import fifteenimage from '../Insights/assets/fiftenimage.jpg';
import sixteenimage from '../Insights/assets/sisteenimage.jpg';
import seventeenimage from '../Insights/assets/seventeenimage.jpg';
import eighteenimage from '../Insights/assets/eighteenimage.jpg';
import ninteenimage from '../Insights/assets/ninteenimage.jpg';
import twentyimage from '../Insights/assets/twentyimage.jpg';
import twentyoneimage from '../Insights/assets/twentyoneimage.jpg';
import twentytwoimage from '../Insights/assets/twentytwoimage.jpg';
import twentythreeimage from '../Insights/assets/twentythreeimage.jpg';
import twentyfourimage from '../Insights/assets/twentyfourimage.jpg';
import twentyfiveimage from '../Insights/assets/twentyfiveimage.jpg';
import twentysiximage from '../Insights/assets/twentysiximage.jpg';
import ReactHelmet from 'react-helmet'


const Insights = () => { 
  return (
    <>
<ReactHelmet>
        <title>
        Insights | Web Design &amp; Digital Marketing Articles &amp; Insights by Pixl
        </title>
      </ReactHelmet>
      {/* first row starts */}
        <div className='container'>
            <div className='row mb-5'>
<div className='row'><h1 className='text-center fw-bold my-5'>Insights from our experts & news from the industry</h1></div>
                <div className='col-md-4 image-container'><img  style={{width:'100%'}}src={firstimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Here are 7 strategies to help you create high-quality backlinks for your website</p> 
                </div>
                <div className='col-md-4 image-container'><img  style={{width:'100%'}}src={fourthimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Maximizing Results with AI Campaigns for Online Marketing</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={thirdimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>The Ideal Content Length for SEO: Finding the Perfect Balance</p>
                </div>
            </div>
        </div>
         {/* first row ends*/}
              {/* seond row starts */}
        <div className='container'>
            <div className='row mb-5'>
                <div className='col-md-4 image-container'><img  style={{width:'100%'}}src={secondimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>The Rise of AI and Machine Learning in SEO: Strategies for Staying Ahead in 2024</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%', height:"275px"}} src={fifthimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>How the Top Digital Marketing Agency Can Generate More Leads for Your Business</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={sixthimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Elevate Your Hospitality Business with the Best Digital Marketing Strategies</p>
                </div>
            </div>
        </div>
         {/* second row ends*/}
              {/* third row starts */}
        <div className='container'>
            <div className='row mb-5'>
                <div className='col-md-4 image-container'><img  style={{width:'100%'}}src={seventhimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Here are 7 strategies to help you create high-quality backlinks for your website</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={eightimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Maximizing Results with AI Campaigns for Online Marketing</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={ninthimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>The Ideal Content Length for SEO: Finding the Perfect Balance</p>
                </div>
            </div>
        </div>
         {/* third row ends*/}
              {/* fourth row starts */}
        <div className='container'>
            <div className='row mb-5'>
                <div className='col-md-4 image-container'><img  style={{width:'100%'}}src={tenthimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Here are 7 strategies to help you create high-quality backlinks for your website</p> 
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={elevenimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Maximizing Results with AI Campaigns for Online Marketing</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={twelthimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>The Ideal Content Length for SEO: Finding the Perfect Balance</p>
                </div>
            </div>
        </div>
         {/* ffourthrow ends*/}
              {/* fifth row starts */}
        <div className='container'>
            <div className='row mb-5'>
                <div className='col-md-4  image-container'><img  style={{width:'100%'}}src={thirteenimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Here are 7 strategies to help you create high-quality backlinks for your website</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={fourteenimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Maximizing Results with AI Campaigns for Online Marketing</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={fifteenimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>The Ideal Content Length for SEO: Finding the Perfect Balance</p>
                </div>
            </div>
        </div>
         {/* fifth row ends*/}
     {/* sixth row starts */}
     <div className='container'>
            <div className='row mb-5'>
                <div className='col-md-4 image-container'><img  style={{width:'100%'}}src={sixteenimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Here are 7 strategies to help you create high-quality backlinks for your website</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={seventeenimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Maximizing Results with AI Campaigns for Online Marketing</p>
                </div> 
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={eighteenimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>The Ideal Content Length for SEO: Finding the Perfect Balance</p>
                </div>
            </div>
        </div>
         {/* sixth row ends*/}
              {/* seventh row starts */}
        <div className='container'>
            <div className='row mb-5'>
                <div className='col-md-4 image-container'><img  style={{width:'100%'}}src={ninteenimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Here are 7 strategies to help you create high-quality backlinks for your website</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%', height:"275px"}} src={twentyimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Maximizing Results with AI Campaigns for Online Marketing</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={twentyoneimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>The Ideal Content Length for SEO: Finding the Perfect Balance</p>
                </div>
            </div>
        </div>
         {/* seventh row ends*/}
              {/* eight row starts */}
        <div className='container'>
            <div className='row mb-5'>
                <div className='col-md-4 image-container'><img  style={{width:'100%'}}src={twentytwoimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Here are 7 strategies to help you create high-quality backlinks for your website</p> 
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={twentythreeimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Maximizing Results with AI Campaigns for Online Marketing</p>
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={twentyfourimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>The Ideal Content Length for SEO: Finding the Perfect Balance</p>
                </div>
            </div>
        </div>
         {/* eight row ends*/}
            {/*ninth  row starts */}
         <div className='container'>
            <div className='row mb-5'>
                <div className='col-md-4 image-container'><img  style={{width:'100%'}}src={twentyfiveimage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Here are 7 strategies to help you create high-quality backlinks for your website</p>         
                </div>
                <div className='col-md-4 image-container'><img style={{width:'100%'}} src={twentysiximage} className='image-fluid rounded-3' alt="pixl pixl-web-designers pixl-web-developers pixl-india pixl-hyderabad" />
                <p className='fs-5'>Maximizing Results with AI Campaigns for Online Marketing</p>
                </div>  
            </div>
        </div>
         {/* ninth row ends*/}
        </>
  )
}

export default Insights