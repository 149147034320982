import React from 'react';
import groupimage from '../Life@pixl/assets/1.jpg';
import teamimage from '../Life@pixl/assets/teamimage.png';
import oldteamimage from '../Life@pixl/assets/oldteamimage.jpg';
import ReactHelmet from 'react-helmet'
import OrganizationSchema from '../OrganizationSchema';


const Lifepixl = () => {
  return (
    <>
      <ReactHelmet>
        <OrganizationSchema />
        <title>
          Life @PIXL Join Pixl for a Better Tomo
        </title>
      </ReactHelmet>
      <div className="container-fluid mt-5">

        <img src={groupimage} alt="pixl" style={{ width: "100%" }} className='image-fluid' />
      </div>
      {/* first image div starts  */}
      <div className='container my-5'>
        <div className='row'>
          <div className='col-md-6 d-flex flex-column justify-content-center'>
            <div className='col'><p className="fs-4">At Pixl, we don’t just create exceptional digital experiences; we cultivate an environment where every team member can thrive, grow, and find inspiration in their work.</p></div>
            <div className='col'><p className="fs-4">Pixl is more than a workplace; it’s a community of passionate individuals driven by a shared commitment to excellence. Our culture is rooted in collaboration, creativity, and continuous learning. We believe that a positive and inclusive atmosphere fosters the creativity needed to push boundaries and deliver outstanding results.</p></div>
          </div>
          <div className='col-md-6'><img className='rounded-3 img-fluid' src={teamimage} alt="pixl web designers" /></div>
        </div>
      </div>
      {/* first image div ends  */}
      {/* secnd image div starts  */}
      <section style={{ backgroundColor: "#F2F2F2" }}>
        <div className='container mb-5 '>
          <div className='row'>
            <div className='col-md-6 my-5 d-flex align-items-center'>
              <img className='rounded-3 img-fluid' src={oldteamimage} style={{ width: "100%" }} alt="pixl designers" />
            </div>
            <div className='col-md-6 mb-5 my-5'>
              <div className='col'>
                <h3 className='fw-bold'>Innovation Hub</h3>
                <p className="fs-4">Step into our workspace, and you’ll feel the energy of innovation. From brainstorming sessions to collaborative workshops, our office is a hub where ideas come to life. We provide the tools and environment for every team member to contribute to our collective success.</p>
              </div>
              <h3 className='fw-bold'>Learning & Development</h3>
              <div className='col'><p className="fs-4">We value personal and professional growth. Our commitment to learning extends beyond projects – we invest in our team’s development through training programs, workshops, and mentorship opportunities. At Pixl, you’re not just an employee; you’re a lifelong learner</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* secnd image div ends  */}
      {/* celebrating success div starts */}
      <div className='container'>
        <h3 className='fw-bold'>Celebrating Success</h3>
        <p>Success is a journey, and we celebrate every milestone along the way. Whether it’s a project launch, a team achievement, or a personal accomplishment, we take pride in recognizing and applauding the efforts that make Pixl a great place to work.</p>
      </div>
      {/* celebrating success div ends */}
      {/* worklife integration div starts */}
      <div className='container'>
        <h3 className='fw-bold'>Work-Life Integration</h3>
        <p>We understand that life outside of work is just as important. That’s why we offer flexible work arrangements that empower our team to balance professional and personal commitments effectively. Healthy and happy employees are productive employees. We encourage a holistic approach to well-being, providing wellness initiatives and resources to support physical and mental health.</p>
      </div>
      {/* worklife integration div ends */}
      {/* join us div starts */}
      <div className='container mb-5'>
        <h3 className='fw-bold'>Join Us</h3>
        <p>Life at Pixl is about more than just work; it’s about being part of a dynamic and supportive community that values creativity, collaboration, and individual growth. If you’re ready to contribute your talents to a workplace where innovation and culture go hand in hand, explore our career opportunities.</p>
        <span>Discover Life at Pixl – where every day is an opportunity to create, innovate, and be part of something extraordinary.</span>
      </div>
      {/* join us success div ends */}
    </>
  )
}

export default Lifepixl