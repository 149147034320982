import React from 'react'
import ReactHelmet from 'react-helmet'
import uiuxdes from './assets/ui-ux-designers.webp'
import { SlLike } from "react-icons/sl";
import { FaDumbbell } from "react-icons/fa";
import { TfiTimer } from "react-icons/tfi";
import mobileappdev from './assets/mobile-app-development-service.png'
import { TiDeviceDesktop } from "react-icons/ti";
import { FaHeadphones } from "react-icons/fa6";
import uiuxprecise from './assets/22.png'
import expertise from './assets/vfdc.png';
import OrganizationSchema from '../OrganizationSchema';
import '../Ui Ux/uiux.css'
import SeparateForm from '../Seperate Form/SeparateForm';
import { ToastContainer } from 'react-toastify';

const UiUx = () => {
    return (
        <>
            <ToastContainer />
            <ReactHelmet>
                <OrganizationSchema />
                <title>
                    UI /UX Development Services Pixl Designers Hyderabad India
                </title>
            </ReactHelmet>
            {/* for large screens start */}
            <div className="container-fluid ui-ux-firstcontainer-lgdevice" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-lg-7">
                            <h1>UI/UX Design And Development Services</h1>
                            <div className='my-4'>
                                <p>Unlock the potential of your digital ventures with our commitment to crafting exceptionally stunning and highly intuitive visual interfaces for your mobile app. Our team, consisting of expert UI/UX designers, doesn’t just focus on aesthetics but goes the extra mile to guarantee engaging user interfaces that leave a lasting impression. Whether you’re aiming for increased user satisfaction, enhanced brand perception, or elevated performance, our tailored approach is geared to meet your unique project needs.</p>
                                <p>Ready to redefine your digital experience? Connect with us today and let’s embark on a journey to take your digital products to new heights</p>
                            </div>
                            <div>

                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>

                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                            <img src={uiuxdes} alt="pixl developers" />
                        </div>
                    </div>
                </div>
            </div>
            {/* for large screens end */}
            {/* for small and medium screens start */}
            <div className="container-fluid ui-ux-firstcontainer-sm-mddevice" style={{ backgroundColor: "#F3F7FF" }}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12">
                            <img src={uiuxdes} alt="pixl india"className='img-fluid' />
                        </div>
                        <div className="col-12">
                            <h1>UI/UX Design And Development Services</h1>
                            <div className='my-4'>
                                <p>Unlock the potential of your digital ventures with our commitment to crafting exceptionally stunning and highly intuitive visual interfaces for your mobile app. Our team, consisting of expert UI/UX designers, doesn’t just focus on aesthetics but goes the extra mile to guarantee engaging user interfaces that leave a lasting impression. Whether you’re aiming for increased user satisfaction, enhanced brand perception, or elevated performance, our tailored approach is geared to meet your unique project needs.</p>
                                <p>Ready to redefine your digital experience? Connect with us today and let’s embark on a journey to take your digital products to new heights</p>
                            </div>
                            <div>

                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for small and medium screens end */}
            {/* second container start */}
            <div className="container my-5">
                <div>
                    <h1>Why Choose Pixl as Your UI/UX Development Company?</h1>
                    <p className='my-4'>At Pixl – UI/UX Design and Development, we understand the profound impact a superior product can have on a business. Our commitment is to craft high-quality, user-centric solutions designed for scalability.</p>
                    <p>Our proven methodology has earned the trust of many industry leaders such as HSBC , Broadridge. Let us recreate the same magic for your business.</p>
                </div>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 mt-2">
                            <div
                                className="card border-0 p-3"
                                style={{ backgroundColor: "#FFF5F0" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-3 col-lg-3 col-md-2 col-sm-2 col-3 card-title">
                                            <span>
                                                <SlLike className="bi bi-clock p-2 text-light rounded-2" fontSize={50} style={{ backgroundColor: "#30B47C" }} />
                                            </span>
                                        </div>
                                        <div className="col-xxl-9 col-lg-9 col-md-10 col-sm-10 col-9 d-flex align-items-center">
                                            <h6 className='fw-bold'>User First Mindset</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-2 text-secondary text-start">
                                        Prioritizing usability and experience is ingrained in our design philosophy. This commitment ensures that each product we create not only meets but exceeds business goals and key performance indicators  .
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 mt-2">
                            <div
                                className="card border-0 p-3"
                                style={{ backgroundColor: "#F3F7FF" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-3 col-lg-3 col-md-2 col-sm-2 col-3 card-title">
                                            <span>
                                                <FaDumbbell className="bi bi-clock p-2 text-light rounded-2" fontSize={50} style={{ backgroundColor: "#E11E5B" }} />
                                            </span>
                                        </div>
                                        <div className="col-xxl-9 col-lg-9 col-md-10 col-sm-10 col-9 d-flex align-items-center">
                                            <h6 className='fw-bold'>Specialized Designing</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-2 text-secondary text-start">
                                        In our pursuit of excellence, we specialize in going beyond the ordinary. Our team, consisting of seasoned UI/UX designers, is dedicated to creating designs that are not just user-centric but also uniquely tailored
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 mt-2">
                            <div
                                className="card border-0 p-3"
                                style={{ backgroundColor: "#F3FFFA" }}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-3 col-lg-3 col-md-2 col-sm-2 col-3 card-title">
                                            <span>
                                                <TfiTimer className="bi bi-clock p-2 text-light rounded-2" fontSize={50} style={{ backgroundColor: "#30B47C" }} />
                                            </span>
                                        </div>
                                        <div className="col-xxl-9 col-lg-9 col-md-10 col-sm-10 col-9 d-flex align-items-center">
                                            <h6 className='fw-bold'>Experienced Designers</h6>
                                        </div>
                                    </div>
                                    <p className="card-text mt-2 text-secondary text-start">
                                        Within our team, experienced UI/UX designers bring passion and stay abreast of the latest design trends and norms, ensuring your projects are on the cutting edge of design innovation and user-friendly.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* second container end */}
            {/* third container start */}
            <div
                className="container-fluid py-5"
                style={{ backgroundColor: "#F6F9FB" }}
            >
                <div className="container">
                    <div className="row">
                        <div
                            className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 pb-5"
                            style={{ position: "relative" }}
                        >
                            <div className="InsuranceTeamBG">
                                <img src={mobileappdev} alt="pixl" style={{ width: "100%" }} />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                            <div>
                                <TiDeviceDesktop className='fs-1 ms-5 ui-ux-design' />
                            </div>
                            <div className="py-4">
                                <h1 className="ms-5 ui-ux-design">UI/UX Design & Development <br /> Services Provided by Pixl
                                </h1>
                            </div>
                            <div>
                                <p className="ms-5 ui-ux-design">
                                    Tailored to fit your unique business needs, our UI/UX Design and Development services are crafted to deliver comprehensive assistance from concept to completion. We prioritize delivering on-time solutions without compromising quality, ensuring your digital experiences are not just functional but exceptional.
                                </p>
                            </div>
                            <ul className="mt-4 ui-ux-list-content">
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>User Research and Usability Testing</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>Creating Information Architecture</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>
                                        UI/UX Design for Web and Mobile</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>UI/UX Design Consultancy Services</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>Wireframing and Prototyping</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>Accessibility Testing</span>
                                </li>
                                <li className="ms-3 d-flex align-items-center" style={{ listStyle: "none" }}>
                                    <span className="me-4">
                                        <i className="bi bi-check2 text-danger fs-2"></i>
                                    </span>
                                    <span>Maintenance and Support</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* fourth container start */}
                <div className="container my-5 py-5">
                    <div className="row">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 pb-5">
                            <div className='rounded-4' style={{ backgroundColor: "#270E4D", padding: "60px 40px 60px 40px", position: "relative" }}>
                                <img src={uiuxprecise} alt="pixl web designers" style={{ width: "100%" }} />
                                <img src={expertise} alt="pixl designers" className='ui-ux-expertise' />
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center">
                            <h1 className='ms-5 ui-ux-design'>We excel in crafting transformative UI/UX experiences that elevate digital interactions and redefine user engagement for businesses</h1>
                            <p className='ms-5 pt-4 pb-2 ui-ux-design'>Immerse your users in transformative digital journeys with our expertise in sculpting exceptional UI/UX experiences. Elevate your business by redefining how users engage with your digital presence.</p>
                            <div className='ms-5 ui-ux-design'>

                                <button className="btn  rounded-2 py-3 px-4 btn-lg mt-4 text-light fw-bold" style={{ backgroundColor: '#E11E5B' }}>
                                    <a href="tel:+91 73823 73824"  style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 73823 73824
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* fourth container end */}
                {/* fifth container start large device*/}
                <div className="container bg-white ui-ux-fifth-container-lg-device">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 border-end">
                            <div>
                                <SeparateForm />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 p-5 ui-ux-form-border-bottom">
                            <div>
                                <FaHeadphones className='fs-1 ms-4' color='#E11E5B' />
                            </div>
                            <h1 className='ms-4 py-4'>
                                Let Pixl be your UI/UX <br /> Developer
                            </h1>
                            <p className='ms-4'>Whether your goal is to enhance app visibility, drive increased downloads, or maximize user engagement, we’ve got the expertise to meet your mobile app development objectives. Your success is our priority.</p>
                            <p className='ms-4'>Get in touch, and let the collaboration begin</p>
                        </div>
                    </div>
                </div>
                {/* fifth container end large device*/}
                {/* fifth container sm and md device start */}
                <div className="container bg-white ui-ux-fifth-container-sm-md-device">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 ui-ux-design-padding border-bottom ">
                            <div>
                                <SeparateForm />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 ui-ux-design-padding ui-ux-form-border-bottom mt-4">
                            <div>
                                <FaHeadphones className='fs-1' color='#E11E5B' />
                            </div>
                            <h1 className='py-3 '>
                                Let Pixl be your UI/UX <br /> Developer
                            </h1>
                            <p>Whether your goal is to enhance app visibility, drive increased downloads, or maximize user engagement, we’ve got the expertise to meet your mobile app development objectives. Your success is our priority.</p>
                            <p>Get in touch, and let the collaboration begin</p>
                        </div>
                    </div>
                </div>
                {/* fifth container sm and md device end */}
            </div>
            {/* third container end */}
        </>
    )
}

export default UiUx